import { Device } from './../../../../shared/models/device.model';
import { Address } from 'src/app/shared/models/address.model';
import { Component, OnInit,Input } from '@angular/core';
import { Plan } from 'src/app/shared/models/plan.model';
import { MatDialog } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { AddSelectedDevice, RemoveSelectedCableDevice, RemoveSelectedCableDeviceId, RemoveSelectedCableLockin, RemoveSelectedChannel, RemoveSelectedDevice, RemoveSelectedMeshDevice, RemoveSelectedMeshLockin, RemoveSelectedPack, RemoveSelectedPlan, ResetCableIndexId, UpdateBillingSummary, UpdateBillingSummaryPayload, UpdateCableLite, UpdateNumberOfBoxes, UpdatePlanDetails } from 'src/app/shared/actions/gelato.actions';
import { BundleModalPlanDetailComponent  } from 'src/app/shared/components/bundle-modal-plan-detail/bundle-modal-plan-detail.component';
import { PlanDetail } from 'src/app/shared/models/planDetail.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { UpdateHelpMeChoose, ResetHelpMeChoose, UpdateComparePlans, AddSelectedPlan } from './../../../../shared/actions/gelato.actions';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { ServicePlan } from 'src/app/shared/models/servicePlan.model';
import { Router } from '@angular/router';
import { BundleViewDetailsModalComponent  } from 'src/app/modules/bundle-plan/components/bundle-view-details-modal/bundle-view-details-modal.component';
import { Service } from 'src/app/shared/models/service.model';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash'
import { Pack } from 'src/app/shared/models/pack.model';
import { Channel } from 'src/app/shared/models/channel.model';
@Component({
  selector: 'app-bundle-box',
  templateUrl: './bundle-box.component.html',
  styleUrls: ['./bundle-box.component.css']
})
export class BundleBoxComponent implements OnInit {
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  selectedPlans:[Plan]
  @Input() plan: Plan;
  panelOpen: boolean = false;
  twoWordPlan: boolean = false;
  firstWord: String = "";
  amphere: String ="&" 

  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getHelpMeChoose) selected$: Observable<any>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getPlanDetail) planDetails$: Observable<ResponseObject<PlanDetail>>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>;
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  showCompare: boolean = false;
  plansFlag:boolean = false
  compareFlag:boolean = false
  servicePlan:ServicePlan[]
  selected:Plan[]
  blanckBox:[]
  compareError : boolean = false
  isDisabled: any;
  selectedCategory: string;
  address:Address
  province_code:string = ""
  planDetail:PlanDetail
  billingSummaryPayload:any
  selectedDevices:Device[] = []
  googleAddress: string = localStorage.getItem("google_address")
  selectedCableDevice:any[] = []
  selectedMesh:Device[] =[]
  selectedPacks:Pack[] = []
  selectedChannels:Channel[] = []
  constructor(private srv: GelatoState,public dialog: MatDialog,private store: Store,private gelatoService:GelatoService,private router: Router) {
    this.planDetails$.subscribe(x=> {
      this.planDetail = x.data["plan_details"]
    })

    this.selectedPacks$.subscribe(x => {
      this.selectedPacks = x
    })

    this.selectedChannels$.subscribe(x => {
      this.selectedChannels = x
    })
    
   }


  ngOnInit() {
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google || this.googleAddress) {
          this.province_code = x.data["province"]["code"]
        }
      })
    })
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    this.selectedCategory$.subscribe(x => {
      this.selectedCategory = x
    })
    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
    this.gelatoService.getServicesByPlanID("3",this.province_code,this.selectedCategory).subscribe(x => {
      this.servicePlan = x.data["service_plans"]
    })
    this.store.select(state => state.gelato.help_me_choose).subscribe(x => {
      this.selected = x
    });

    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    })

    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCableDevice = x
      else this.selectedCableDevice = []
    })
    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMesh =x
    })
  }

  openPlanDetail(id) {
    
    //UPDATE PLAN_DETAIL
    this.gelatoService.getPlanDetailsByID(id,this.province_code,this.selectedCategory).subscribe(x => {
      this.store.dispatch(new UpdatePlanDetails(x))
      const dialogRef = this.dialog.open(BundleModalPlanDetailComponent,{
        height: '800px',
        width: '660px',
      });
      
      
      dialogRef.afterClosed().subscribe(result => {
      });
    })
    
  }
  

  editPlanName(planName){
    var split = planName.split(" ")
    if(split.length > 1){
      return split[1]
    }
    else{
      return planName
    }
  }

  getFlag(plan:Plan){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.id === plan.id)
    }else{
      return false
    }
    
  }

  selectPlan(id){
  
     //UPDATE PLAN_DETAIL
     this.gelatoService.getPlanDetailsByID(id,this.province_code,this.selectedCategory).subscribe(x => {
      this.store.dispatch(new UpdatePlanDetails(x))
      this.router.navigate(['bundle-plan/channel-lineup'])
    })
   
  }
  
  getThis(plan:Plan) {
    this.store.dispatch(new UpdateCableLite(false))
    this.panelOpen = true
    this.store.dispatch(new UpdateHelpMeChoose(this.planDetail))
    if(this.selectedPlans){
      this.selectedPlans.filter(x => x.service_id === environment.SERVICE_BUNDLE).map(y => {
        this.store.dispatch(new RemoveSelectedPlan(y.id))
      }) 
      this.selectedDevices.filter(device => device.device_service_type == environment.SERVICE_BUNDLE).map(x => {
        this.store.dispatch(new RemoveSelectedDevice(x.id, environment.SERVICE_BUNDLE))
      })
      this.resetCableAddOns()
    }
    this.store.dispatch(new AddSelectedPlan(plan))

    plan.comes_with.map(x => {
      const copy = {...x, device_service_type: environment.SERVICE_BUNDLE}
      this.store.dispatch(new AddSelectedDevice(copy))
    })
    


    //CONSTRUCT SELECTED PLANS
    const selected = this.selectedPlans.map(plan => {
      return {
        "plan_id":plan.id,
        device_id: this.selectedDevices.filter(x => x.device_service_type == plan.service_id).map(x => x.id)
        // device_id:x.comes_with.map(x=>x.id)
      }
    })
    const defaultAddon = {
        "devices":[],
        "channel_pack_id":[],
        "channel_id":[]
    }
    let payload = this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected, "addons":defaultAddon }

    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      if(x.status_code === 200){
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }else{
      }
    })    

  }
  
  resetCableAddOns(){
    this.selectedCableDevice.forEach(device => {
      this.store.dispatch(new RemoveSelectedCableDevice(device.id))
      this.store.dispatch(new RemoveSelectedCableDeviceId(device.id))
      this.store.dispatch( new RemoveSelectedCableLockin(device.name))
    })
    this.selectedMesh.map(x => {
      this.store.dispatch(new RemoveSelectedMeshDevice(x.id))
      this.store.dispatch(new RemoveSelectedMeshLockin(x.name))
    })
    if(this.selectedPacks){
      this.selectedPacks.forEach(pack => {
        this.store.dispatch(new RemoveSelectedPack(pack.id))
      })
    }
    if(this.selectedChannels){
      this.selectedChannels.forEach(channel => {
        this.store.dispatch(new RemoveSelectedChannel(channel.id))
      })
    }

    this.store.dispatch(new UpdateNumberOfBoxes(null))
    this.store.dispatch( new ResetCableIndexId())
  }



}
