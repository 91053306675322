import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UpdateCompareChannels, ResetHelpMeChoose, UpdateComparePlans, AddSelectedPlan, UpdateEditMode, UpdateApplyButtonFlag, UpdateVoucherCode, UpdateBillingSummaryPayload, UpdateBillingSummary } from '../../../shared/actions/gelato.actions';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { GelatoService } from 'src/app/core/services/gelato.service';
import * as _ from 'lodash';
import { Plan } from '../../models/plan.model';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Select(GelatoState.getEditMode) editMode$: Observable<boolean>;
  @Select(GelatoState.getEditOriginLink) editOriginLink$: Observable<String>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Input() nextButton: boolean
  @Input() helpChoose: boolean = true
  @Input() editPlan: boolean = false
  @Input() backClick?: string
  @Output() nextClick: any = new EventEmitter()

  selectedPlans: [Plan]
  plansFlag: boolean = false
  compareFlag: boolean = false
  editMode: boolean = false

  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  billingSummaryPayload: any
  constructor(private srv: GelatoState, private store: Store, private gelatoService: GelatoService, private router: Router) { }

  ngOnInit() {
    this.editMode$.subscribe(x => {
      this.editMode = x
    })


    this.billingSummaryPayload$.subscribe(x => {
      if (x) this.billingSummaryPayload = x

    })
    this.selectedPlans$.subscribe(plans => {
      if (plans) this.selectedPlans = plans
    })
  }

  goBack() {
    if (this.backClick) {
      this.router.navigate([this.backClick])
    } else {
      window.history.back();
    }

  }

  goNext() {
    this.nextClick.emit("click")
    this.store.dispatch(new UpdateEditMode(false))
  }

  saveChanges() {
    this.store.dispatch(new UpdateEditMode(false))
    this.removeDiscount()
    let sub = this.editOriginLink$.subscribe(x => {
      if (this.selectedPlans.some(plan => plan.service_id == environment.SERVICE_TV && plan.default_cable_box_id == 0)) {
        this.router.navigate(['/tv-plan/select-device']).then(x => sub.unsubscribe)
      } else {
        this.router.navigate([x]).then(x => sub.unsubscribe())
      }
    })
  }

  removeDiscount() {
    this.store.dispatch(new UpdateApplyButtonFlag(false))
    this.store.dispatch(new UpdateVoucherCode(null))

    let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)), "voucher_code", "")
    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))

    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
      if (x.status_code === 200) {
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      } else {
        console.error(x.message)
      }
    })
  }

}
