// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  GELATO_API_BASE_URL : "https://subscribeapi.mysky.com.ph/api/v1",
  // GELATO_API_BASE_URL: "http://122.248.248.45/backend/api/v1",
  INTERFACE_ID: "PROJECT GELATO",
  INTERFACE_KEY: "1425a01802d1a39cd9c9a7184320c78c8fe53400a7bceb27a74362f799e2d1e2",
  GOOGLE_CLOUD_KEY: "AIzaSyAKjz4VSo_W3aMLYU54C3hXFt6elMlUccQ",
  GOOGLE_RECAPTCHA_KEY: "6Lc4sM0ZAAAAAMCrYCfLzDuMGMVndxXiyJfJt9f4",
  PLAN_LINK: {
    "1": "../../internet-plan/select-plan",
    "2": "../../tv-plan/choose-plan",
    "3": "../../bundle-plan/select-plan"
  },
  SERVICE_INTERNET: 1,
  SERVICE_TV: 2,
  SERVICE_BUNDLE: 3,
  NOT_SUBSCRIBER: 98,
  UPGRADE_PLAN: 99,
  DEVICE_MODEM: 1,
  DEVICE_CABLE: 2,
  DEVICE_MESH: 3,
  KEYCLOAK_URL: "https://sky-account.mysky.com.ph/auth/",
  // KEYCLOAK_REALM: "developer",
  // KEYCLOAK_CLIENT: "mysky",
   KEYCLOAK_REALM: "mysky",
   KEYCLOAK_CLIENT: "myskyweb",

  // Account Checking API
  ACCOUNT_BASE_URL: "https://stagecustinfo.skycable.com/contact/api",
  ACCOUNT_SECRET_KEY: 'OTUxNzQxOTQ1ZWYwNTk2ZjI2NmIzNjA0ODc0NmYwNTM3YzhlNTM2OTQxZjViNDBlYzUxMGQ0MTExNjRkMmFiZQ==',
  ACCOUNT_INTERFACE_ID: 'CONTACTUPDATE',
  ACCOUNT_CREDENTIAL: 'SKYICTEST',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
