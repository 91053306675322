import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs';
import { Plan } from '../../models/plan.model';
import { PlanDetail } from '../../models/planDetail.model';
import { Router } from '@angular/router';
import { AddSelectedPlan, AddSelectedDevice, RemoveSelectedPlan, RemoveSelectedDevice, UpdateBillingSummaryPayload, UpdateBillingSummary, RemoveSelectedPack, RemoveSelectedChannel, RemoveSelectedMeshLockin, RemoveSelectedMeshDevice } from '../../actions/gelato.actions';
import { GelatoService } from 'src/app/core/services/gelato.service';
import * as _ from 'lodash'
import { Channel } from '../../models/channel.model';
import { Device } from '../../models/device.model';
import { Pack } from '../../models/pack.model';

@Component({
  selector: 'app-compare-channel-lineup',
  templateUrl: './compare-channel-lineup.component.html',
  styleUrls: ['./compare-channel-lineup.component.css']
})
export class CompareChannelLineupComponent implements OnInit {
  @Select(GelatoState.getHelpMeChoose) helpMeChoose$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>
  @Input() selectedPlansToCompare:[]
  @Input() channelLineup:[]
  @Output() getThisEvent = new EventEmitter();
  selectedPlans:[Plan]
  billingSummaryPayload:any
  errorMessage = ""
  removedPlan: number
  selectedPacks:Pack[] = []
  selectedChannels:Channel[] = []
  selectedDevices:Device[] =  []
  selectedMesh:Device[] =[]
  constructor( private store: Store,private gelatoService:GelatoService,private router: Router) { }

  ngOnInit() {
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
     this.selectedPacks$.subscribe(x => {
      this.selectedPacks = x
    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    
    })

    this.selectedChannels$.subscribe(x => {
      this.selectedChannels = x
    }) 
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })

    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMesh =x
    })

    this.selectedPacks$.subscribe(x => {
      this.selectedPacks = x
    })

    this.selectedChannels$.subscribe(x => {
      this.selectedChannels = x
    })
  }

  getThisFlag(id){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.id === id)
    }else{
      return false
    }
  }

  
  getThis(plan:Plan) {
    if(plan.service_id === environment.SERVICE_TV){
      
    if(this.selectedPlans){
      if(this.selectedPlans.some(x =>x.service_id === environment.SERVICE_TV)){
        this.selectedPlans.filter(x => x.service_id === environment.SERVICE_TV).map(y => {
          this.removedPlan = y.id
          this.store.dispatch(new RemoveSelectedPlan(y.id))
          this.store.dispatch(new AddSelectedPlan(plan))
          
          })
        }else{
          this.store.dispatch(new AddSelectedPlan(plan))
        }
          
        this.selectedDevices.filter(device => device.device_service_type == environment.SERVICE_TV).map(x => {
          this.store.dispatch(new RemoveSelectedDevice(x.id, environment.SERVICE_TV))
        })

        if(plan.default_cable_box_id != 0){
          const copy = {...plan.default_cable_box, device_service_type : environment.SERVICE_TV}
          this.store.dispatch(new AddSelectedDevice(copy))
        }
        this.billingSummary()
      }
      else{
        if(plan.service_id === environment.SERVICE_TV){
          if(plan.default_cable_box_id != 0){
            const copy = {...plan.default_cable_box, device_service_type : environment.SERVICE_TV}
            this.store.dispatch(new AddSelectedDevice(copy))
          }
      this.store.dispatch(new AddSelectedPlan(plan))
      }else{
        this.store.dispatch(new AddSelectedPlan(plan))
        plan.comes_with.map(x => {
          this.store.dispatch(new AddSelectedDevice(x))
        })
      }
      this.billingSummary()
      }
    
  }else{
    
    
    if(this.selectedPlans){
      this.selectedPlans.filter(x => x.service_id === environment.SERVICE_BUNDLE).map(y => {
        this.store.dispatch(new RemoveSelectedPlan(y.id))
      }) 

      this.selectedDevices.filter(device => device.device_service_type == environment.SERVICE_BUNDLE).map(x => {
        this.store.dispatch(new RemoveSelectedDevice(x.id, environment.SERVICE_BUNDLE))
      })

      this.selectedMesh.map(x => {
        this.store.dispatch(new RemoveSelectedMeshDevice(x.id))
        this.store.dispatch(new RemoveSelectedMeshLockin(x.name))
      })
      
      if(this.selectedPacks){
        this.selectedPacks.forEach(pack => {
          this.store.dispatch(new RemoveSelectedPack(pack.id))
        })
      }
      if(this.selectedChannels){
        this.selectedChannels.forEach(channel => {
          this.store.dispatch(new RemoveSelectedChannel(channel.id))
        })
      }
    }
    this.store.dispatch(new AddSelectedPlan(plan))
    plan.comes_with.map(x => {
      const copy = {...x, device_service_type: environment.SERVICE_BUNDLE}
      console.warn(copy,"HERE")
      this.store.dispatch(new AddSelectedDevice(copy))
    })

    //CONSTRUCT SELECTED PLANS
    const selected = this.selectedPlans.map(x => {
      return {
        "plan_id":x.id,
        device_id:x.comes_with.map(x=>x.id)
      }
    })
    const defaultAddon = {
        "devices":[],
        "channel_pack_id":[],
        "channel_id":[]
    }
    let payload = this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected, "addons":defaultAddon }
    
    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      if(x.status_code === 200){
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }
    })    

    this.router.navigate(['/tv-plan/create-plan/tv-packs'])
  }
  }

  billingSummary(){
      //CONSTRUCT SELECTED PLANS
      const selected = this.selectedPlans.map(plan => {
        return {
         "plan_id":plan.id,
         device_id: this.selectedDevices.filter(device => device.device_service_type === plan.service_id).map(x => x.id)
       }
     })
      const defaultAddon = {
          "devices":[],
          "channel_pack_id":[],
          "channel_id":[]
      }

      if(this.removedPlan == 56){
        if(this.selectedPacks){
          this.selectedPacks.forEach(pack => {
            this.store.dispatch(new RemoveSelectedPack(pack.id))
          })
        }
        if(this.selectedChannels){
          this.selectedChannels.forEach(channel => {
            this.store.dispatch(new RemoveSelectedChannel(channel.id))
          })
        }
        this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected, "addons":defaultAddon }
      }
      else{
        this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected }
      }
      
      //UPDATE BILLING SUMMARY PAYLOAD
      this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
      //UPDATE BILLING SUMMARY
      this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
        
        if(x.status_code === 200){
          this.errorMessage = ""
          this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
          this.router.navigate(['/tv-plan/create-plan/tv-packs'])
        }else{
          this.errorMessage = x.message
        }
      })    
  
  }

}
