import { AddQrCodeData, UpdateFormAddress, UpdateGoogleAddress, UpdateHeaderText, UpdateSelectedService, UpdateSelectedServiceCategory } from './../../../../shared/actions/gelato.actions';
import { ResponseObject } from './../../../../shared/models/response-object.model';
import { Address } from 'src/app/shared/models/address.model';
import { Component, OnInit, Input } from '@angular/core';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Service } from 'src/app/shared/models/service.model';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateServices, UpdateHTTPRequestFlag, UpdateVisitedPages } from 'src/app/shared/actions/gelato.actions';
import { HeaderText } from './../../../../shared/models/header-text.model';
import { ActivatedRoute, Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { QrCode } from 'src/app/shared/models/qr-code.model';

@Component({
  selector: 'app-available-location',
  templateUrl: './available-location.component.html',
  styleUrls: ['./available-location.component.css']
})
export class AvailableLocationComponent implements OnInit {

  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  @Select(GelatoState.getVisitedPages) visitedPages$: Observable<[any]>;
  @Select(GelatoState.getGoogleCoordinates) googleCoordinates$: Observable<number[]>;
  address: string
  message: String = ""
  noService: boolean = false
  services: Service[]
  googleAddress: string
  constructor(private route: ActivatedRoute, private store: Store, private gelatoService: GelatoService, private router: Router) {
    // Scan QR Code if available pass qr id
    this.route.queryParams.subscribe(params => {
      if (params['qr-code']) {
        // Save qr code id here, to be passed on submit application
        this.scanQRCode(params['qr-code']);
      }
    });

    localStorage.getItem('qr-code') && this.store.dispatch(new AddQrCodeData(JSON.parse(localStorage.getItem('qr-code'))))

    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.gelatoService.getHeaderText().subscribe(x => {
      if (x) this.store.dispatch(new UpdateHeaderText(x["data"]))
    })

    this.visitedPages$.subscribe(x => {
      if (x == undefined) {
        this.store.dispatch(new UpdateVisitedPages(['available']))
      }
      else if (!x.includes('available')) {
        this.store.dispatch(new UpdateVisitedPages(['available']))
      }
    })

    this.getServicesByAddress()

    if (localStorage.getItem("google_address")) {
      this.googleAddress = localStorage.getItem("google_address")
    }

    if (localStorage.getItem("google_coordinates")) {
      const googleCoordinates = JSON.parse(localStorage.getItem("google_coordinates")) as number[]
      this.gelatoService.getServicesByLATLONG(googleCoordinates[0], googleCoordinates[1]).subscribe(x => {
        this.store.dispatch(new UpdateServices(x))
        if (!x.data) {
          this.message = x.message
          this.noService = true
          this.router.navigate(['/onboarding/validate-location'])
        } else if (x.data['services'].length == 0) {
          this.message = x.message
          this.noService = true
          this.router.navigate(['/onboarding/validate-location'])
        } else {
          this.headerText$.subscribe(x => {
            this.message = x.check_availability_screen.ca_heading_text_2
          })
          this.services = x.data["services"]
          this.message = x.message
        }
        this.store.dispatch(new UpdateHTTPRequestFlag(false))

        // new request force to redirect (internet plan)
        if (this.services.length == 1 && this.services[0].id == 1) {
          this.store.dispatch(new UpdateSelectedService(this.services[0].id))
          this.store.dispatch(new UpdateSelectedServiceCategory(this.services[0].service_category))
          this.router.navigate(['/internet-plan/select-plan'])
        }
      })
    }
  }

  ngOnInit() {
  }

  scanQRCode(qrId) {
    this.gelatoService.retrieveQRCodeDetails(qrId).subscribe(details => {
      let qrCode: QrCode = details.data['qr_code']
      localStorage.setItem("qr-code", JSON.stringify(qrCode))
      // Save QR data here
      this.store.dispatch(new AddQrCodeData(qrCode))
      if (qrCode.province) {
        let qrAddress = {
          province: {},
          city: {},
          building: {},
          barangay: {},
          street: {},
          subd: "",
          unit_no: "",
          zip_code: ""
        }
        this.gelatoService.getProvinces().subscribe(x => {
          qrAddress.province = x.data['provinces'].filter(province => province.province_code == qrCode.province)[0]
          this.gelatoService.getCities(qrCode.province).subscribe(x => {
            qrAddress.city = x.data['cities'].filter(city => city.city_code == qrCode.city)[0]
            if (qrCode.building_id) {
              this.gelatoService.getBuildings(qrCode.province, qrCode.city).subscribe(x => {
                qrAddress.building = x.data['buildings'].filter(building => building.id == qrCode.building_id)[0]
              })
            }
            this.gelatoService.getBarangays(qrCode.province, qrCode.city).subscribe(x => {
              qrAddress.barangay = x.data['barangays'].filter(barangay => barangay.brgy_code == qrCode.barangay)[0]
              this.gelatoService.getStreets(qrCode.province, qrCode.city, qrCode.barangay).subscribe(x => {
                qrAddress.street = x.data['streets'].filter(street => street.street_code == qrCode.street)[0]
                this.store.dispatch(new UpdateGoogleAddress(null))
                localStorage.setItem("address", JSON.stringify(qrAddress))
                this.getServicesByAddress()
              })
            })
          })
        })
      }
      else {
        this.router.navigate(['/onboarding/set-location'])
      }
    })
  }

  getServicesByAddress() {
    const addressObj = localStorage.getItem("address")
    if (addressObj) {
      const x = JSON.parse(addressObj) as Address
      this.address = `${x.unit_no} ${x.building.building_name ? x.building.building_name : ''} ${x.subd} ${x.street.name}, ${x.barangay.name}, ${x.city.name}, ${x.province.name} ${x.zip_code}`
      this.gelatoService.getServices(x.province.province_code, x.city.city_code, x.barangay.brgy_code, x.street.street_code, x.building.id).subscribe(x => {
        this.store.dispatch(new UpdateServices(x))
        if (!x.data) {
          this.message = x.message
          this.noService = true
          this.router.navigate(['/onboarding/validate-location'])
        } else if (x.data['services'].length == 0) {
          this.message = x.message
          this.noService = true
          this.router.navigate(['/onboarding/validate-location'])
        } else {
          this.headerText$.subscribe(x => {
            this.message = x.check_availability_screen.ca_heading_text_2
          })
          this.services = x.data["services"]
          this.message = x.message
        }
        this.store.dispatch(new UpdateHTTPRequestFlag(false))

        if (this.services.length == 1 && this.services[0].id == 1) {
          this.store.dispatch(new UpdateSelectedService(this.services[0].id))
          this.store.dispatch(new UpdateSelectedServiceCategory(this.services[0].service_category))
          this.router.navigate(['/internet-plan/select-plan'])
        }
      })
    }
  }

}
