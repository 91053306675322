import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormComponent } from './pages/form/form.component';
import { UpgradeContainerComponent } from './pages/upgrade-container/upgrade-container.component';

const routes: Routes = [
  {
    path: "upsell", component: UpgradeContainerComponent,
    children: [
      { path: "form", component: FormComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UpgradeRoutingModule { }
