import { environment } from 'src/environments/environment';
import { Service } from './../../models/service.model';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { UpdateSelectedService, UpdateSelectedServiceCategory } from '../../actions/gelato.actions';

@Component({
  selector: 'app-service-cards',
  templateUrl: './service-cards.component.html',
  styleUrls: ['./service-cards.component.css']
})
export class ServiceCardsComponent implements OnInit {

  @Input() service: Service;

  constructor(private router: Router, private store: Store) { }

  ngOnInit() {
  
  }
  navigate(id,category){
    if(category != ''){
      this.store.dispatch(new UpdateSelectedService(id))
      this.store.dispatch(new UpdateSelectedServiceCategory(category))
    }

    if(id === environment.SERVICE_TV){
      this.router.navigate(['tv-plan/standard-plan'])
    }else if( id === environment.SERVICE_INTERNET){
      this.router.navigate(['internet-plan/select-plan'])
    }else if( id === environment.SERVICE_BUNDLE){
      this.router.navigate(['bundle-plan/select-plan'])
    } else if(id == environment.UPGRADE_PLAN){
      this.router.navigate([''])
    } else if(id == environment.NOT_SUBSCRIBER){
      this.router.navigate([''])
    }
  }
}