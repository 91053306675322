import { environment } from 'src/environments/environment';
import { BillingSummary } from './../../../../shared/models/billing-summary';
import { AddSelectedPlan, UpdateEditMode, AddSelectedDevice, RemoveSelectedDevice, RemoveSelectedPlan, UpdateBillingSummary, UpdateBillingSummaryPayload } from './../../../../shared/actions/gelato.actions';
import { Plan } from 'src/app/shared/models/plan.model';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable, concat } from 'rxjs';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { PlanDetail } from 'src/app/shared/models/planDetail.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { Device } from 'src/app/shared/models/device.model';
import { Pack } from 'src/app/shared/models/pack.model';
import { Channel } from 'src/app/shared/models/channel.model';
import { VoucherCode } from 'src/app/shared/models/voucher-code';
import { AvailableLockinPeriod } from 'src/app/shared/models/available-lockin-period.model';
import * as _ from 'lodash'
@Component({
  selector: 'app-internet-view-details-modal',
  templateUrl: './internet-view-details-modal.component.html',
  styleUrls: ['./internet-view-details-modal.component.css']
})
export class InternetViewDetailsModalComponent implements OnInit {
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getPlanDetail) planDetails$: Observable<ResponseObject<PlanDetail>>;
  
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getEditMode) editMode$: Observable<boolean>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.getVoucherCode) discountCode$: Observable<ResponseObject<VoucherCode>>;
  @Select(GelatoState.getEditMode) edit$: Observable<boolean>;
  @Select(GelatoState.getApplyButtonFlag) applyButtonFlag$: Observable<boolean>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>;
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>;
  @Select(GelatoState.getSelectedCableDeviceId) CableId$: Observable<[any]>;
  @Select(GelatoState.getSelectedPlans) selectedPlan$: Observable<[Plan]>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;

  selectedPlans:[Plan]
  planDetail:PlanDetail
  selectedService:number
  discountCode:string =""
  selectedPlan:Plan[] = []
  selectedPacks:Pack[] = []
  selectedDevices:Device[] =  []
  selectedChannels:Channel[] = []
  selectedCableLockin:[AvailableLockinPeriod]
  selectedMeshLockin:[AvailableLockinPeriod]
  selectedCableDevice:any[] = []
  selectedMeshDevice:any[] = []
  createYourOwnFlag:boolean
  errorMessage = ""
  billingSummaryPayload:any
  
  constructor(private store: Store,private gelatoService:GelatoService,private router: Router,public dialogRef: MatDialogRef<InternetViewDetailsModalComponent>) {
    this.planDetails$.subscribe(x=> {
      this.planDetail = x.data["plan_details"]
    })
    
    window.scrollTo({top: 0})
   }

  ngOnInit() {
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    
    })
    
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
  }

  getThisFlag(planDetail:PlanDetail){
    if(this.selectedPlans){
      if(this.selectedPlans.some(x => x.id === planDetail.id)){
        return true
      }else if(this.selectedPlans.some(x => x.service_id == environment.SERVICE_BUNDLE)){
        return true
      }else{
        return false
      }
    }
    else{
      return false
    }
  }
  getThis(plan:Plan) {
      

    if(this.selectedPlans){
      this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET).map(y => {
        this.store.dispatch(new RemoveSelectedPlan(y.id))
        y.comes_with.map(x => {
          this.store.dispatch(new RemoveSelectedDevice(x.id))      
        })
      }) 
    }
    this.store.dispatch(new AddSelectedPlan(plan))
    plan.comes_with.map(x => {
      const copy = {...x, device_service_type: environment.SERVICE_INTERNET}
      this.store.dispatch(new AddSelectedDevice(copy))
    })

    
    //CONSTRUCT SELECTED PLANS
    const selected = this.selectedPlans.map(x => {
      return {
        "plan_id":x.id,
        device_id:x.comes_with.map(x=>x.id)
      }
    })
    let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",selected)
    
    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      
      if(x.status_code === 200){
        this.errorMessage = ""
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }else{
        this.errorMessage = x.message
      }
    })    
    

    this.dialogRef.close()
  }
 
 
 
  closeDialog(){
    this.dialogRef.close();
  }
  
  getPicture(){
    let width = window.innerWidth
      // Phone
    if(width <= 450){
      return this.planDetail['promo_details']['image_sizes'][0]['mobile_309x146'] ? this.planDetail['promo_details']['image_sizes'][0]['mobile_309x146'] : this.planDetail['promo_details']['image_sizes'][0]['desktop_632x146'];
    }
    else{
      return this.planDetail['promo_details']['image_sizes'][0]['desktop_632x146'];
    }
  }

}
