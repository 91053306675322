import { UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { MeshDevice } from './../../models/mesh-device.model';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { DeviceDetail } from '../../models/device-detail.model';

@Component({
  selector: 'app-mesh-benefits',
  templateUrl: './mesh-benefits.component.html',
  styleUrls: ['./mesh-benefits.component.css']
})
export class MeshBenefitsComponent implements OnInit {

  meshDevice:DeviceDetail
  requestFlag: boolean = true
  constructor(private store: Store,private gelatoService:GelatoService) {
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
   }

  ngOnInit() {
    this.gelatoService.getDeviceDetail("13").subscribe(x => {
      this.meshDevice = x.data["device"]
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      this.requestFlag = false
    })
  }

}
