import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { ChannelPack } from 'src/app/shared/models/channel-pack.model';
import { PackDetail } from 'src/app/shared/models/pack-detail.model';
import { Pack } from 'src/app/shared/models/pack.model';
import { AddSelectedPack, RemoveSelectedPack, UpdateBillingSummary, UpdateBillingSummaryPayload } from 'src/app/shared/actions/gelato.actions';
import * as _ from 'lodash'
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Router } from '@angular/router';
import { Plan } from 'src/app/shared/models/plan.model';
@Component({
  selector: 'app-pack-info',
  templateUrl: './pack-info.component.html',
  styleUrls: ['./pack-info.component.css']
})
export class PackInfoComponent implements OnInit {
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getPackDetail) packDetails$: Observable<ResponseObject<PackDetail>>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  selectedService: number
  flag:boolean
  selectedPacks:Pack[] = []
  billingSummaryPayload:any
  selectedPlans:Plan[] = []
  constructor(private store: Store,private gelatoService:GelatoService,private router: Router) { }

  ngOnInit() {
    this.selectedService$.subscribe(x => {
      if(x)this.selectedService = x
    })

    this.selectedPacks$.subscribe(x => {
      if(x)this.selectedPacks = x
    })
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    this.selectedPlans$.subscribe(x => {
      if(x) this.selectedPlans = x
    })
  }

  getFlag(x:ResponseObject<PackDetail>){
    
    if(this.selectedPacks){
      return this.selectedPacks.some(y => y.id === x.data["pack_details"].id)
    }else{
      return false
    }
    
  }
  getThis(packDetails:Pack){
    this.flag = !this.flag
    this.store.dispatch(new AddSelectedPack(packDetails))
    this.updatePacks()
    
  }
  removeThis(packDetails:Pack){
    this.flag = !this.flag
    this.store.dispatch(new RemoveSelectedPack(packDetails.id))
    this.updatePacks()
  }

  updatePacks(){
    let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.channel_pack_id",this.selectedPacks.map(x => x.id))
    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      if(x.status_code === 200){
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }else{
      }
    })    
  }

  next(){
    
    if(this.selectedService && this.selectedPlans){
      
      if(this.selectedService === environment.SERVICE_TV){
        this.router.navigate(['/internet-plan/select-plan'])
      }else if(this.selectedService == environment.SERVICE_BUNDLE){
        let sub = this.selectedCategory$.subscribe(x => {
          if(x){
            if(x === "FTTH"){
              this.router.navigate(['/bundle-plan/plan-summary']).then(x => sub.unsubscribe())
            }else{
              this.router.navigate(['/bundle-plan/select-device']).then(x => sub.unsubscribe())
            }
          }
        }) 
      }else{
        let sub = this.selectedCategory$.subscribe(x => {
          if(x){
            if(x === "FTTH"){
              this.router.navigate(['/internet-plan/plan-summary']).then(x => sub.unsubscribe())
            }else{
              //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
              if(this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_BUNDLE).some(x => x.is_upgradeable === 1)){
                this.router.navigate(['/internet-plan/select-device']).then(x => sub.unsubscribe())
              }else{
                this.router.navigate(['/internet-plan/select-addon']).then(x => sub.unsubscribe())
              }      
            }
          }
        }) 
      }
    }
  }
}
