import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Devices } from 'src/app/shared/models/devices.model';
import { Router } from '@angular/router';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateDevices, UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { Device } from 'src/app/shared/models/device.model';
import { Plan } from 'src/app/shared/models/plan.model';

@Component({
  selector: 'app-bundle-select-device',
  templateUrl: './bundle-select-device.component.html',
  styleUrls: ['./bundle-select-device.component.css']
})
export class BundleSelectDeviceComponent implements OnInit {
  @Select(GelatoState.getSelectedPlans) selectedPlans$:Observable<[Plan]>
  @Select(GelatoState.getDevices) devices$: Observable<ResponseObject<Devices>>;
  meshFlag:boolean = false
  boxMoreFlag:boolean = false
  requestFlag:boolean = true
  selectedPlans:[Plan]
  displayModemFlag:boolean = false
  displayTvFlag:boolean = false
  
  hasTVPlanSelectedFlag:boolean = false
  constructor(private router: Router,private store: Store,private gelatoService:GelatoService) { 
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
  }

  ngOnInit() {
    
     this.selectedPlans$.subscribe(x => {
      if(x) this.selectedPlans = x
    })
    this.gelatoService.getDevices().subscribe(x => {
      if(this.selectedPlans && x){
        if(this.selectedPlans.length > 0) {
          const comesWith =  this.selectedPlans.filter(x => x.service_id === environment.SERVICE_BUNDLE).map(x => x.comes_with)[0].filter(x => x.device_type_id === environment.DEVICE_MODEM).map(x => x.id)
          const deviceIds = x.data["modem_devices"].map(x => x.id)
          const count = deviceIds.filter(x => !comesWith.includes(x))
          if (count.length > 0) this.displayModemFlag = true
          
        }
        
        
      }
      if(this.selectedPlans && x){
        if(this.selectedPlans.length > 0) {
          const comesWith =  this.selectedPlans.filter(x => x.service_id === environment.SERVICE_BUNDLE).map(x => x.comes_with)[0].filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id)
          const TvdeviceIds = x.data["cable_box_devices"].map(x => x.id)
          const Tvcount = TvdeviceIds.filter(x => !comesWith.includes(x))
          if (Tvcount.length > 0) this.displayTvFlag = true
          if (Tvcount.length > 0) this.hasTVPlanSelectedFlag = true
          
        }
        
        
      }
    
           
      this.store.dispatch(new UpdateDevices(x))
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      this.requestFlag = false
    })
  }

  toggleMesh(){
    this.meshFlag = !this.meshFlag
  }
  toggleBoxMore(){
    this.boxMoreFlag = !this.boxMoreFlag
  }
  nextClick(){
    this.router.navigate(['/internet-plan/plan-summary'])
  }
}
