import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternetPlanRoutingModule } from './internet-plan-routing.module';
import { InternetPlanContainerComponent } from './pages/internet-plan-container/internet-plan-container.component';
import { SelectInternetComponent } from './pages/select-internet/select-internet.component';
import { FinalStepsComponent } from './pages/final-steps/final-steps.component';
import { PlanBoxContainerComponent } from './components/plan-box-container/plan-box-container.component';
import { PlanBoxComponent } from './components/plan-box/plan-box.component';
import { InternetHelpMeChooseComponent } from './pages/internet-help-me-choose/internet-help-me-choose.component';
import { InternetViewDetailsModalComponent } from './components/internet-view-details-modal/internet-view-details-modal.component';
import { InternetSelectDeviceComponent } from './pages/internet-select-device/internet-select-device.component';
import { InternetCompareDeviceComponent } from './pages/internet-compare-device/internet-compare-device.component';
import { InternetMeshDetailsComponent } from './pages/internet-mesh-details/internet-mesh-details.component';
import { MeshDeviceDetailsComponent } from './pages/mesh-device-details/mesh-device-details.component';
import { CompareMeshDevicesComponent } from './pages/compare-mesh-devices/compare-mesh-devices.component';


@NgModule({
  declarations: [ InternetPlanContainerComponent, SelectInternetComponent, FinalStepsComponent, PlanBoxContainerComponent, PlanBoxComponent, InternetHelpMeChooseComponent, InternetViewDetailsModalComponent, InternetSelectDeviceComponent, InternetCompareDeviceComponent, InternetMeshDetailsComponent, MeshDeviceDetailsComponent, CompareMeshDevicesComponent],
  entryComponents:[InternetViewDetailsModalComponent],
  imports: [
    InternetPlanRoutingModule,
    SharedModule
  ]
})
export class InternetPlanModule { }
