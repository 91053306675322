import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GelatoState } from 'src/app/shared/state/gelato.state';

@Component({
  selector: 'app-upgrade-container',
  templateUrl: './upgrade-container.component.html',
  styleUrls: ['./upgrade-container.component.css']
})
export class UpgradeContainerComponent implements OnInit {

  @Select(GelatoState.getHTTPRequestFlag) httpReqeuestFlag$: Observable<boolean>;
  flag: boolean
  constructor(private router: Router) {
    // this.router.url === '/onboarding' || this.router.url === '/' ? this.router.navigate(['/onboarding/set-location']) : null
  }

  ngOnInit() {
    this.httpReqeuestFlag$.subscribe(x => {
      this.flag = x
    })
  }

}
