import { Component, OnInit, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { Observable } from 'rxjs';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateHTTPRequestFlag, UpdateHeaderText, UpdateBarangays, UpdateBuildings, UpdateCities, UpdateStreets, UpdateProvinces, AddConfirmationData } from 'src/app/shared/actions/gelato.actions';
import { GenericErrorModalComponent } from 'src/app/shared/components/generic-error-modal/generic-error-modal.component';
import { Address } from 'src/app/shared/models/address.model';
import { Barangay } from 'src/app/shared/models/barangay.model';
import { Building } from 'src/app/shared/models/building.model';
import { City } from 'src/app/shared/models/city.model';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { Province } from 'src/app/shared/models/province.model';
import { Street } from 'src/app/shared/models/street.model';
import { ValidateBody } from 'src/app/shared/models/validate-location.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-validate-address',
  templateUrl: './validate-address.component.html',
  styleUrls: ['./validate-address.component.css']
})
export class ValidateAddressComponent implements OnInit {

  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getProvinces) provinces$: Observable<[Province]>;
  @Select(GelatoState.getCities) cities$: Observable<[City]>;
  @Select(GelatoState.getBuildings) buildings$: Observable<[Building]>;
  @Select(GelatoState.getBarangays) barangays$: Observable<[Barangay]>;
  @Select(GelatoState.getStreets) streets$: Observable<[Street]>;
  validateAddressForm: FormGroup
  submitted: boolean = false
  address: Address
  addressObj: Address

  // Captcha configs
  public siteKey = environment.GOOGLE_RECAPTCHA_KEY;
  public badge = 'inline';
  public type = 'image';
  public theme = 'light';
  public recaptcha: any = null;
  public size = 'Normal';
  public lang = 'en';
  gRecaptcha: String;
  @ViewChild('captchaElem', {}) captchaElem: InvisibleReCaptchaComponent;

  constructor(private gelatoService: GelatoService, private store: Store, private formBuilder: FormBuilder, private dialog: MatDialog, private router: Router,) {
    gelatoService.getHeaderText().subscribe(headings => {
      store.dispatch(new UpdateHTTPRequestFlag(true))
      store.dispatch(new UpdateHeaderText(headings.data))
      store.dispatch(new UpdateHTTPRequestFlag(false))
    })
    //LOAD PROVINCES
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    gelatoService.getProvinces().subscribe(x => {
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      this.store.dispatch(new UpdateProvinces(x.data["provinces"]))
    })

    // Auto fill form from user input
    this.addressObj = JSON.parse(localStorage.getItem("address")) as Address
    if (this.addressObj) {
      console.warn(this.addressObj)
    }

    this.address$.subscribe(address => {
      this.address = address
    })

    this.validateAddressForm = formBuilder.group({
      house_unit_no: [this.addressObj?.unit_no ? this.addressObj.unit_no : '', Validators.required],
      subdivision_building_village: [this.addressObj?.subd ? this.addressObj.subd : ''],
      province: [this.addressObj?.province ? this.addressObj.province : '', Validators.required],
      city: [this.addressObj?.city ? this.addressObj.city : '', Validators.required],
      building: [this.addressObj?.building ? this.addressObj.building : ''],
      barangay: [this.addressObj?.barangay ? this.addressObj.barangay : '', Validators.required],
      street: [this.addressObj?.street ? this.addressObj.street : '', Validators.required],
      zip_code: [this.addressObj?.zip_code ? this.addressObj.zip_code : '', Validators.required],
      name: ['', Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9\\-\\s\\ñ\\Ñ']+$")])],
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.pattern('^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\\.[a-z]{2,4}$')])],
      mobile: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(12), Validators.min(999999999)])],
      terms: [false, Validators.requiredTrue],
      privacy: [false, Validators.requiredTrue],
      recaptcha: ['', Validators.required]
    })
  }

  ngOnInit() {

  }

  submit(form: FormGroup) {
    this.submitted = true
    console.warn(form)
    if (form.valid) {
      let body: ValidateBody = {
        application_details: {
          contact_name: form.value.name,
          email_address: form.value.email,
          mobile_no: form.value.mobile,
          house_unit_no: form.value.house_unit_no,
          street_name: form.value.street.street_code,
          subdivision_building_village: form.value.subdivision_building_village,
          province_code: form.value.province.province_code,
          city_code: form.value.city.city_code,
          barangay_code: form.value.barangay.brgy_code,
          building_id: form.value.building == '' ? '' : form.value.building.id,
          terms: form.value.terms,
          privacy: form.value.privacy
        }
      }
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.gelatoService.submitLocationValidtaionRequest(body).subscribe(x => {
        if (x.status_code === 200) {
          let data = { ...x.data, transaction: true }
          this.store.dispatch(new AddConfirmationData(data))
          this.router.navigate(['/confirmation'])
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        } else {
          console.error(x.message)
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        }
      }, (error) => {
        console.error(error.message)
        this.dialog.open(GenericErrorModalComponent, {
          autoFocus: false,
          height: '150px',
          width: '300px'
        })
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })
    }
  }

  loadSelectData(event) {

    if (event.target.name === "province") {
      //LOAD CITIES
      const province = this.validateAddressForm.controls["province"].value
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.gelatoService.getCities(province.province_code).subscribe(x => {
        this.store.dispatch(new UpdateCities(x.data["cities"]))
        this.store.dispatch(new UpdateBarangays(x.data[""]))
        this.store.dispatch(new UpdateStreets(x.data[""]))
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })

      // Reset other select
      document.getElementsByClassName('province')[0]['style'].color = 'black'
      document.getElementsByClassName('city')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('barangay')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('building')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      this.validateAddressForm.controls['city'].patchValue('')
      this.validateAddressForm.controls['barangay'].patchValue('')
      this.validateAddressForm.controls['street'].patchValue('')
    } else if (event.target.name === "city") {
      //LOAD BARANGAYS
      const province = this.validateAddressForm.controls["province"].value
      const city = this.validateAddressForm.controls["city"].value
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.gelatoService.getBarangays(province.province_code, city.city_code).subscribe(x => {
        this.store.dispatch(new UpdateBarangays(x.data["barangays"]))
        this.store.dispatch(new UpdateStreets(x.data[""]))
      })
      this.gelatoService.getBuildings(province.province_code, city.city_code).subscribe(x => {
        this.store.dispatch(new UpdateBuildings(x.data["buildings"]))
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })

      // Reset other select
      document.getElementsByClassName('city')[0]['style'].color = 'black'
      document.getElementsByClassName('barangay')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('building')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      this.validateAddressForm.controls['barangay'].patchValue('')
      this.validateAddressForm.controls['street'].patchValue('')
    } else if (event.target.name === "barangay") {
      //LOAD STREETS
      const province = this.validateAddressForm.controls["province"].value
      const city = this.validateAddressForm.controls["city"].value
      const barangay = this.validateAddressForm.controls["barangay"].value
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.gelatoService.getStreets(province.province_code, city.city_code, barangay.brgy_code).subscribe(x => {
        this.store.dispatch(new UpdateStreets(x.data["streets"]))
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })

      // Reset other select
      document.getElementsByClassName('barangay')[0]['style'].color = 'black'
      document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      this.validateAddressForm.controls['street'].patchValue('')
    } else if (event.target.name === "building") {
      // Reset other select
      document.getElementsByClassName('building')[0]['style'].color = 'black'
      document.getElementsByClassName('barangay')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'

    }
    else document.getElementsByClassName('street')[0]['style'].color = 'black'

  }

  numberClick() {
    document.getElementById('number-wrap').classList.add('number-wrap');
  }

  numberBlur() {
    document.getElementsByClassName('number')[0]['value'].length < 1 ? document.getElementById('number-wrap').classList.remove('number-wrap') : null
  }

  // Restrict input to number characters only
  keyDown(e: any, number: boolean) {
    if (number && (e.keyCode == 69 || e.keyCode == 189 || e.keyCode == 190)) {
      return false
    }
    else {
      return true
    }
  }

  handleReset() {

  }
  handleReady() {
    this.captchaElem.execute();
  }
  handleLoad() { }
  handleExpire() { }
  handleSuccess(e) {
    //  Success Google Captcha
    this.gRecaptcha = e
  }

}
