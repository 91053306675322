import { environment } from 'src/environments/environment';
import { Address } from 'src/app/shared/models/address.model';
import { PlanDetail } from 'src/app/shared/models/planDetail.model';
import { UpdateHelpMeChoose, ResetHelpMeChoose, UpdateComparePlans, AddSelectedPlan, UpdateHTTPRequestFlag, AddSelectedDevice, UpdateBillingSummary, UpdateBillingSummaryPayload, RemoveSelectedDevice, RemoveSelectedPlan, RemoveSelectedMeshDevice, RemoveSelectedMeshLockin, RemoveSelectedChannel, RemoveSelectedPack } from './../../../../shared/actions/gelato.actions';
import { Plan } from 'src/app/shared/models/plan.model';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { ServicePlan } from 'src/app/shared/models/servicePlan.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Router } from '@angular/router';
import * as _ from 'lodash'
import { Service } from 'src/app/shared/models/service.model';
import { Device } from 'src/app/shared/models/device.model';
import { Channel } from 'src/app/shared/models/channel.model';
import { Pack } from 'src/app/shared/models/pack.model';
@Component({
  selector: 'app-bundle-help-me-choose',
  templateUrl: './bundle-help-me-choose.component.html',
  styleUrls: ['./bundle-help-me-choose.component.css']
})
export class BundleHelpMeChooseComponent implements OnInit {

  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getHelpMeChoose) selected$: Observable<any>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  selectedPlans:[Plan]
  showCompare: boolean = false;
  plansFlag:boolean = false
  compareFlag:boolean = false
  servicePlan:ServicePlan[]
  selected:Plan[]
  blanckBox:[]
  compareError : boolean = false
  isDisabled: any;
  twoWordPlan: boolean = false;
  firstWord: String = "";
  selectedCategory: string;
  address:Address
  billingSummaryPayload:any
  province_code:string = ""
  selectedDevices:Device[] = []
  googleAddress: string = localStorage.getItem("google_address")
  env = environment
  selectedMesh:Device[] =[]
  selectedPacks:Pack[] = []
  selectedChannels:Channel[] = []
  constructor(private srv: GelatoState, private store: Store,private gelatoService:GelatoService,private router: Router) { }

  header: any;
   message: any;
   
  ngOnInit() {
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google || this.googleAddress) {
          this.province_code = x.data["province"]["code"]
        }
      })
    })

    this.selectedPacks$.subscribe(x => {
      this.selectedPacks = x
    })

    this.selectedChannels$.subscribe(x => {
      this.selectedChannels = x
    })
    
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    this.selectedCategory$.subscribe(x => {
      this.selectedCategory = x
    })
  this.resetComparison()
    this.headerText$.subscribe(x => {
      this.header = x.cabletv_plan_screen.cable_tv_help_me_choose_heading_text
      this.message = x.cabletv_plan_screen.cable_tv_help_me_choose_heading_subtext
    })
    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
    this.gelatoService.getServicesByPlanID("3",this.province_code, this.selectedCategory).subscribe(x => {
      this.servicePlan = x.data["service_plans"]
    })
    this.store.select(state => state.gelato.help_me_choose).subscribe(x => {
      this.selected = x
    });
        
    this.headerText$.subscribe(x => {
      this.header = x.cabletv_plan_screen.cable_tv_help_me_choose_heading_text
      this.message = x.cabletv_plan_screen.cable_tv_help_me_choose_heading_subtext
    })
    
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    })

    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMesh =x
    })
  }

  displayPlans(){
    this.togglePlan()
   
  }
  togglePlan(){
    this.plansFlag = !this.plansFlag
    this.compareError = false
    this.toggleError()
  }
  toggleCompare(){
    this.compareError = false
    if(this.selected != undefined){
      if(this.selected.length > 1){
        this.compareFlag = !this.compareFlag
      }
      else{
        this.compareError = true
        this.toggleError()
      }
    }
    else {
      this.compareError = true
      this.toggleError()
    }

   
  }
  selectPlan(plan:Plan){
    this.store.dispatch(new UpdateHelpMeChoose(plan))
    this.togglePlan()
    if(this.selected.length == 3){
      this.isDisabled = "none";
    }
  }
  resetComparison(){
    this.compareFlag =  false
    this.plansFlag = false
    this.store.dispatch(new UpdateHTTPRequestFlag(false))
    this.store.dispatch(new ResetHelpMeChoose())
    this.isDisabled = "any";
  }

  getThisFlag(planDetail:PlanDetail){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.id === planDetail.id)
    }
    else{
      return false
    }
  }

  getThis(plan:Plan) {
    
    if(this.selectedPlans){
      this.selectedPlans.filter(x => x.service_id === environment.SERVICE_BUNDLE).map(y => {
        this.store.dispatch(new RemoveSelectedPlan(y.id))
        // y.comes_with.map(x => {
        //   this.store.dispatch(new RemoveSelectedDevice(x.id))      
        // })
      }) 

      this.selectedDevices.filter(device => device.device_service_type == environment.SERVICE_BUNDLE).map(x => {
        this.store.dispatch(new RemoveSelectedDevice(x.id, environment.SERVICE_BUNDLE))
      })

      this.selectedMesh.map(x => {
        this.store.dispatch(new RemoveSelectedMeshDevice(x.id))
        this.store.dispatch(new RemoveSelectedMeshLockin(x.name))
      })
      if(this.selectedPacks){
        this.selectedPacks.forEach(pack => {
          this.store.dispatch(new RemoveSelectedPack(pack.id))
        })
      }
      if(this.selectedChannels){
        this.selectedChannels.forEach(channel => {
          this.store.dispatch(new RemoveSelectedChannel(channel.id))
        })
      }
    }
    this.store.dispatch(new AddSelectedPlan(plan))
    plan.comes_with.map(x => {
      const copy = {...x, device_service_type: environment.SERVICE_BUNDLE}
      this.store.dispatch(new AddSelectedDevice(copy))
    })

    //CONSTRUCT SELECTED PLANS
    const selected = this.selectedPlans.map(plan => {
      return {
        "plan_id":plan.id,
        device_id: this.selectedDevices.filter(x => x.device_service_type == plan.service_id).map(x => x.id)
      }
    })
    const defaultAddon = {
        "devices":[],
        "channel_pack_id":[],
        "channel_id":[]
    }
    let payload = this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected, "addons":defaultAddon }
    // let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",selected)
    
    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      if(x.status_code === 200){
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }
    })    

    this.router.navigate(['/tv-plan/create-plan/tv-packs'])
  }

  compare(){
   this.showCompare = true
  }

  toggleError(){
    if(this.compareError){
      document.getElementsByClassName('add-choice')[0].classList.add('red-border')
      document.getElementsByClassName('add-choice')[1].classList.add('red-border')
    }
    else{
      document.getElementsByClassName('add-choice')[0].classList.remove('red-border')
      document.getElementsByClassName('add-choice')[1].classList.remove('red-border')

    }
  }
  
  editPlanName(planName){
    var split = planName.split(" ")
    if(split.length > 1){
      return split[1]
    }
    else{
      return planName
    }
  }

}
