import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs';
import { Plan } from '../../models/plan.model';
import { PlanDetail } from '../../models/planDetail.model';
import { Router } from '@angular/router';
import { AddSelectedPlan, UpdateHelpMeChoose, AddSelectedDevice, UpdateBillingSummaryPayload, UpdateBillingSummary, RemoveSelectedDevice, RemoveSelectedMeshDevice, RemoveSelectedMeshLockin, RemoveSelectedPlan } from '../../actions/gelato.actions';
import { ResponseObject } from '../../models/response-object.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import * as _ from 'lodash'
import { environment } from 'src/environments/environment';
import { Device } from '../../models/device.model';

@Component({
  selector: 'app-channel-lineup',
  templateUrl: './channel-lineup.component.html',
  styleUrls: ['./channel-lineup.component.css']
})

export class ChannelLineupComponent implements OnInit {
  @Select(GelatoState.getHelpMeChoose) helpMeChoose$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getPlanDetail) planDetails$: Observable<ResponseObject<PlanDetail>>;
  @Select(GelatoState.getHelpMeChoose) selected$: Observable<any>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>
  @Input() plan: Plan;
  @Input() selectedPlan:[]
  @Input() Detail:[]
  @Output() getThisEvent = new EventEmitter();
  selectedPlans:[Plan]
  planDetail:PlanDetail
  billingSummaryPayload:any
  selectedMesh:Device[] =[]
  selectedDevices:Device[] = []
  constructor(private srv: GelatoState,private store: Store,private gelatoService:GelatoService,private router: Router) 
  { 
    this.planDetails$.subscribe(x=> {
      this.planDetail = x.data["plan_details"]
    })
  }

  ngOnInit() {
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    })

    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMesh =x
    })
  }

  getThisFlag(planDetail:PlanDetail){
    // Check if plan selected
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.id === planDetail.id)
    }else{
      return false
    }
  }
 

  getThis(plan:Plan) {
    console.warn(plan)
    if(this.selectedPlans){
      this.selectedPlans.filter(x => x.service_id === environment.SERVICE_BUNDLE).map(y => {
        this.store.dispatch(new RemoveSelectedPlan(y.id))
        // y.comes_with.map(x => {
        //   this.store.dispatch(new RemoveSelectedDevice(x.id))      
        // })
      }) 

      this.selectedDevices.filter(device => device.device_service_type == environment.SERVICE_BUNDLE).map(x => {
        this.store.dispatch(new RemoveSelectedDevice(x.id, environment.SERVICE_BUNDLE))
      })

      this.selectedMesh.map(x => {
        this.store.dispatch(new RemoveSelectedMeshDevice(x.id))
        this.store.dispatch(new RemoveSelectedMeshLockin(x.name))
      })
    }
    this.store.dispatch(new AddSelectedPlan(plan))
    plan.comes_with.map(x => {
      const copy = {...x, device_service_type: environment.SERVICE_BUNDLE}
      console.warn(copy,"HERE")
      this.store.dispatch(new AddSelectedDevice(copy))
    })

    //CONSTRUCT SELECTED PLANS
    const selected = this.selectedPlans.map(plan => {
      return {
        "plan_id":plan.id,
        device_id: this.selectedDevices.filter(x => x.device_service_type == plan.service_id).map(x => x.id)
      }
    })
    const defaultAddon = {
        "devices":[],
        "channel_pack_id":[],
        "channel_id":[]
    }
    let payload = this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected, "addons":defaultAddon }
    // let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",selected)
    
    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      if(x.status_code === 200){
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }
    })    

    this.router.navigate(['/tv-plan/create-plan/tv-packs'])
  }

}

