import { UpdateDeviceCollection } from './../../../../shared/actions/gelato.actions';
import { DeviceCollection } from './../../../../shared/models/device-collection.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateDevices, UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { Device } from 'src/app/shared/models/device.model';
import { Devices } from 'src/app/shared/models/devices.model';
import { Plan } from 'src/app/shared/models/plan.model';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lite-select-device',
  templateUrl: './lite-select-device.component.html',
  styleUrls: ['./lite-select-device.component.css']
})
export class LiteSelectDeviceComponent implements OnInit {
  @Select(GelatoState.getDeviceCollection) devices$: Observable<ResponseObject<DeviceCollection>>;
  @Select(GelatoState.getSelectedDevices) selectedDevice$: Observable<[Device]>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  header:String = "Select your SKY Fiber cable modem and SKYcable digibox"
  requestFlag:boolean = true
  twoInOneDevices: any = [];
  devices: DeviceCollection[]
  selectedDevice:Device[] = []
  selectedPlans:Plan[] = []
  constructor(private router: Router,private store: Store,private gelatoService:GelatoService) {
    
    this.store.dispatch(new UpdateHTTPRequestFlag(true))

    this.gelatoService.getDeviceCollection().subscribe(x => {
      this.store.dispatch(new UpdateDeviceCollection(x))
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      // Update device list w/ mesh
      this.gelatoService.getDevices().subscribe(x => {
        this.store.dispatch(new UpdateDevices(x))
      })
      this.devices = x.data['device_collections']
      this.requestFlag = false
    })

    this.selectedDevice$.subscribe(x => {
      if(x){
        this.selectedDevice = x
      }
    })
    
    this.selectedPlans$.subscribe(x => {
      if(x) {
        this.selectedPlans = x
      }
    })

   
  }

  ngOnInit() {
  }

  nextClick(){
    this.router.navigate(['/tv-plan/lite-addon'])
  }

  isUpgradableFlag(){
    return this.selectedPlans.filter(x => x.service_id == environment.SERVICE_INTERNET).some(x => x.is_upgradeable == 1)
  }

  nextFlag(){
    return this.selectedDevice.some(x => x.is_2in1 == true || x.device_type_id == environment.DEVICE_CABLE)
  }

}
