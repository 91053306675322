import { DeviceCollection } from './../models/device-collection.model';
import { Building } from './../models/building.model';
import { MeshDevice } from './../models/mesh-device.model';
import { Device } from './../models/device.model';
import { VoucherCode } from './../models/voucher-code';
import { DeviceComparison } from './../models/device-comparison.model';
import { DeviceDetail } from './../models/device-detail.model';
import { ChannelObject } from './../models/channel-object.model';
import { PackDetail } from './../models/pack-detail.model';
import { ChannelPack } from './../models/channel-pack.model';
import { Service } from './../models/service.model';
import { Address } from './../models/address.model';
import { Barangay } from './../models/barangay.model';
import { Province } from 'src/app/shared/models/province.model';
import { HeaderText } from '../models/header-text.model';
import { City } from '../models/city.model';
import { Street } from '../models/street.model';
import { ServicePlan } from '../models/servicePlan.model';
import { PlanDetail } from '../models/planDetail.model';
import { ChannelComparison } from '../models/channel-comparison.model';
import { ResponseObject } from '../models/response-object.model';
import { Genre } from '../models/genre.model';
import { Channel } from '../models/channel.model';
import { Devices } from '../models/devices.model';
import { Plan } from '../models/plan.model';
import { Pack } from '../models/pack.model';
import { AvailableLockinPeriod } from '../models/available-lockin-period.model';
import { CableBoxDevice } from '../models/cable-box-device.model';
import { ApplicationDetails } from '../models/application-details.model';
import { QrCode } from '../models/qr-code.model';


export class UpdateHTTPRequestFlag {
    static readonly type = '[GELATO] UpdateHTTPRequestFlag'
    constructor(public payload: boolean) { }
}

export class UpdateHeaderText {
    static readonly type = '[GELATO] UpdateHeaderText'
    constructor(public payload: HeaderText) { }
}

export class UpdateProvinces {
    static readonly type = '[GELATO] UpdateProvinces'
    constructor(public payload: [Province]) { }
}

export class UpdateCities {
    static readonly type = '[GELATO] UpdateCities'
    constructor(public payload: [City]) { }
}

export class UpdateBuildings {
    static readonly type = '[GELATO] UpdateBuildings'
    constructor(public payload: [Building]) { }
}

export class UpdateBarangays {
    static readonly type = '[GELATO] UpdateBarangays'
    constructor(public payload: [Barangay]) { }
}

export class UpdateStreets {
    static readonly type = '[GELATO] UpdateStreets'
    constructor(public payload: [Street]) { }
}

export class UpdateFormAddress {
    static readonly type = '[GELATO] UpdateFormAddress'
    constructor(public payload: Address) { }
}

export class UpdateServices {
    static readonly type = '[GELATO] UpdateServices'
    constructor(public payload: ResponseObject<[Service]>) { }
}

export class UpdateServicePlan {
    static readonly type = '[GELATO] UpdateServicePlan'
    constructor(public payload: ResponseObject<[ServicePlan]>) { }
}

export class UpdatePlanDetails {
    static readonly type = '[GELATO] UpdatePlanDetails'
    constructor(public payload: ResponseObject<PlanDetail>) { }
}

export class UpdateComparePlans {
    static readonly type = '[GELATO] UpdateComparePlans'
    constructor(public payload: ResponseObject<[PlanDetail]>) { }
}

export class UpdateCompareChannels {
    static readonly type = '[GELATO] UpdateCompareChannels'
    constructor(public payload: ResponseObject<ChannelComparison>) { }
}

export class UpdateGenres {
    static readonly type = '[GELATO] UpdateGenres'
    constructor(public payload: ResponseObject<[Genre]>) { }
}

export class UpdateGenreDetail {
    static readonly type = '[GELATO] UpdateGenreDetail'
    constructor(public payload: ResponseObject<Genre>) { }
}

export class UpdateChannelPacks {
    static readonly type = '[GELATO] UpdateChannelPacks'
    constructor(public payload: ResponseObject<ChannelPack>) { }
}

export class UpdatePackDetail {
    static readonly type = '[GELATO] UpdatePackDetail'
    constructor(public payload: ResponseObject<PackDetail>) { }
}

export class UpdateChannels {
    static readonly type = '[GELATO] UpdateChannels'
    constructor(public payload: ResponseObject<ChannelObject>) { }
}

export class UpdateChannelDetail {
    static readonly type = '[GELATO] UpdateChannelDetail'
    constructor(public payload: ResponseObject<Channel>) { }
}

export class UpdateDevices {
    static readonly type = '[GELATO] UpdateDevices'
    constructor(public payload: ResponseObject<Devices>) { }
}

export class UpdateLockinPeriod {
    static readonly type = '[GELATO] UpdateLockPeriod'
    constructor(public payload: ResponseObject<AvailableLockinPeriod>) { }
}

export class UpdateDeviceDetail {
    static readonly type = '[GELATO] UpdateDeviceDetail'
    constructor(public payload: ResponseObject<DeviceDetail>) { }
}

export class UpdateDeviceCollection {
    static readonly type = '[GELATO] UpdateDeviceCollection'
    constructor(public payload: ResponseObject<DeviceCollection>) { }
}

export class UpdateCompareDevices {
    static readonly type = '[GELATO] UpdateCompareDevices'
    constructor(public payload: ResponseObject<DeviceComparison>) { }
}

export class UpdateHelpMeChoose {
    static readonly type = '[GELATO] UpdateHelpMeChoose'
    constructor(public payload: any) { }
}

export class ResetHelpMeChoose {
    static readonly type = '[GELATO] ResetHelpMeChoose'
    constructor() { }
}
export class UpdateSelectedService {
    static readonly type = '[GELATO] UpdateSelectedService'
    constructor(public payload: number) { }
}
export class UpdateSelectedServiceCategory {
    static readonly type = '[GELATO] UpdateSelectedServiceCategory'
    constructor(public payload: string) { }
}

export class UpdateSelectedCableTvPlan {
    static readonly type = '[GELATO] UpdateSelectedCableTvPlan'
    constructor(public payload: Plan) { }
}

export class UpdateBillingSummary {
    static readonly type = '[GELATO] UpdateBillingSummary'
    constructor(public payload: any) { }
}

export class AddSelectedPlan {
    static readonly type = '[GELATO] AddSelectedPlan'
    constructor(public payload: Plan) { }
}

export class RemoveSelectedPlan {
    static readonly type = '[GELATO] RemoveSelectedPlan'
    constructor(public payload: number) { }
}

export class AddSelectedPack {
    static readonly type = '[GELATO] AddSelectedPack'
    constructor(public payload: Pack) { }
}

export class RemoveSelectedPack {
    static readonly type = '[GELATO] RemoveSelectedPack'
    constructor(public payload: number) { }
}

export class AddSelectedChannel {
    static readonly type = '[GELATO] AddSelectedChannel'
    constructor(public payload: Channel) { }
}

export class RemoveSelectedChannel {
    static readonly type = '[GELATO] RemoveSelectedChannel'
    constructor(public payload: number) { }
}

export class UpdateVoucherCode {
    static readonly type = '[GELATO] UpdateVoucherCode'
    constructor(public payload: ResponseObject<VoucherCode>) { }
}

export class UpdateEditMode {
    static readonly type = '[GELATO] UpdateEditMode'
    constructor(public payload: boolean) { }
}

export class UpdateEditOriginLink {
    static readonly type = '[GELATO] UpdateEditOriginLink'
    constructor(public payload: String) { }
}

export class UpdateApplyButtonFlag {
    static readonly type = '[GELATO] UpdateApplyButtonFlag'
    constructor(public payload: boolean) { }
}

export class AddSelectedDevice {
    static readonly type = '[GELATO] AddSelectedDevice'
    constructor(public payload: Device) { }
}

export class UpdateSelectedCableTvDevice {
    static readonly type = '[GELATO] UpdateSelectedCableTvDevice'
    constructor(public payload: Device) { }
}
export class AddApplicationDetails {
    static readonly type = '[GELATO] AddApplicationDetails'
    constructor(public payload: ApplicationDetails) { }
}

export class RemoveSelectedDevice {
    static readonly type = '[GELATO] RemoveSelectedDevice'
    constructor(public payload: number, public service_type?: number) { }
}

export class UpdateSelectedAvailableLockinPeriod {
    static readonly type = '[GELATO] UpdateSelectedAvailableLockinPeriod'
    constructor(public payload: AvailableLockinPeriod) { }
}

export class AddSelectedMeshDevice {
    static readonly type = '[GELATO] AddSelectedMeshDevice'
    constructor(public payload: any) { }
}

export class RemoveSelectedMeshDevice {
    static readonly type = '[GELATO] RemoveSelectedMeshDevice'
    constructor(public payload: any) { }
}


export class AddSelectedMeshLockin {
    static readonly type = '[GELATO] AddSelectedMeshLockin'
    constructor(public payload: AvailableLockinPeriod) { }
}

export class RemoveSelectedMeshLockin {
    static readonly type = '[GELATO] RemoveSelectedMeshLockin'
    constructor(public payload: string) { }
}

export class AddSelectedCableDevice {
    static readonly type = '[GELATO] AddSelectedCableDevice'
    constructor(public payload: CableBoxDevice) { }
}

export class AddSelectedCableDeviceCountId {
    static readonly type = '[GELATO] AddSelectedCableDeviceCountId'
    constructor(public payload: number) { }
}

export class RemoveCableIndexId {
    static readonly type = '[GELATO] RemoveCableIndexId'
    constructor(public payload: any) { }
}

export class ResetCableIndexId {
    static readonly type = '[GELATO] ResetCableIndexId'
    constructor() { }
}

export class AddSelectedCableDeviceIndex {
    static readonly type = '[GELATO] AddSelectedCableDeviceIndex'
    constructor(public payload: number) { }
}

export class AddOrdinalPostion {
    static readonly type = '[GELATO] AddOrdinalPostion'
    constructor(public payload: number) { }
}
export class AddMonthlyFee {
    static readonly type = '[GELATO] AddMonthlyFee'
    constructor(public payload: number) { }
}
export class AddInstallFee {
    static readonly type = '[GELATO] AddInstallFee'
    constructor(public payload: number) { }
}

export class RemoveSelectedCableDevice {
    static readonly type = '[GELATO] RemoveSelectedCableDevice'
    constructor(public payload: number) { }
}
export class RemoveSelectedCableDeviceId {
    static readonly type = '[GELATO] RemoveSelectedCableDeviceId'
    constructor(public payload: any) { }
}
export class UpdateSelectedCableDevice {
    static readonly type = '[GELATO] UpdateSelectedCableDevice'
    constructor(public payload: any) { }
}
export class UpdateSelectedCableDeviceId {
    static readonly type = '[GELATO] UpdateSelectedCableDeviceId'
    constructor(public payload: any) { }
}
export class AddSelectedCableLockin {
    static readonly type = '[GELATO] AddSelectedCableLockin'
    constructor(public payload: AvailableLockinPeriod) { }
}

export class RemoveSelectedCableLockin {
    static readonly type = '[GELATO] RemoveSelectedCableLockin'
    constructor(public payload: string) { }
}

export class ResetAll {
    static readonly type = '[GELATO] ResetAll'
    constructor() { }
}
export class ResetCableAddons {
    static readonly type = '[GELATO] ResetCableAddons'
    constructor() { }
}
export class AddConfirmationData {
    static readonly type = '[GELATO] AddConfirmationData'
    constructor(public payload: any) { }
}

export class UpdateCreateYourOwnFlag {
    static readonly type = '[GELATO] UpdateCreateYourOwnFlag'
    constructor(public payload: boolean) { }
}

export class UpdateVisitedPages {
    static readonly type = '[GELATO] UpdateVisitedPages'
    constructor(public payload: any) { }
}

export class UpdateValidIDs {
    static readonly type = '[GELATO] UpdateValidIDs'
    constructor(public payload: any) { }
}

export class UpdateSecondaryIDs {
    static readonly type = '[GELATO] UpdateSecondaryIDs'
    constructor(public payload: any) { }
}

export class UpdateGoogleLATLONG {
    static readonly type = '[GELATO] UpdateGoogleLATLONG'
    constructor(public glat: number, public glong: number) { }
}

export class UpdateGoogleAddress {
    static readonly type = '[GELATO] UpdateGoogleAddress'
    constructor(public payload: any) { }
}

export class UpdateBillingSummaryPayload {
    static readonly type = '[GELATO] UpdateBillingSummaryPayload'
    constructor(public payload: any) { }
}

export class UpdateNumberOfBoxes {
    static readonly type = '[GELATO] UpdateNumberOfBoxes'
    constructor(public payload: number) { }
}

export class UpdateCableLite {
    static readonly type = '[GELATO] UpdateCableLite'
    constructor(public payload: boolean) { }
}

export class AddQrCodeData {
    static readonly type = '[GELATO] AddQrCodeData'
    constructor(public payload: QrCode) { }
}
