import { environment } from 'src/environments/environment';
import { Address } from 'src/app/shared/models/address.model';
import { PlanDetail } from 'src/app/shared/models/planDetail.model';
import { UpdateHelpMeChoose, ResetHelpMeChoose, UpdateComparePlans, AddSelectedPlan, AddSelectedDevice, UpdateBillingSummary, UpdateBillingSummaryPayload, RemoveSelectedDevice, RemoveSelectedPlan, RemoveSelectedMeshDevice, RemoveSelectedMeshLockin } from './../../../../shared/actions/gelato.actions';
import { Plan } from 'src/app/shared/models/plan.model';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { ServicePlan } from 'src/app/shared/models/servicePlan.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Router } from '@angular/router';
import { Service } from 'src/app/shared/models/service.model';
import * as _ from 'lodash'
import { Device } from 'src/app/shared/models/device.model';
@Component({
  selector: 'app-internet-help-me-choose',
  templateUrl: './internet-help-me-choose.component.html',
  styleUrls: ['./internet-help-me-choose.component.css']
})
export class InternetHelpMeChooseComponent implements OnInit {

  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getHelpMeChoose) selected$: Observable<any>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>
  selectedPlans:[Plan]
  showCompare: boolean = false;
  plansFlag:boolean = false
  compareFlag:boolean = false
  servicePlan:ServicePlan[]
  selected:Plan[]
  blanckBox:[]
  compareError : boolean = false
  address:Address
  billingSummaryPayload:any
  province_code:string = ""
  googleAddress: string = localStorage.getItem("google_address")
  selectedDevices:Device[] =  []
  selectedMesh:Device[] =[]
  selectedCable:Device[]= []
  constructor(private store: Store,private gelatoService:GelatoService,private router: Router) {
   }
   header: any;
   message: any;

  ngOnInit() {
    this.resetComparison()
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google || this.googleAddress) {
          this.province_code = x.data["province"]["code"]
        }
      })
    })
    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
    this.selectedCategory$.subscribe(x => {
      if(x){
        this.gelatoService.getServicesByPlanID("1",this.province_code, x).subscribe(x => {
          this.servicePlan = x.data["service_plans"]
        })
      }
    })


    this.store.select(state => state.gelato.help_me_choose).subscribe(x => {
      this.selected = x
    });

    this.headerText$.subscribe(x => {
      this.header = x.cabletv_plan_screen.cable_tv_help_me_choose_heading_text
      this.message = x.cabletv_plan_screen.cable_tv_help_me_choose_heading_subtext
    })

    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })

    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })

    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    })

    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMesh =x
    })

    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCable = x
    })
  }

  getThisFlag(planDetail:PlanDetail){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.id === planDetail.id)
    }else{
      return false
    }
  }

  displayPlans(){
    this.togglePlan()
  }
  togglePlan(){
    this.plansFlag = !this.plansFlag
    this.compareError = false
    this.toggleError()
  }
  toggleCompare(){
    this.compareError = false
    if(this.selected != undefined){
      if(this.selected.length > 1){
        this.compareFlag = !this.compareFlag
      }
      else{
        this.compareError = true
        this.toggleError()
      }
    }
    else {
      this.compareError = true
      this.toggleError()
    }

  }
  selectPlan(plan:Plan){
    this.store.dispatch(new UpdateHelpMeChoose(plan))
    this.togglePlan()
  }
  resetComparison(){
    this.compareFlag =  false
    this.plansFlag = false
    this.store.dispatch(new ResetHelpMeChoose())
  }

  getThis(plan:Plan) {
    if(this.selectedPlans){
      this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET).map(y => {
        this.store.dispatch(new RemoveSelectedPlan(y.id))
        // y.comes_with.map(x => {
        //   this.store.dispatch(new RemoveSelectedDevice(x.id))
        // })
      })
      this.selectedDevices.filter(device => device.device_service_type == environment.SERVICE_INTERNET).map(x => {
        this.store.dispatch(new RemoveSelectedDevice(x.id, environment.SERVICE_INTERNET))
      })

      this.selectedMesh.map(x => {
        this.store.dispatch(new RemoveSelectedMeshDevice(x.id))
        this.store.dispatch(new RemoveSelectedMeshLockin(x.name))
      })
    }
    this.store.dispatch(new AddSelectedPlan(plan))
    plan.comes_with.map(x => {
      const copy = {...x, device_service_type: environment.SERVICE_INTERNET}
      this.store.dispatch(new AddSelectedDevice(copy))
    })


    //CONSTRUCT SELECTED PLANS
    const selected = this.selectedPlans.map(plan => {
      return {
        "plan_id":plan.id,
        device_id: this.selectedDevices.filter(x => x.device_service_type == plan.service_id).map(x => x.id)
      }
    })
    const devices = this.selectedMesh.map(x => {
      return {

        "device_id": x.id,
        "lockin_period_id": x.lockin_period_id || x.lockin_period['id']
      }
    })
    this.selectedCable.map(x => {
      devices.push({
        "device_id": x.id,
        "lockin_period_id": x.lockin_period_id
      })
    })
    // let payload = this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected, "addons.devices":devices }
    let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",selected)
    payload = _.set(JSON.parse(JSON.stringify(payload)), "addons.devices", devices)

    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      if(x.status_code === 200){
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }
    })

    let sub1 = this.selectedCategory$.subscribe(x => {
      // force to plan sumary due to removal plans
      this.router.navigate(['/internet-plan/plan-summary']).then(x => {
        sub1.unsubscribe()
      })
    })

    /*
    let sub = this.selectedService$.subscribe(x => {
      if(x === environment.SERVICE_INTERNET){
        this.router.navigate(['/tv-plan/choose-plan']).then(x => sub.unsubscribe())
      }else{
        this.selectedCategory$.subscribe(x => {
          if(x){
            if(x === "FTTH"){
              this.router.navigate(['/internet-plan/plan-summary']).then(x => sub.unsubscribe())
            }else{
              //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
              if(this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_BUNDLE).some(x => x.is_upgradeable === 1)){
                this.router.navigate(['/internet-plan/select-device'])
              }else{
                this.router.navigate(['/internet-plan/select-addon']).then(x => sub.unsubscribe())
              }
            }
          }
        })
      }
    })
    */
  }

  toggleError(){
    if(this.compareError){
      document.getElementsByClassName('add-choice')[0].classList.add('red-border')
      document.getElementsByClassName('add-choice')[1].classList.add('red-border')
    }
    else{
      document.getElementsByClassName('add-choice')[0].classList.remove('red-border')
      document.getElementsByClassName('add-choice')[1].classList.remove('red-border')

    }
  }
}
