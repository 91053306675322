import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StandaloneRoutingModule } from './standalone-routing.module';
import { FormComponent } from './pages/form/form.component';
import { StandaloneContainerComponent } from './pages/standalone-container/standalone-container.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [FormComponent, StandaloneContainerComponent],
  imports: [
    CommonModule,
    StandaloneRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    NgxCaptchaModule
  ]
})
export class StandaloneModule { }
