import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs';
import { ResponseObject } from '../../models/response-object.model';
import { Devices } from '../../models/devices.model';
import { Router } from '@angular/router';
import { HeaderText } from '../../models/header-text.model';
import { Device } from '../../models/device.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-choose-mesh-box',
  templateUrl: './choose-mesh-box.component.html',
  styleUrls: ['./choose-mesh-box.component.css']
})
export class ChooseMeshBoxComponent implements OnInit {
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getDevices) devices$: Observable<ResponseObject<Devices>>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;

  selectedMesh:Device[]
  constructor(private router: Router) { }

  ngOnInit() {
    this.selectedDevices$.subscribe(x => {
      this.selectedMesh = x.filter(device => device.device_type_id == environment.DEVICE_MESH)
    })
  }

  goTo(x){
    this.router.navigate([x])
  }
}
