import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { KeycloakService } from 'keycloak-angular';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/core/services/account.service';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { InternetViewDetailsModalComponent } from 'src/app/modules/internet-plan/components/internet-view-details-modal/internet-view-details-modal.component';
import { UpdateHTTPRequestFlag, UpdateHeaderText, AddConfirmationData } from 'src/app/shared/actions/gelato.actions';
import { GenericErrorModalComponent } from 'src/app/shared/components/generic-error-modal/generic-error-modal.component';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { UpgradeBody } from 'src/app/shared/models/upgrade-request.model';
import { UpgradeType } from 'src/app/shared/models/upgrade-type.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { environment } from 'src/environments/environment';
import { AccountNumberDialogComponent } from '../../components/account-number-dialog/account-number-dialog.component';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  upgradeTypes: [UpgradeType]
  upgradeForm: FormGroup
  submitted: boolean = false
  invalidAccountNumber: boolean = false
  isLoggedIn: boolean = false

  // Captcha configs
  public siteKey = environment.GOOGLE_RECAPTCHA_KEY;
  public badge = 'inline';
  public type = 'image';
  public theme = 'light';
  public recaptcha: any = null;
  public size = 'Normal';
  public lang = 'en';
  gRecaptcha: String;
  @ViewChild('captchaElem', {}) captchaElem: InvisibleReCaptchaComponent;

  constructor(private gelatoService: GelatoService, private store: Store, private formBuilder: FormBuilder, private dialog: MatDialog, private router: Router, private keycloak: KeycloakService, private accountService: AccountService) {

    this.gelatoService.getHeaderText().subscribe(headings => {
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.store.dispatch(new UpdateHeaderText(headings.data))
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
    })

    gelatoService.getUpgradeTypes().subscribe(upgrades => {
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.upgradeTypes = upgrades.data
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
    })

    this.upgradeForm = this.formBuilder.group({
      type: ['', Validators.required],
      accountNumber: ['', Validators.compose([Validators.required, Validators.minLength(9)])],
      name: ['', Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9\\-\\s\\ñ\\Ñ']+$")])],
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.pattern('^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\\.[a-z]{2,4}$')])],
      mobile: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(12), Validators.min(999999999)])],
      terms: [false, Validators.requiredTrue],
      privacy: [false, Validators.requiredTrue],
      recaptcha: ['', Validators.required]
    })

    this.keycloak.isLoggedIn().then((is) => {
      if (is) {
        this.keycloak.loadUserProfile().then((x) => {
          if (x) {
            this.isLoggedIn = true
            this.upgradeForm.controls.accountNumber.setValue(parseInt(x['attributes']['skyaccount'][0]))
            this.upgradeForm.controls.email.setValue(x.email)
            this.upgradeForm.controls.accountNumber.disable()
          }
        });
      }
    });
  }

  ngOnInit() {
  }

  submit(form: FormGroup) {
    this.submitted = true
    if (form.valid && !this.isLoggedIn) {
      this.checkAccountNumber(form.value.accountNumber, form)
    } else if (form.valid) {
      this.submitUpgrade(form)
    }
  }

  checkAccountNumber(accountNumber: number, form: FormGroup) {
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.accountService.getContactInfo(accountNumber.toString()).subscribe(res => {
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      if (res && res.Status != '0') {
        this.invalidAccountNumber = false
        this.submitUpgrade(form)
      } else if (res.Status == '0') {
        this.invalidAccountNumber = true
      }
    }, (error) => {
      console.error(error.message)
      this.dialog.open(GenericErrorModalComponent, {
        autoFocus: false,
        height: '150px',
        width: '300px'
      })
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
    })
  }

  submitUpgrade(form: FormGroup) {
    let body: UpgradeBody = {
      application_details: {
        upgrade_type_id: form.value.type.id,
        account_no: form.getRawValue().accountNumber,
        contact_name: form.value.name,
        email_address: form.value.email,
        mobile_no: form.value.mobile,
        terms: form.value.terms,
        privacy: form.value.privacy
      }
    }
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.gelatoService.submitUpgradeRequest(body).subscribe(x => {
      if (x.status_code === 200) {
        let data = { ...x.data, transaction: true }
        this.store.dispatch(new AddConfirmationData(data))
        this.router.navigate(['/confirmation'])
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      } else {
        console.error(x.message)
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      }
    }, (error) => {
      console.error(error.message)
      this.dialog.open(GenericErrorModalComponent, {
        autoFocus: false,
        height: '150px',
        width: '300px'
      })
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
    })
  }

  openModal() {
    const dialogRef = this.dialog.open(AccountNumberDialogComponent, {
      autoFocus: false,
      height: '95%',
      width: '660px',
    });
  }

  numberClick() {
    document.getElementById('number-wrap').classList.add('number-wrap');
  }

  numberBlur() {
    document.getElementsByClassName('number')[0]['value'].length < 1 ? document.getElementById('number-wrap').classList.remove('number-wrap') : null
  }

  // Restrict input to number characters only
  keyDown(e: any, number: boolean) {
    if (number && (e.keyCode == 69 || e.keyCode == 189 || e.keyCode == 190)) {
      return false
    }
    else {
      return true
    }
  }

  handleReset() {

  }
  handleReady() {
    this.captchaElem.execute();
  }
  handleLoad() { }
  handleExpire() { }
  handleSuccess(e) {
    //  Success Google Captcha
    this.gRecaptcha = e
  }

}
