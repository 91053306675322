import { environment } from 'src/environments/environment';
import { Address } from 'src/app/shared/models/address.model';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { HeaderText } from './../../../../shared/models/header-text.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { ServicePlan } from './../../../../shared/models/servicePlan.model';
import { UpdateDevices, UpdateServicePlan } from './../../../../shared/actions/gelato.actions';
import { ProgressComponent } from './../../../../shared/components/progress/progress.component';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { TvPlanBoxContainerComponent } from '../../component/tv-plan-box-container/tv-plan-box-container.component'
import { Plan } from 'src/app/shared/models/plan.model';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Service } from 'src/app/shared/models/service.model';


@Component({
  selector: 'app-cable-lite',
  templateUrl: './cable-lite.component.html',
  styleUrls: ['./cable-lite.component.css']
})
export class CableLiteComponent implements OnInit {
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getHTTPRequestFlag) httpReqeuestFlag$: Observable<boolean>;
  @Select(GelatoState.getEditMode) editMode$: Observable<boolean>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  selectedPlans: [Plan]
  flag: boolean
  editMode: boolean
  selectedService: number
  servicePlan: ServicePlan[]
  address:Address
  province_code:string = ""
  hasInternetPlan:boolean = false
  hasMoreThanOnePlan:boolean=false
  isCableLite:boolean=false
  googleAddress: string = localStorage.getItem("google_address")
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  constructor(private router: Router, private srv: GelatoState, private store: Store, private gelatoService: GelatoService) {
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
  }
  public help: any;
  header: any;
  cableNext: any;
  ngOnInit() {
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google || this.googleAddress) {
          this.province_code = x.data["province"]["code"]
        }
      })
    })
    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
    this.selectedService$.subscribe(x => {
      this.selectedService = x
      if(this.selectedPlans.some(x=>x.service_id===environment.SERVICE_INTERNET)){
        this.hasInternetPlan = true
        this.isCableLite = true
      }
      
    })
    this.editMode$.subscribe(x => {
      this.editMode = x
    })

    this.headerText$.subscribe(x => {
      this.header = x.cabletv_plan_screen['cable_lite_heading_text']
      this.cableNext = x.cabletv_plan_screen.internet_first_heading_text
      this.help = x.cabletv_plan_screen.cable_tv_plan_heading_subtext_3
    })
    this.httpReqeuestFlag$.subscribe(x => {
      this.flag = x
    })
    this.selectedCategory$.subscribe(x => {
      if (x) {
        this.gelatoService.getServicesByPlanID("2",this.province_code,x, this.isCableLite).subscribe(x => {
          this.store.dispatch(new UpdateHTTPRequestFlag(true))
          this.servicePlan = x.data["service_plans"]
          //IF PLANS IS MORE THAN ONE, SHOW HELP ME CHOOSE
          if(this.servicePlan.reduce((x,y) => x+y.plans.length,0) > 1) this.hasMoreThanOnePlan = true
          console.log(this.hasMoreThanOnePlan)
          this.store.dispatch(new UpdateServicePlan(x.data["service_plans"]))
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        })
      }
    })


    
  }

  nextFlag() {
    if (this.selectedPlans) {
      return this.selectedPlans.some(x => x.service_id == environment.SERVICE_TV)
    }
    else {
      return false
    }
  }

  next() {

    if (this.selectedService) {
      if (this.selectedService === environment.SERVICE_TV) {
        this.router.navigate(['/internet-plan/select-plan'])
      } else if (this.selectedService == environment.SERVICE_BUNDLE) {
        this.selectedCategory$.subscribe(x => {
          if (x) {
            if (x === "FTTH") {
              this.router.navigate(['/bundle-plan/plan-summary'])
            } else {
              this.router.navigate(['/bundle-plan/select-device'])
            }
          }
        })
      } else {
        this.selectedCategory$.subscribe(x => {
          if (x) {
            if (x === "FTTH") {
              this.router.navigate(['/internet-plan/plan-summary'])
            } else {
              //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
              if(this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_BUNDLE).some(x => x.is_upgradeable === 1)){
                //UPDATE DEVICES, BECAUSE UPDATING OF DEVICES IS IN SELECT DEVICE.
                this.store.dispatch(new UpdateHTTPRequestFlag(true))
                this.gelatoService.getDevices().subscribe(x => {
                  this.store.dispatch(new UpdateDevices(x))
                  this.store.dispatch(new UpdateHTTPRequestFlag(false))
                  x.data.modem_devices.length > 1 ? this.router.navigate(['/internet-plan/select-device']) : this.router.navigate(['/internet-plan/select-addon'])
                })
              }else{
                this.store.dispatch(new UpdateHTTPRequestFlag(true))
                this.gelatoService.getDevices().subscribe(x => {
                  this.store.dispatch(new UpdateDevices(x))
                  this.store.dispatch(new UpdateHTTPRequestFlag(false))
                })
                this.router.navigate(['/internet-plan/select-addon'])
              }
            }
          }
        })
      }
    }
  }


  nextClick() {
    this.router.navigate(['tv-plan/create-plan/tv-packs'])
   
  }

  maybeFilter() {
    if (this.selectedPlans) {
      return !this.selectedPlans.some(x => x.service_id == environment.SERVICE_TV)
    }
    else {
      return true
    }
  }

}

