import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GelatoState } from '../../state/gelato.state';
import { browserRefresh } from 'src/app/app.component';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  @Select(GelatoState.getConfirmationDetails) confirmationDetails$: Observable<any>
  details: any[]
  constructor(private router: Router) {
  }

  ngOnInit() {
    if (browserRefresh) {
      this.router.navigate([''])
    }
    this.confirmationDetails$.subscribe(x => {
      this.details = x
    })

    // if(localStorage.getItem('s2sParam')){
    //   let key = localStorage.getItem('s2sParam');
    //   this.firePixel(key);
    // }
  }

  goto() {
    window.location.href = "https://www.mysky.com.ph/metromanila"
  }

  // firePixel(key) {
  //   var img = document.createElement("img");
  //   img.src = key;
  //   document.body.appendChild(img);
  // }
}
