import { Address } from 'src/app/shared/models/address.model';
import { Component, OnInit, Input } from '@angular/core';
import { Plan } from 'src/app/shared/models/plan.model';
import { MatDialog } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { AddSelectedDevice, AddSelectedPlan, RemoveSelectedCableDevice, RemoveSelectedCableDeviceId, RemoveSelectedCableLockin, RemoveSelectedChannel, RemoveSelectedDevice, RemoveSelectedPack, RemoveSelectedPlan, ResetCableIndexId, UpdateBillingSummary, UpdateBillingSummaryPayload, UpdateCableLite, UpdateNumberOfBoxes, UpdatePlanDetails } from 'src/app/shared/actions/gelato.actions';
import { TvModalPlanDetailComponent  } from 'src/app/shared/components/tv-modal-plan-detail/tv-modal-plan-detail.component';
import { PlanDetail } from 'src/app/shared/models/planDetail.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable, Subscription } from 'rxjs';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Service } from 'src/app/shared/models/service.model';
import { environment } from 'src/environments/environment';
import { Device } from 'src/app/shared/models/device.model';
import { Pack } from 'src/app/shared/models/pack.model';
import { Channel } from 'src/app/shared/models/channel.model';
import { google } from '@agm/core/services/google-maps-types';
@Component({
  selector: 'app-tv-plan-box',
  templateUrl: './tv-plan-box.component.html',
  styleUrls: ['./tv-plan-box.component.css']
})
export class TvPlanBoxComponent implements OnInit {
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>;
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>
  selectedPlans:[Plan]
  @Input() plan: Plan;
  panelOpen: boolean = false;
  twoWordPlan: boolean = false;
  firstWord: String = "";
  subscription: Subscription;
  address:Address
  selectedDevices:Device[] =  []
  province_code:string = ""
  errorMessage = ""
  billingSummaryPayload:any
  selectedPacks:Pack[] = []
  selectedChannels:Channel[] = []
  removedPlan: number
  googleAddress: string = localStorage.getItem("google_address")
  selectedCableDevice:any[] = []
  selectedMesh:Device[] =[]
  constructor(public dialog: MatDialog,private store: Store,private gelatoService:GelatoService) { }

  ngOnInit() {
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google || this.googleAddress) {
          this.province_code = x.data["province"]["code"]
        }
      })
    })
   
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    
    })

    this.selectedPacks$.subscribe(x => {
      this.selectedPacks = x
    })

    this.selectedChannels$.subscribe(x => {
      this.selectedChannels = x
    })

    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCableDevice = x
      else this.selectedCableDevice = []
    })
    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMesh =x
    })

    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
  }
  openPlanDetail(id) {
    this.subscription = this.selectedCategory$.subscribe(x => {
      if(x){ 
        //UPDATE PLAN_DETAIL
        this.gelatoService.getPlanDetailsByID(id,this.province_code,x).subscribe(x => {
          this.store.dispatch(new UpdatePlanDetails(x))
          const dialogRef = this.dialog.open(TvModalPlanDetailComponent,{
            height: '800px',
            width: '660px',
          });
          dialogRef.afterClosed().subscribe(result => {
            this.subscription.unsubscribe()
          });
         })
      }
    })
    
    
  }

  editPlanName(planName){
    var split = planName.split(" ")
    if(split.length > 1){
      return split[1]
    }
    else{
      return planName
    }
  }

  getFlag(plan:Plan){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.id === plan.id)
    }else{
      return false
    }
    
  }
  
  getThis(plan:Plan) {
    this.store.dispatch(new UpdateCableLite(false))
    this.panelOpen = true
    if(this.selectedPlans){
      if(this.selectedPlans.some(x =>x.service_id === environment.SERVICE_TV)){
        this.selectedPlans.filter(x => x.service_id === environment.SERVICE_TV).map(y => {
          this.removedPlan = y.id
          this.store.dispatch(new RemoveSelectedPlan(y.id))
          this.store.dispatch(new AddSelectedPlan(plan))
          })
          
        }else{
          this.store.dispatch(new AddSelectedPlan(plan))
        }
          
        this.selectedDevices.filter(device => device.device_service_type == environment.SERVICE_TV).map(x => {
          this.store.dispatch(new RemoveSelectedDevice(x.id, environment.SERVICE_TV))
        })
        
        if(plan.default_cable_box_id != 0){
          const copy = {...plan.default_cable_box, device_service_type : environment.SERVICE_TV}
          this.store.dispatch(new AddSelectedDevice(copy))
        }
        this.resetCableAddOns()
        this.billingSummary()
      }
      else{
        if(plan.service_id === environment.SERVICE_TV){
          if(plan.default_cable_box_id != 0){
            const copy = {...plan.default_cable_box, device_service_type : environment.SERVICE_TV}
            this.store.dispatch(new AddSelectedDevice(copy))
          }
          this.store.dispatch(new AddSelectedPlan(plan))
      }else{
        this.store.dispatch(new AddSelectedPlan(plan))
        plan.comes_with.map(x => {
          const copy = {...x, device_service_type: environment.SERVICE_TV}
          this.store.dispatch(new AddSelectedDevice(copy))
        })
      }
      this.billingSummary()
    }
    
  }

  billingSummary(){
      //CONSTRUCT SELECTED PLANS
      const selected = this.selectedPlans.map(plan => {
         return {
          "plan_id":plan.id,
          device_id: this.selectedDevices.filter(device => device.device_service_type === plan.service_id).map(x => x.id)
        }
      })
      const devices = this.selectedMesh.map(x => {
        return {
  
          "device_id": x.id,
          "lockin_period_id": x.lockin_period_id || x.lockin_period['id']
        }
      })
      const defaultAddon = {
          "devices":devices,
          "channel_pack_id":[],
          "channel_id":[]
      }

      if(this.removedPlan == 56){
        if(this.selectedPacks){
          this.selectedPacks.forEach(pack => {
            this.store.dispatch(new RemoveSelectedPack(pack.id))
          })
        }
        if(this.selectedChannels){
          this.selectedChannels.forEach(channel => {
            this.store.dispatch(new RemoveSelectedChannel(channel.id))
          })
        }
        this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected, "addons":defaultAddon }
      }
      else{
        this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected, "addons":defaultAddon }
      }
      
      //UPDATE BILLING SUMMARY PAYLOAD
      this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
      //UPDATE BILLING SUMMARY
      this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
        
        if(x.status_code === 200){
          this.errorMessage = ""
          this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        }else{
          this.errorMessage = x.message
        }
      })    
  
  }

  resetCableAddOns(){
    this.selectedCableDevice.forEach(device => {
      this.store.dispatch(new RemoveSelectedCableDevice(device.id))
      this.store.dispatch(new RemoveSelectedCableDeviceId(device.id))
      this.store.dispatch( new RemoveSelectedCableLockin(device.name))
    })
    if(this.selectedPacks){
      this.selectedPacks.forEach(pack => {
        this.store.dispatch(new RemoveSelectedPack(pack.id))
      })
    }
    if(this.selectedChannels){
      this.selectedChannels.forEach(channel => {
        this.store.dispatch(new RemoveSelectedChannel(channel.id))
      })
    }
    this.store.dispatch(new UpdateNumberOfBoxes(null))
    this.store.dispatch( new ResetCableIndexId())
  }

}
