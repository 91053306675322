import { environment } from 'src/environments/environment';
import { UpdateDevices, UpdateHTTPRequestFlag, UpdateNumberOfBoxes } from './../../actions/gelato.actions';
import { Component, OnInit,Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Devices } from '../../models/devices.model';
import { ResponseObject } from '../../models/response-object.model';
import { GelatoState } from '../../state/gelato.state';
import { Plan } from '../../models/plan.model';
import { Router } from '@angular/router';
import { Device } from '../../models/device.model';
import { ResetCableIndexId, AddSelectedCableDeviceIndex, UpdateDeviceDetail } from '../../actions/gelato.actions';
import { MatDialog } from '@angular/material/dialog';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { ModemViewDetailsModalComponent } from '../modem-view-details-modal/modem-view-details-modal.component';
@Component({
  selector: 'app-cable-addon',
  templateUrl: './cable-addon.component.html',
  styleUrls: ['./cable-addon.component.css']
})
export class CableAddonComponent implements OnInit {
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedService) selectedServices$: Observable<number>;
  @Select(GelatoState.getSelectedDevices) selectedDevice$: Observable<[Device]>;
  @Select(GelatoState.getSelectedDeviceIndex) cableIndex$: Observable<any>
  @Select(GelatoState.getSelectedCableDeviceId) cableCountId$: Observable<any>
  @Select(GelatoState.getSelectedCable) selectedCable$:Observable<[Device]>
  @Select(GelatoState.getDevices) devices$: Observable<ResponseObject<Devices>>;
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>;
  @Select(GelatoState.getNumberOfBoxes) numberOfBoxes$: Observable<number>;
  meshFlag:boolean = false
  selectedPlans:Plan[] = []
  @Input() devices: ResponseObject<Devices>;
  title:string = "Choose your Cable Box"
  count:any
  cableIndex:number[];
  disabledFlag:string
  length:number
  cableCountId:number[];
  selectValue:string = ""
  box:number = 0
  requestFlag:boolean
  selectedService:number
  device:Device[]
  changeFlag:boolean = false
  createYourOwnFlag:boolean = false
  numberOfBoxes:number
  isSelected:boolean = true
  isCableLite:boolean = false
  deviceList: Device[]
  constructor(public dialog: MatDialog,private store: Store,private router: Router,private gelatoService:GelatoService) { }
  
  ngOnInit() {
    this.createYourOwnFlag$.subscribe(x => {
      if(x)this.createYourOwnFlag = x
    })
    this.cableIndex$.subscribe(x => {
      this.cableIndex = x
      this.count = x
    
      
    })
    this.cableCountId$.subscribe(x => {
      this.cableCountId = x
      if(x){
        if(x.length > 0) this.meshFlag = true
      }
    
    })
    this.selectedPlans$.subscribe(x => {
       if(x){
         this.selectedPlans = x
         if(x.length > 0) this.title = "Upgrade your Cable Box"
         if(this.selectedPlans.some(x=>x.id === 56)) this.isCableLite = true
         x.filter(plan => plan.service_id == environment.SERVICE_TV).map(tvPlan => {
           this.deviceList = tvPlan.comes_with
         })
       }
     })
    this.selectedServices$.subscribe(x => {
      if(x) this.selectedService = x
      
    })
    this.selectedDevice$.subscribe(x => {
      if(x){
        if(x.length > 0){
          if(this.selectedService == environment.SERVICE_BUNDLE){
            this.device = x.filter(x => x.device_service_type === environment.SERVICE_BUNDLE && x.device_type_id == environment.SERVICE_TV || x.is_2in1 == true)
            this.device.length > 1 ? this.device = this.device.filter(x => x.is_2in1 == false) : null
          } else{
            this.device = x.filter(x => x.device_service_type == environment.SERVICE_TV)
          }
        }
        
        //CHECK IF TV PLAN DEVICE CAN BE UPGRADED
        if(this.createYourOwnFlag){
          this.changeFlag = true  
        }else{
          this.changeFlag = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_TV).some(x => x.is_upgradeable === 1) && this.deviceList.length > 1
        }
        
      }
    })

    setTimeout(() => {
      this.numberOfBoxes$.subscribe(x => {
        if(x !== null){
          this.numberOfBoxes = x
          this.selectValue = this.numberOfBoxes > 1 ? `${this.numberOfBoxes} Boxes`: `${this.numberOfBoxes} Box`
        }else{
          this.numberOfBoxes = null
        } 
      })
      }, 200);
    if(!this.device){
      this.router.navigate(['/tv-plan/select-device'])
    }
  }

  toggleMesh(){
    this.meshFlag = !this.meshFlag
  }

  

  selectPlan(x){
    this.router.navigate([x])
  }


  

  onOptionsSelected(value:any){
      this.store.dispatch(new UpdateNumberOfBoxes(+value))
  //  Adding Cable Boxes
      if(value == 1 ){
        const counting = [1]
        this.count = counting
        this.cableIndex = counting
        const zero = 0
        this.selectValue = "1 Box"
        this.store.dispatch(new ResetCableIndexId())
        this.store.dispatch(new AddSelectedCableDeviceIndex(zero))
         
      }
      if(value == 2){
        const counting = [1,2]
        this.count = counting
        const zero = 0
        const one = 1
        this.selectValue = "2 Boxes"
        this.store.dispatch(new ResetCableIndexId())
        this.store.dispatch(new AddSelectedCableDeviceIndex(zero)) 
        this.store.dispatch(new AddSelectedCableDeviceIndex(one)) 
      }
      if(value == 3){
        const counting = [1,2,3]
        this.count = counting
        const zero = 0
        const one = 1
        const two = 2
        this.selectValue = "3 Boxes"
        this.store.dispatch(new ResetCableIndexId())
        this.store.dispatch(new AddSelectedCableDeviceIndex(zero)) 
        this.store.dispatch(new AddSelectedCableDeviceIndex(one)) 
        this.store.dispatch(new AddSelectedCableDeviceIndex(two)) 
      }if(value == 4){
        const counting = [1,2,3,4]
        this.count = counting
        const zero = 0
        const one = 1
        const two = 2
        const three = 3
        this.selectValue = "4 Boxes"
        this.store.dispatch(new ResetCableIndexId())
        this.store.dispatch(new AddSelectedCableDeviceIndex(zero)) 
        this.store.dispatch(new AddSelectedCableDeviceIndex(one)) 
        this.store.dispatch(new AddSelectedCableDeviceIndex(two)) 
        this.store.dispatch(new AddSelectedCableDeviceIndex(three))  
      }
     
    
    
   
}

goTo(x){
  this.router.navigate([x])
}

displayFlag(device:Device) {
  if(this.selectedPlans){
    
    if(this.selectedPlans.length > 0){
      const x = this.selectedPlans.filter(x=>x.service_id === environment.SERVICE_TV || x.service_id === environment.SERVICE_BUNDLE)
        if(x.length > 0){
          return x.map(x => x.comes_with)[0].some(x => x.id === device.id)
        }
    } 
  }else{
    return false
  }

  
}




openModal(id){
  //UPDATE PLAN_DETAIL
  this.gelatoService.getDeviceDetail(`${id}`).subscribe(x => {
    this.store.dispatch(new UpdateDeviceDetail(x))
    const dialogRef = this.dialog.open(ModemViewDetailsModalComponent,{
      height: '95%',
      width: '660px',
    });

    dialogRef.afterClosed().subscribe(result => {
    ;
    });
  })
}
  nextClick(){
    if(this.selectedService === environment.SERVICE_TV){
      if(this.selectedPlans.some(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_BUNDLE)){
        //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
        if(this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_BUNDLE).some(x => x.is_upgradeable === 1)){
          //UPDATE DEVICES, BECAUSE UPDATING OF DEVICES IS IN SELECT DEVICE.
          this.store.dispatch(new UpdateHTTPRequestFlag(true))
          let planId = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET).map(x => x.id)[0]
          let cablePlanId = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_TV).map(x => x.id)[0]
          this.gelatoService.getDevices(planId, planId, cablePlanId, undefined).subscribe(x => {
            this.store.dispatch(new UpdateDevices(x))
            this.store.dispatch(new UpdateHTTPRequestFlag(false))
            x.data.modem_devices.length > 1 ? this.router.navigate(['/internet-plan/select-device']) : this.router.navigate(['/internet-plan/select-addon'])
          })
        }else{
          this.router.navigate(['/internet-plan/select-addon'])
        }
      }else{
        this.router.navigate(['/tv-plan/plan-summary'])
      }
      
    }else{
      this.router.navigate(['/tv-plan/plan-summary'])
    }
    
  }
  back(){
    window.history.back()
  }
  
}


