import { AddSelectedDevice, AddSelectedMeshDevice, AddSelectedMeshLockin, RemoveSelectedCableDevice, RemoveSelectedCableDeviceId, RemoveSelectedCableLockin, RemoveSelectedDevice, ResetCableIndexId, UpdateBillingSummary, UpdateBillingSummaryPayload, UpdateDeviceDetail, UpdateNumberOfBoxes } from './../../actions/gelato.actions';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store,Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Observable } from 'rxjs';
import { GelatoState } from '../../state/gelato.state';
import { CableViewModalDetailsComponent } from '../cable-view-modal-details/cable-view-modal-details.component';
import { Device } from '../../models/device.model';
import { CableBoxDevice } from '../../models/cable-box-device.model';
import { environment } from 'src/environments/environment';
import { AvailableLockinPeriod } from '../../models/available-lockin-period.model';
import { DeviceDetail } from '../../models/device-detail.model';
import { Plan } from '../../models/plan.model';
import { ResponseObject } from '../../models/response-object.model';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cable-box-device',
  templateUrl: './cable-box-device.component.html',
  styleUrls: ['./cable-box-device.component.css']
})
export class CableBoxDeviceComponent implements OnInit  {
  
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>
  @Input() modemDevice: CableBoxDevice;
  
  selectedDevice:[Device]
  panelOpen: boolean = false;

  
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>;
  @Select(GelatoState.getSelectedLockin) selectedLockin$: Observable<AvailableLockinPeriod>
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>
  @Select(GelatoState.getDeviceDetail) deviceDetails$: Observable<ResponseObject<DeviceDetail>>
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>
  @Select(GelatoState.getSelectedPlans) selectedPlan$: Observable<[Plan]>;
  @Select(GelatoState.getEditMode) edit$: Observable<boolean>;
  @Select(GelatoState.getApplyButtonFlag) applyButtonFlag$: Observable<boolean>;
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>;
  @Select(GelatoState.getSelectedCableDeviceId) CableId$: Observable<[any]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;

  selectedPlan:Plan[] = []
  selectedDevices:Device[] =  []
  selectedCableLockin:[AvailableLockinPeriod]
  selectedMeshLockin:[AvailableLockinPeriod]
  selectedCableDevice:any[] = []
  selectedMeshDevice:any[] = []
  errorMessage = ""
  selectedLockin:AvailableLockinPeriod
  selectedMesh:[Device]
  createYourOwnFlag:boolean = false
  selectedService: number
  billingSummaryPayload:any
  selectedPlans:Plan[] = []
  constructor(public dialog: MatDialog,private store: Store,private gelatoService:GelatoService,private router: Router) { }

  ngOnInit() {
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevice = x
    })
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
    this.selectedLockin$.subscribe(x => {
      this.selectedLockin = x
    })

    this.selectedMesh$.subscribe(x => {
      if (x) this.selectedMesh = x
    })
    this.createYourOwnFlag$.subscribe(x =>{
      if(x) this.createYourOwnFlag = x
    })
    this.selectedPlan$.subscribe(x => {
      if(x) this.selectedPlan = x
     
    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    
    })
    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCableDevice = x
      else this.selectedCableDevice = []
    })
  
    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMeshDevice = x
      else this.selectedMeshDevice = []
    })
   
  
    this.selectedPlans$.subscribe(x => {
      if(x) this.selectedPlans = x
    })
  }

  openModal(id){
    //UPDATE PLAN_DETAIL
    let planId
    let fiberPlanId
    this.selectedPlan.forEach(x => x.service_id == environment.SERVICE_TV ? planId = x.id : null)
    this.selectedPlan.forEach(x => x.service_id == environment.SERVICE_INTERNET ? fiberPlanId = x.id : null)
    this.gelatoService.getDeviceDetail(id,null,fiberPlanId,planId).subscribe(x => {
      this.store.dispatch(new UpdateDeviceDetail(x))
      const dialogRef = this.dialog.open(CableViewModalDetailsComponent,{
        height: '95%',
        width: '660px',
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    })
  }
  getFlag(modem){
    if(this.selectedDevice){
      return this.selectedDevice.some(x => x.id === modem.id)
    }else{
      return false
    }
  }

  getBox(id){
    // this.panelOpen = true
    this.gelatoService.getDeviceDetail(`${id}`).subscribe(x => {
      this.store.dispatch(new UpdateDeviceDetail(x))
      this.getThis(x.data['device'])
    })
  }

  getThis(device:Device){
    device = {...device, device_service_type : environment.SERVICE_TV}
   this.resetCableAddOns();
    //ADD MESH DEVICE
    if(device.device_type_id === environment.DEVICE_MESH){
      this.store.dispatch(new AddSelectedMeshDevice(device))  
      this.store.dispatch(new AddSelectedMeshLockin(this.selectedLockin))  
      const selected = []
      const selectedMesh = this.selectedMeshDevice.map(x => {
        selected.push({
          "device_id":x.id,
          "lockin_period_id":x.lockin_period_id || x.lockin_period['id']
        })
       return{
        device_id:x.id,
        "lockin_period_id":x.lockin_period_id || x.lockin_period['id']
       }
        
      })
      const selectedCable = this.selectedCableDevice.map(x => {
        selected.push({
          "device_id":x.id,
          "lockin_period_id":x.lockin_period_id 
        })
        return {
          
            device_id:x.id,
            "lockin_period_id":x.lockin_period_id 
        }
      })
      let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.devices",selected)
      //UPDATE BILLING SUMMARY PAYLOAD
      this.store.dispatch(new UpdateBillingSummaryPayload(payload))
      //UPDATE BILLING SUMMARY
      this.gelatoService.billingSummary(payload).subscribe(x => {
        if(x.status_code === 200){
          this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        }else{ 
        }
      })  
      //END UPDATE MESH BILLING SUMMARY PAYLOAD
      //CREATEYOUROWN PLAN 
    }else if(this.createYourOwnFlag){
      if(this.selectedDevice){
        // CHANGE CABLE TV DEVICE
        if(this.selectedDevice.length > 0 && this.selectedDevice.some(x=>x.device_type_id === device.device_type_id)){
          const selected = []
          this.selectedDevice.filter(x =>x.device_type_id === device.device_type_id).map((y)=>{
            this.store.dispatch(new RemoveSelectedDevice(y.id))
            this.store.dispatch(new AddSelectedDevice(device))
          

            selected.push({
              "plan_id":0,
              device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id)
            })
            
              this.selectedPlan.map(x=>{
                selected.push({
                  "plan_id":x.id,
                  device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id)
                })
              })
              
             
            
          })
          this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
          //UPDATE BILLING SUMMARY PAYLOAD
          this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
          //UPDATE BILLING SUMMARY
          
          
          this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
            if(x.status_code === 200){
              this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
            }else{
            }
          })
        }
        
        else{
          this.store.dispatch(new AddSelectedDevice(device))
      
          const selected = [];
         this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map((device,i) => {
      
            selected.push({
              "plan_id":0,
              device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id)
            })
          })
    
          this.selectedPlan.map(x => {
            selected.push({
              "plan_id":x.id,
              device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id)
            })
          })
         
          this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
          //UPDATE BILLING SUMMARY PAYLOAD
          this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
          //UPDATE BILLING SUMMARY
          this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
            if(x.status_code === 200){
              this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
            }else{
            }
          })  
        }
      }else{
        this.store.dispatch(new AddSelectedDevice(device))
      
        const selected = [];
       this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map((device,i) => {
    
          selected.push({
            "plan_id":0,
            device_id:[device.id]
          })
        })
  
        this.selectedPlan.map(x => {
          selected.push({
            "plan_id":x.id,
            device_id:x.comes_with.map(x=>x.id)
          })
        })
       
        this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
        //UPDATE BILLING SUMMARY PAYLOAD
        this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
        //UPDATE BILLING SUMMARY
        this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
          if(x.status_code === 200){
            this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
          }else{
          }
        })  
      }
        
            
         
      // END CREATEYOUROWN PLAN
    }else{
      if(this.selectedDevice){
        if(this.selectedDevices.some(x=>x.device_type_id===device.device_type_id)){
        
          this.selectedDevices.filter(x=>x.device_type_id === device.device_type_id).map(x=>{
            this.store.dispatch(new RemoveSelectedDevice(x.id))
          })
          this.store.dispatch(new AddSelectedDevice(device))
          

          //IF BUNDLE
          if(this.selectedService === environment.SERVICE_BUNDLE){
            //START UPDATE BUNDLE BILLING SUMMARY PAYLOAD
            const bundle = this.selectedPlan.filter(x => x.service_id === environment.SERVICE_BUNDLE).map(plan => {
              return{
                plan_id:plan.id,
                device_id:this.selectedDevices.map(x => x.id)
              }
            })
            
            let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",bundle)
            //UPDATE BILLING SUMMARY PAYLOAD
            this.store.dispatch(new UpdateBillingSummaryPayload(payload))
            //UPDATE BILLING SUMMARY
            this.gelatoService.billingSummary(payload).subscribe(x => {
              if(x.status_code === 200){
                this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
              }else{ 
              }
            })  
            //END UPDATE BUNDLE BILLING SUMMARY PAYLOAD
          }else{
            //INTERNET AND TV PLANS
            //START UPDATE INTERNET OR TV BILLING SUMMARY PAYLOAD
           
            const x = this.selectedPlan.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_TV).map(plan => {
              return{
                plan_id:plan.id,
                //IF INTERNET GET SELECTED MODEM AND MESH DEVICES
                //IF TV GET BOX DEVICES
                device_id:plan.service_id === environment.SERVICE_INTERNET? this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id) : this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id) 
               
              }
            })
            
            let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",x)
            //UPDATE BILLING SUMMARY PAYLOAD
            this.store.dispatch(new UpdateBillingSummaryPayload(payload))
            //UPDATE BILLING SUMMARY
            this.gelatoService.billingSummary(payload).subscribe(x => {
              if(x.status_code === 200){
                this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
              }else{ 
              }
            })
          
            //END UPDATE INTERNET OR TV BILLING SUMMARY PAYLOAD

          }
        }else{
            //START ADD DEVICE IF INTERNET PLAN SELECTED
          this.store.dispatch(new AddSelectedDevice(device))
            const x = this.selectedPlan.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_TV).map(plan => {
              return{
                plan_id:plan.id,
                //IF INTERNET GET SELECTED MODEM AND MESH DEVICES
                //IF TV GET BOX DEVICES
                device_id:plan.service_id === environment.SERVICE_INTERNET? this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id) : this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id) 
               
              }
            })
            
            let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",x)
            //UPDATE BILLING SUMMARY PAYLOAD
            this.store.dispatch(new UpdateBillingSummaryPayload(payload))
            //UPDATE BILLING SUMMARY
            this.gelatoService.billingSummary(payload).subscribe(x => {
              if(x.status_code === 200){
                this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
              }else{ 
              }
            })
        }
      }else{
        // ADD DEVICE
        const selected = []
          this.store.dispatch(new AddSelectedDevice(device))
            this.selectedPlan.map(x=>{
              selected.push({
                "plan_id":x.id,
                device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE || x.device_type_id === environment.DEVICE_MODEM).map(x => x.id)
              })
            })
        this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
        //UPDATE BILLING SUMMARY PAYLOAD
        this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
        //UPDATE BILLING SUMMARY
        
        
        this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
          if(x.status_code === 200){
            this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
          }else{
          }
        })
        
      }
    }
   
  }

  resetCableAddOns(){
    this.selectedCableDevice.forEach(device => {
      this.store.dispatch(new RemoveSelectedCableDevice(device.id))
      this.store.dispatch(new RemoveSelectedCableDeviceId(device.id))
      this.store.dispatch( new RemoveSelectedCableLockin(device.name))
    })
    this.store.dispatch(new UpdateNumberOfBoxes(null))
    this.store.dispatch( new ResetCableIndexId())

    this.removeBillingSummaryCableAddons()
  }
  
  removeBillingSummaryCableAddons(){
    //CONSTRUCT SELECTED CABLE DEVICE
  const selected = []
  this.selectedCableDevice.map(x => {
    selected.push({
    
      "device_id":x.id,
      "lockin_period_id":x.lockin_period_id 
    })
  })
  this.selectedMeshDevice.map(x => {
    selected.push({
    
      "device_id":x.id,
      "lockin_period_id":x.lockin_period_id || x.lockin_period['id']
    })
  })
  let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.devices",selected)
  //UPDATE BILLING SUMMARY PAYLOAD
  this.store.dispatch(new UpdateBillingSummaryPayload(payload))
  //UPDATE BILLING SUMMARY
  this.gelatoService.billingSummary(payload).subscribe(x => {
    if(x.status_code === 200){
      this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
    }else{
    }
  })    

  }
}
