import { UpdateSecondaryIDs, UpdateValidIDs } from './../../actions/gelato.actions';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Select,Store } from '@ngxs/store';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs';
import { Address } from '../../models/address.model';
import { MatDialog } from '@angular/material/dialog';
import { IdModalComponent } from '../id-modal/id-modal.component';
import { Street } from '../../models/street.model';
import { ResponseObject } from '../../models/response-object.model';
import { Province } from '../../models/province.model';
import { City } from '../../models/city.model';
import { Building } from '../../models/building.model';
import { Barangay } from '../../models/barangay.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateHTTPRequestFlag, UpdateProvinces, AddConfirmationData, UpdateVisitedPages } from '../../actions/gelato.actions';
import { Pack } from '../../models/pack.model';
import { Plan } from '../../models/plan.model';
import { Channel } from '../../models/channel.model';
import { VoucherCode } from '../../models/voucher-code';
import { Device } from '../../models/device.model';
import { ApplicationDetails } from '../../models/application-details.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { browserRefresh } from 'src/app/app.component';
import { Service } from '../../models/service.model';
import { BillingSummary } from '../../models/billing-summary';
import { Devices } from '../../models/devices.model';
import { QrCode } from '../../models/qr-code.model';
import { HeaderText } from '../../models/header-text.model';
class ImageSnippet {
  constructor(public src: string, public file: File) {}
}
@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.css']
})
export class ApplicationFormComponent implements OnInit {

  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getSelectedPlans) selectedPlan$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getVoucherCode) discountCode$: Observable<ResponseObject<VoucherCode>>;
  @Select(GelatoState.getEditMode) edit$: Observable<boolean>;
  @Select(GelatoState.getApplyButtonFlag) applyButtonFlag$: Observable<boolean>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>;
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>;
  @Select(GelatoState.getSelectedCableDeviceId) CableId$: Observable<[any]>;
  @Select(GelatoState.getProvinces) provinces$: Observable<[Province]>;
  @Select(GelatoState.getCities) cities$: Observable<[City]>;
  @Select(GelatoState.getBuildings) buildings$: Observable<[Building]>;
  @Select(GelatoState.getBarangays) barangays$: Observable<[Barangay]>;
  @Select(GelatoState.getStreets) streets$: Observable<[Street]>;
  @Select(GelatoState.getVisitedPages) visitedPages$: Observable<[any]>;
  @Select(GelatoState.getHTTPRequestFlag) httpReqeuestFlag$: Observable<boolean>;
  @Select(GelatoState.getValidIDs) validIDs$: Observable<any>;
  @Select(GelatoState.getSecondaryIDs) secondaryIDs$: Observable<any>;
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getBillingSummary) billingSummary$: Observable<[BillingSummary]>
  @Select(GelatoState.getDevices) devices$: Observable<ResponseObject<Devices>>;
  @Select(GelatoState.getCableLite) cableLite$: Observable<boolean>;
  @Select(GelatoState.getQrCodeData) qrCode$: Observable<QrCode>;
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  flag:boolean
  selectedPlan:Plan[] = []
  selectedPacks:Pack[] = []
  selectedDevices:Device[] =  []
  selectedChannels:Channel[] = []
  selectedCableDevice:any[] = []
  selectedMeshDevice:any[] = []
  applicationForm: FormGroup
  submitted: boolean = false
  address:Address
  addressForm: FormGroup
  applicationdetails:[ApplicationDetails]
  discount:string =""
  fileName:string = 'Upload valid ID'
  SERVER_URL="http://18.141.127.146/api/v1/submitApplication"

  errorMessage = ""

  // Photo Validation
  photoName: any;
  photoContent : string
  fileExtension: any;
  fileExtensionError: boolean = false;
  fileExtensionMessage: any;
  selectedFile: ImageSnippet;
  // Button Validation
  buttonMessage:any;


  //START GOOGLE CAPTCHA
  public siteKey = environment.GOOGLE_RECAPTCHA_KEY;
  public badge = 'inline';
  public type = 'image';
  public theme = 'light';
  public recaptcha: any = null;
  public size = 'Normal';
  public lang = 'en';
  gRecaptcha:String;


  createYourOwnFlag:boolean = false
  @ViewChild('captchaElem', {}) captchaElem: InvisibleReCaptchaComponent;


  DOBErrorStyle:boolean = false;
  DOBHoverText:string = "";
  province_code:string = ""
  province_name:string = ""
  city_code:string = ""
  city_name:string = ""
  barangay_code:string = ""
  barangay_name:string = ""
  street_code:string = ""
  street_name:string = ""
  birthdate:string = ""
  unit_no:string = ""
  building:string = ""
  subd:string = ""
  zip_code:string = ""
  buildingId:number = 0
  BillingSummary:BillingSummary[]
  cableDevice:Device[]=[]
  MeshDevice:Device[]=[]

  cableLite:boolean

  qrCode: QrCode

  constructor(private http: HttpClient,private router:Router, private formBuilder:FormBuilder,private dialog: MatDialog,private store: Store,private gelatoService:GelatoService) {

    this.visitedPages$.subscribe(x => {
      if(!x.includes('form')){
        this.store.dispatch(new UpdateVisitedPages(['available','select-plan','summary','form']))
     }
   })
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    gelatoService.getProvinces().subscribe(x => {
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      this.store.dispatch(new UpdateProvinces(x.data["provinces"]))
    })
    this.billingSummary$.subscribe(x=>{
      if(x) this.BillingSummary = x
    })
    this.selectedPlan$.subscribe(x => {
      if(x) this.selectedPlan = x

    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x

    })
    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCableDevice = x
      else this.selectedCableDevice = []
    })

    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMeshDevice = x
      else this.selectedMeshDevice = []
    })

    this.selectedPacks$.subscribe(x => {
      if(x) this.selectedPacks = x
      else this.selectedPacks = []
    })
    this.selectedChannels$.subscribe(x => {
      if(x) this.selectedChannels = x
     else this.selectedChannels = []
    })
    this.discountCode$.subscribe(x => {
      if(x) this.discount = x.data['voucher_code'].code
      else this.discount = ""
    })
    this.httpReqeuestFlag$.subscribe(x =>{
      this.flag = x
    })
    gelatoService.getValidID().subscribe(x => {
      if(x.status_code == 200){
        this.store.dispatch(new UpdateValidIDs(x.data['primary_ids']))
        this.store.dispatch(new UpdateSecondaryIDs(x.data['secondary_ids']))
      }
      else{
        console.error(x.message)
      }
    })
    this.devices$.subscribe(x=>{
      if(x){
        this.cableDevice = x.data['cable_box_devices']
        this.MeshDevice = x.data['mesh_devices']
      }
    })
    this.cableLite$.subscribe(x => {
      this.cableLite = x
    })

    this.qrCode$.subscribe(x => {
      this.qrCode = x
    })

    this.headerText$.subscribe(x => {
      this.DOBHoverText = x.hover_text_screen.birthdate_hover_text;
    })
  }

  ngOnInit() {
    this.createYourOwnFlag$.subscribe(x => {
      if(x) this.createYourOwnFlag = x
    })
    if(browserRefresh){
      this.router.navigate(['/onboarding/available-location'])
    }

    this.services$.subscribe(x => {
      if(x){
        this.province_code   = x.data["province"]["code"]
        this.province_name   = x.data["province"]["name"]
        this.city_code       = x.data["city"]["code"]
        this.city_name       = x.data["city"]["name"]
        this.barangay_code   = x.data["barangay"]["code"]
        this.barangay_name   = x.data["barangay"]["name"]
        this.street_code     = x.data["street"]["code"]
        this.street_name     = x.data["street"]["name"]
      }
    })

    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
      this.province_name = this.address.province.name
      this.city_code = this.address.city.city_code
      this.city_name = this.address.city.name
      this.barangay_code = this.address.barangay.brgy_code
      this.barangay_name = this.address.barangay.name
      this.street_code = this.address.street.street_code
      this.street_name = this.address.street.name
      this.unit_no = this.address.unit_no
      if(!this.address.building.building_name){
        this.building = ''
      }else{
        this.building = this.address.building.building_name
        this.buildingId = this.address.building.id
      }
      this.subd = this.address.subd
      this.zip_code = this.address.zip_code
    }

    this.applicationForm = this.formBuilder.group({
      firstname: ['',Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9\\-\\s\\ñ\\Ñ']+$")])],
      middlename: ['',Validators.compose([Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9\\-\\s\\ñ\\Ñ']+$")])],
      lastname: ['',Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9\\-\\s\\ñ\\Ñ']+$")])],
      birthdate: ['',Validators.compose([Validators.pattern('^(1[0-2]|0[1-9])/(3[01]|[12][0-9]|0[1-9])/[0-9]{4}$')])],
      email_address: ['',Validators.compose([Validators.required, Validators.email,,Validators.pattern('^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\\.[a-z]{2,4}$')])],
      mobile_no: ['',Validators.compose([Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(12), Validators.minLength(10)])],
      house_unit_no: [this.unit_no,Validators.required],
      street_name: [this.street_name,Validators.required],
      subdivision_building_village: [this.subd],
      province_code: [this.province_name,Validators.required],
      city_code: [this.city_name,Validators.required],
      building: [this.building],
      barangay_code: [this.barangay_name,Validators.required],
      zipcode: [this.zip_code,Validators.required],
      attachment: [''],
      terms: [false,Validators.requiredTrue],
      privacy: [false,Validators.requiredTrue],
      validID: [''],
      recaptcha: ['',Validators.required]
    })
  }

  handleReset(){

  }
  handleReady(){
    this.captchaElem.execute();
  }
  handleLoad(){}
  handleExpire(){}
  handleSuccess(e){
  //  Success Google Captcha
  this.gRecaptcha = e
  }

  checkValue(){

  }

  dobValidator($event: any) {
    if(!/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      const charCode = ($event.which) ? $event.which : $event.keyCode;

      if (charCode > 31 && !(charCode <= 40 && charCode >= 37) && (charCode < 48 || charCode > 57)
      && !(charCode <= 105 && charCode >= 96) || this.birthdate.length > 10) {
        this.birthdate  = this.birthdate.substring(0, this.birthdate.length - 1);
      }

      if ((this.birthdate.toString().length == 2 || this.birthdate.toString().length == 5)
      && (charCode != 191) && !(charCode <= 40 && charCode >= 37)
      && this.birthdate.charAt(this.birthdate.toString().length-1) != "/" && charCode != 8) {
        this.birthdate = $event.target.value + "/";
      }
    }
  }

  submitForm(){
    if(this.applicationForm.valid){
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.applicationForm.get('province_code').setValue(this.province_code)
      this.applicationForm.get('city_code').setValue(this.city_code)
      this.applicationForm.get('barangay_code').setValue(this.barangay_code)
      this.applicationForm.get('street_name').setValue(this.street_name)


      let fname=this.applicationForm.get('firstname').value;
      let mname = this.applicationForm.get('middlename').value;
      let lname = this.applicationForm.get('lastname').value;
      let email = this.applicationForm.get('email_address').value;
      let no = this.applicationForm.get('mobile_no').value;
      let birthdate = this.applicationForm.get('birthdate').value;
      let unit = this.applicationForm.get('house_unit_no').value;
      let street = this.applicationForm.get('street_name').value;
      let subdivision = this.applicationForm.get('subdivision_building_village').value;
      let province = this.applicationForm.get('province_code').value;
      let city = this.applicationForm.get('city_code').value;
      let barangay = this.applicationForm.get('barangay_code').value;
      let zipcode = this.applicationForm.get('zipcode').value;
      let attach = this.applicationForm.get('attachment').value;
      let privacy = this.applicationForm.get('privacy').value;
      let terms = this.applicationForm.get('terms').value;
      const validID = this.applicationForm.get('validID').value;
      this.submitted = false

      let formData = new FormData();

      // DOB Validation
      if (new Date() < new Date(birthdate)) {
        this.applicationForm.controls['birthdate'].setErrors({incorrect: true, message: 'Invalid date'});
        this.DOBErrorStyle = true;
        this.submitted = true;
      }

      // IF CREATEYOUROWNPLAN
    if(this.createYourOwnFlag){
      // CHECK SELECTEDPLAN
      if(this.selectedPlan.length){
        this.selectedPlan.map((plan,i) => {
              formData.append(`selected_plans[${i}][plan_id]`, `${plan.id}`);
              this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MESH || x.device_type_id === environment.DEVICE_MODEM).map((device,index)=>{
                formData.append(`selected_plans[${i}][device_id][${index}]`, `${device.id}`);
              })
              formData.append(`selected_plans[1][plan_id]`, `${0}`);
              formData.append(`selected_plans[1][device_id]`, `${this.selectedDevices.filter(d => d.device_type_id === environment.DEVICE_CABLE).map(x => x.id)}`);
          })
       }else{
        // NO SELECTED PLAN
        formData.append(`selected_plans[0][plan_id]`, `${0}`);
        formData.append(`selected_plans[0][device_id]`, `${this.selectedDevices.filter(d => d.device_type_id === environment.DEVICE_CABLE).map(x => x.id)}`);
      }
    }else{
      // NOT CREATEYOUROWNPLAN
      this.selectedPlan.map((plan,i) => {


          if(plan.service_id === environment.SERVICE_INTERNET){
            formData.append(`selected_plans[${i}][plan_id]`, `${plan.id}`);
            this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MESH || x.device_type_id === environment.DEVICE_MODEM).map((device,index)=>{
              formData.append(`selected_plans[${i}][device_id][${index}]`, `${device.id}`);
            })
          }else if(plan.service_id === environment.SERVICE_TV){
            formData.append(`selected_plans[${i}][plan_id]`, `${plan.id}`);
            // Check if 2 in 1 device is not selected
            // else, do not pass device id
            if(this.cableLite){
              if(!this.selectedDevices.some(x => x.is_2in1)){
                formData.append(`selected_plans[${i}][device_id]`, `${this.selectedDevices.filter(d => d.device_type_id === environment.DEVICE_CABLE).map(x => x.id)}`);
              }
            }
            else{
              formData.append(`selected_plans[${i}][device_id]`, `${this.selectedDevices.filter(d => d.device_type_id === environment.DEVICE_CABLE).map(x => x.id)}`);
            }
          }else{
            formData.append(`selected_plans[${i}][plan_id]`, `${plan.id}`);
            this.selectedDevices.map((x,deviceIndex) => {
              formData.append(`selected_plans[${i}][device_id][${deviceIndex}]`, `${x.id}`);
            })
          }

        })

    }

    // CABLE AND MESH ADDONS FROM BILLINGSUMMARY
    this.BillingSummary.map(device => {
      device.addons.devices.map((x,i)=>{
        formData.append(`addons[devices][${i}][device_id]`, `${x.id}`);
      formData.append(`addons[devices][${i}][lockin_period_id]`, `${x.lockin_period_id}`);
      })
    })

    this.selectedChannels.map((channel,i) => {
      formData.append(`addons[channel_id][${i}]`, `${channel.id}`);

    })
    this.selectedPacks.map((pack,i) => {
      formData.append(`addons[channel_pack_id][${i}]`, `${pack.id}`);

    })

    formData.append('voucher_code', this.discount.toString());
    formData.append('application_details[firstname]', fname.toString());
    formData.append('application_details[middlename]', mname.toString());
    formData.append('application_details[lastname]', lname.toString());
    formData.append('application_details[email_address]', email.toString());
    formData.append('application_details[mobile_no]', no.toString());
    formData.append('application_details[birthdate]', birthdate.toString());
    formData.append('application_details[house_unit_no]', unit.toString());
    formData.append('application_details[street_name]', street.toString());
    // CHECK BUILDING AND SUBDIVISION
    if(this.buildingId !== 0){
      formData.append('application_details[building_id]', this.buildingId.toString());
    }
    if(this.subd){
    formData.append('application_details[subdivision_building_village]', subdivision.toString());
    }
    formData.append('application_details[province_code]', province.toString());
    formData.append('application_details[city_code]', city.toString());
    formData.append('application_details[barangay_code]', barangay.toString());
    formData.append('application_details[zipcode]', zipcode.toString());
    formData.append('application_details[attachment]', attach);
    formData.append('application_details[terms]', terms.toString());
    formData.append('application_details[privacy]',privacy.toString());
    formData.append('application_details[id_type]',validID.toString());
    formData.append('application_details[g-recaptcha-response]',this.gRecaptcha.toString());
    if(this.qrCode && this.qrCode.agent_name){
      formData.append('application_details[agent_name]',this.qrCode.agent_name)
      formData.append('application_details[agent_email]',this.qrCode.agent_email)
      formData.append('application_details[account_executive_name]',this.qrCode.account_executive_name)
      formData.append('application_details[account_executive_email]',this.qrCode.account_executive_email)
      formData.append('application_details[purpose]',this.qrCode.purpose)
    }

      this.gelatoService.submitApplication(formData).subscribe(
        x => {
        if(x.status_code === 200){
          this.store.dispatch(new AddConfirmationData(x.data))
          this.buttonMessage = ""
          localStorage.removeItem("qr-code")
          this.router.navigate(['/confirmation'])
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        }else{
          this.buttonMessage = x.message
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        }
      },
      err => {
        this.buttonMessage = err.error.message.errors?.join('\n');
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
        console.log(err);
      });
    }else{
      this.submitted = true
      this.buttonMessage = "*Please complete the details above"
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
    }
  }

  numberClick(){
    document.getElementById('number-wrap').classList.add('number-wrap');
  }

  numberBlur(){
    document.getElementsByClassName('number')[0]['value'].length < 1 ? document.getElementById('number-wrap').classList.remove('number-wrap') : null
  }

  viewSample(){
    const dialogRef = this.dialog.open(IdModalComponent,{
      height: '600px',
      width: '690px',
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  uploadId(event){

    if(window.navigator.onLine == false){
      this.fileExtensionMessage = "*File upload failed. Please try again"
      this.fileExtensionError = true;
      return false
    }

     const file: File = event.target.files[0];
     this.applicationForm.controls["attachment"].setValue(file)

      this.fileName = event.target.files[0].name
     this.photoName = file.name;

    if( event.target.files[0].size/1024/1024 < 4){
     const allowedExtensions =
        ["jpg","jpeg","pdf","JPG","JPEG","png"];
     this.fileExtension = this.photoName.split('.').pop();

     if(this.isInArray(allowedExtensions, this.fileExtension)) {
         this.fileExtensionError = false;
         this.fileExtensionMessage = ""

     } else {
         this.fileExtensionMessage = "*File format not supported"
         this.fileExtensionError = true;

     }

     if (file) {

         const reader  = new FileReader();
         reader.readAsDataURL(file)
         reader.onload=(event : any)=>{
            this.selectedFile = new ImageSnippet(event.target.result,file)
            this.photoContent = event.target.result
            this.applicationForm.get('attachment').setValue(file)
         }

     } else {

     }

    }else{
      this.fileExtensionMessage = "*File size limit exceeded"
      this.fileExtensionError = true;

    }
 }

  isInArray(array, word) {
     return array.indexOf(word.toLowerCase()) > -1;
  }


}



