import { AddSelectedDevice, AddSelectedMeshDevice, AddSelectedMeshLockin, RemoveSelectedDevice, UpdateBillingSummary, UpdateBillingSummaryPayload, UpdateSelectedAvailableLockinPeriod, RemoveSelectedMeshDevice, RemoveSelectedMeshLockin } from './../../actions/gelato.actions';
import { Device } from 'src/app/shared/models/device.model';
import { MeshDevice } from './../../models/mesh-device.model';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateDeviceDetail } from '../../actions/gelato.actions';
import { ModemViewDetailsModalComponent } from '../modem-view-details-modal/modem-view-details-modal.component';
import { AvailableLockinPeriod } from '../../models/available-lockin-period.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeviceDetail } from '../../models/device-detail.model';
import { Plan } from '../../models/plan.model';
import { ResponseObject } from '../../models/response-object.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as _ from 'lodash';

@Component({
  selector: 'app-mesh-device-box',
  templateUrl: './mesh-device-box.component.html',
  styleUrls: ['./mesh-device-box.component.css']
})
export class MeshDeviceBoxComponent implements OnInit {
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>;
  @Input() meshDevice: MeshDevice;
  @Input() maxLimit: number;
  selectedMesh:[Device]
  panelOpen: boolean = false;
  selected: AvailableLockinPeriod
  form: FormGroup
  hasError:boolean = false

  
  @Select(GelatoState.getSelectedLockin) selectedLockin$: Observable<AvailableLockinPeriod>
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>
  @Select(GelatoState.getDeviceDetail) deviceDetails$: Observable<ResponseObject<DeviceDetail>>
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>
  @Select(GelatoState.getSelectedPlans) selectedPlan$: Observable<[Plan]>;
  @Select(GelatoState.getEditMode) edit$: Observable<boolean>;
  @Select(GelatoState.getApplyButtonFlag) applyButtonFlag$: Observable<boolean>;
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>;
  @Select(GelatoState.getSelectedCableDeviceId) CableId$: Observable<[any]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>

  selectedPlan:Plan[] = []
  selectedDevices:Device[] =  []
  selectedCableLockin:[AvailableLockinPeriod]
  selectedMeshLockin:[AvailableLockinPeriod]
  selectedCableDevice:any[] = []
  selectedMeshDevice:any[] = []
  errorMessage = ""
  selectedLockin:AvailableLockinPeriod
  createYourOwnFlag:boolean = false
  selectedService: number
  billingSummaryPayload:any
  selectedPlans:Plan[] = []
  selectedDevice:[Device]

  constructor(private formBuilder: FormBuilder,public dialog: MatDialog,private store: Store,private gelatoService:GelatoService,private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      lockin: ['',Validators.required],
    })

    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMesh = x
    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevice = x
    })
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
    this.selectedLockin$.subscribe(x => {
      this.selectedLockin = x
    })

    this.createYourOwnFlag$.subscribe(x =>{
      if(x) this.createYourOwnFlag = x
    })
    this.selectedPlan$.subscribe(x => {
      if(x) this.selectedPlan = x
     
    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    
    })
    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCableDevice = x
      else this.selectedCableDevice = []
    })
  
    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMeshDevice = x
      else this.selectedMeshDevice = []
    })
   
    
    this.selectedPlans$.subscribe(x => {
      if(x) this.selectedPlans = x
    })
  }

  openModal(id){
    //UPDATE PLAN_DETAIL
    this.gelatoService.getDeviceDetail(`${id}`).subscribe(x => {
      this.store.dispatch(new UpdateDeviceDetail(x))
      const dialogRef = this.dialog.open(ModemViewDetailsModalComponent,{
        height: '95%',
        width: '660px',
      });
    })
    

    
  }

  getFlag(mesh){
    if(this.selectedMesh){
      return this.selectedMesh.some(x => x.id === mesh.id)
    }else{
      return false
    }
  }

  getThis(id){
    this.panelOpen = true
    this.gelatoService.getDeviceDetail(`${id}`).subscribe(x => {
      this.getBox(x.data['device'])
    })
  }

  getBox(device){
    //ADD MESH DEVICE
    if(this.selectedMeshDevice.length < this.maxLimit){
      this.store.dispatch(new AddSelectedMeshDevice(device))  
      this.store.dispatch(new AddSelectedMeshLockin(device.lockin_period_id))  
      const selected = []
      const selectedMesh = this.selectedMeshDevice.map(x => {
        selected.push({
          "device_id":x.id,
          "lockin_period_id":x.lockin_period_id || x.lockin_period['id']
        })
       return{
        device_id:x.id,
        "lockin_period_id":x.lockin_period_id || x.lockin_period['id']
       }
        
      })
      const selectedCable = this.selectedCableDevice.map(x => {
        selected.push({
          "device_id":x.id,
          "lockin_period_id":x.lockin_period_id 
        })
        return {
          
            device_id:x.id,
            "lockin_period_id":x.lockin_period_id 
        }
      })
      let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.devices",selected)
      //UPDATE BILLING SUMMARY PAYLOAD
      this.store.dispatch(new UpdateBillingSummaryPayload(payload))
      //UPDATE BILLING SUMMARY
      this.gelatoService.billingSummary(payload).subscribe(x => {
        if(x.status_code === 200){
          this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        }else{ 
        }
      })  
      //END UPDATE MESH BILLING SUMMARY PAYLOAD
    } else {
      this.openSnackBar( "You've reached the maximum allowed number of selections.","Close")
    }
    
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

}
