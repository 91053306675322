import { LiteSelectDeviceComponent } from './../modules/tv-plan/pages/lite-select-device/lite-select-device.component';
import { CableLiteComponent } from './../modules/tv-plan/pages/cable-lite/cable-lite.component';
import { ApplicationFormComponent } from './components/application-form/application-form.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { HeaderComponent } from './components/header/header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { ServiceFeeComponent } from './components/service-fee/service-fee.component';
import { ProgressComponent } from './components/progress/progress.component';
import { ServiceCardsComponent } from './components/service-cards/service-cards.component';
import { HelpMeChooseComponent } from './components/help-me-choose/help-me-choose.component';
import { ChannelLineupComponent } from './components/channel-lineup/channel-lineup.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ModalPlanDetailComponent } from './components/modal-plan-detail/modal-plan-detail.component';
import { TvModalPlanDetailComponent } from './components/tv-modal-plan-detail/tv-modal-plan-detail.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { AddAPlanToCompareBoxComponent } from './components/add-a-plan-to-compare-box/add-a-plan-to-compare-box.component'
import { MatTabsModule } from '@angular/material/tabs';
import { TvPlanBoxComponent } from '../../app/modules/tv-plan/component/tv-plan-box/tv-plan-box.component';
import { TvPlanBoxContainerComponent } from '../../app/modules/tv-plan/component/tv-plan-box-container/tv-plan-box-container.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BundleModalPlanDetailComponent } from './components/bundle-modal-plan-detail/bundle-modal-plan-detail.component';
import { BundleLineupComponent } from './components/bundle-lineup/bundle-lineup.component';
import { CompareChannelLineupComponent } from './components/compare-channel-lineup/compare-channel-lineup.component';
import { HeaderChannelLineupComponent } from './components/header-channel-lineup/header-channel-lineup.component';
import { ModemDeviceBoxComponent } from './components/modem-device-box/modem-device-box.component';
import { MeshDeviceBoxComponent } from './components/mesh-device-box/mesh-device-box.component';
import { TvSelectDeviceModalComponent } from './components/tv-select-device-modal/tv-select-device-modal.component';
import { ChooseModemBoxComponent } from './components/choose-modem-box/choose-modem-box.component';
import { ChooseCableBoxComponent } from './components/choose-cable-box/choose-cable-box.component';
import { CableDeviceBoxComponent } from './components/cable-device-box/cable-device-box.component';
import { ModemViewDetailsModalComponent } from './components/modem-view-details-modal/modem-view-details-modal.component';
import { MeshBenefitsComponent } from './components/mesh-benefits/mesh-benefits.component';
import { CompareModemTableComponent } from './components/compare-modem-table/compare-modem-table.component';
import { CompareMeshTableComponent } from './components/compare-mesh-table/compare-mesh-table.component';
import { CompareCableTableComponent } from './components/compare-cable-table/compare-cable-table.component';
import { CompareDeviceHeaderComponent } from './components/compare-device-header/compare-device-header.component';
import { RouterModule } from '@angular/router';
import { CableViewModalDetailsComponent } from './components/cable-view-modal-details/cable-view-modal-details.component';
import { CableBoxDeviceComponent } from './components/cable-box-device/cable-box-device.component';
import { CableBoxViewDetailsComponent } from './components/cable-box-view-details/cable-box-view-details.component';
import { FinalizePlanComponent } from './components/finalize-plan/finalize-plan.component';
import { IdModalComponent } from './components/id-modal/id-modal.component';
import { InternetAddonComponent } from './components/internet-addon/internet-addon.component';
import { CableAddonComponent } from './components/cable-addon/cable-addon.component';
import { ChooseMeshBoxComponent } from './components/choose-mesh-box/choose-mesh-box.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ChooseLiteBoxComponent } from './components/choose-lite-box/choose-lite-box.component';
import { LiteDeviceBoxComponent } from './components/lite-device-box/lite-device-box.component';
import { TwoOneDeviceBoxComponent } from './components/two-one-device-box/two-one-device-box.component';
import { TwoDeviceModalComponent } from './components/two-device-modal/two-device-modal.component';
import { CustomLoaderComponent } from './components/custom-loader/custom-loader.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GenericErrorModalComponent } from './components/generic-error-modal/generic-error-modal.component';
@NgModule({
  declarations: [BundleModalPlanDetailComponent, TvModalPlanDetailComponent, HeaderComponent, FooterComponent,
    ServiceFeeComponent, ProgressComponent, ServiceCardsComponent, HelpMeChooseComponent,
    ChannelLineupComponent, ModalPlanDetailComponent, AddAPlanToCompareBoxComponent, BundleModalPlanDetailComponent,
    BundleLineupComponent, CompareChannelLineupComponent, HeaderChannelLineupComponent, ModemDeviceBoxComponent,
    MeshDeviceBoxComponent, TvSelectDeviceModalComponent, ChooseModemBoxComponent,
    ChooseCableBoxComponent, CableDeviceBoxComponent, ModemViewDetailsModalComponent, MeshBenefitsComponent,
    CompareModemTableComponent, CompareMeshTableComponent, CompareCableTableComponent,
    CompareDeviceHeaderComponent, CableViewModalDetailsComponent, CableBoxDeviceComponent,
    CableBoxViewDetailsComponent, FinalizePlanComponent, ApplicationFormComponent, IdModalComponent,
    InternetAddonComponent, CableAddonComponent, ConfirmationComponent, ChooseMeshBoxComponent, ChooseLiteBoxComponent, LiteDeviceBoxComponent, TwoOneDeviceBoxComponent, TwoDeviceModalComponent, CustomLoaderComponent, GenericErrorModalComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatDialogModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    MatProgressSpinnerModule,
    MatSnackBarModule
  ],
  entryComponents: [GenericErrorModalComponent, TvSelectDeviceModalComponent, BundleModalPlanDetailComponent, ModalPlanDetailComponent, TvModalPlanDetailComponent, ChannelLineupComponent, ModemViewDetailsModalComponent, CableDeviceBoxComponent, CableViewModalDetailsComponent, ApplicationFormComponent, IdModalComponent, ConfirmationComponent, TwoDeviceModalComponent],
  exports: [
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatExpansionModule,
    HeaderComponent,
    FooterComponent,
    ServiceFeeComponent,
    CommonModule,
    ProgressComponent,
    ServiceCardsComponent,
    HelpMeChooseComponent,
    MatProgressSpinnerModule,
    ModalPlanDetailComponent,
    AddAPlanToCompareBoxComponent,
    TvModalPlanDetailComponent,
    TvSelectDeviceModalComponent,
    ChannelLineupComponent,
    BundleModalPlanDetailComponent,
    BundleLineupComponent,
    FormsModule,
    CompareChannelLineupComponent,
    HeaderChannelLineupComponent,
    ModemDeviceBoxComponent,
    MeshDeviceBoxComponent,
    CableDeviceBoxComponent,
    ChooseModemBoxComponent,
    ChooseCableBoxComponent,
    ModemViewDetailsModalComponent,
    MeshBenefitsComponent,
    CompareModemTableComponent,
    CompareMeshTableComponent,
    CompareCableTableComponent,
    CompareDeviceHeaderComponent,
    CableViewModalDetailsComponent,
    FinalizePlanComponent,
    ApplicationFormComponent,
    IdModalComponent,
    ConfirmationComponent,
    ChooseMeshBoxComponent,
    CableAddonComponent,
    ChooseLiteBoxComponent,
    TwoOneDeviceBoxComponent,
    LiteDeviceBoxComponent,
    TwoDeviceModalComponent,
    CustomLoaderComponent,
    MatSnackBarModule,
    GenericErrorModalComponent
  ]
})
export class SharedModule { }
