import { SharedModule } from './shared/shared.module';
import { BundlePlanModule } from './modules/bundle-plan/bundle-plan.module';
import { InternetPlanModule } from './modules/internet-plan/internet-plan.module';
import { TvPlanModule } from './modules/tv-plan/tv-plan.module';

import { OnboardingModule } from './modules/onboarding/onboarding.module';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxsModule } from '@ngxs/store';
import { GelatoState } from './shared/state/gelato.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { QrCodeModule } from './modules/qr-code/qr-code.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { OptionsComponent } from './modules/onboarding/pages/options/options.component';
import { UpgradeModule } from './modules/upgrade/upgrade.module';
import { StandaloneModule } from './modules/standalone/standalone.module';


function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.KEYCLOAK_URL,
        realm: environment.KEYCLOAK_REALM,
        clientId: environment.KEYCLOAK_CLIENT,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
    });
}

@NgModule({
  declarations: [
    AppComponent,
    OptionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxsModule.forRoot([
      GelatoState
    ], { developmentMode: false }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot(),
    OnboardingModule,
    TvPlanModule,
    InternetPlanModule,
    BundlePlanModule,
    CoreModule,
    BrowserAnimationsModule,
    SharedModule,
    QrCodeModule,
    KeycloakAngularModule,
    UpgradeModule,
    StandaloneModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
