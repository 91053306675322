import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { Plan } from 'src/app/shared/models/plan.model';
import { Channel } from 'src/app/shared/models/channel.model';
import { Pack } from 'src/app/shared/models/pack.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Service } from 'src/app/shared/models/service.model';
import { Address } from 'src/app/shared/models/address.model';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { UpdateDevices, UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';

@Component({
  selector: 'app-create-tv-plan',
  templateUrl: './create-tv-plan.component.html',
  styleUrls: ['./create-tv-plan.component.css']
})
export class CreateTvPlanComponent implements OnInit {
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getEditMode) editMode$: Observable<boolean>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getCableLite) cableLite$: Observable<boolean>;
  selectedPacks: [Pack]
  selectedChannels: [Channel]
  editMode: boolean
  selectedService: number
  selectedPlans:[Plan]
  Address:string
  message:String = ""
  noService: boolean = false
  services:Service[] = []
  cableLite:boolean = false
  constructor(private router: Router,private gelatoService:GelatoService,private store: Store) { 
    this.selectedChannels$.subscribe(x => {
      this.selectedChannels = x
    })

    this.selectedPacks$.subscribe(x => {
      this.selectedPacks = x
    })
  }

  ngOnInit() {
    this.services$.subscribe(x =>{
      if(x) this.services = x.data['services']
    })
    this.selectedPlans$.subscribe(x => {
      if (x) this.selectedPlans = x
    })
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
    
    this.editMode$.subscribe(x => {
      this.editMode = x
    })

    this.cableLite$.subscribe(x => {
      this.cableLite = x
    })
  }

  nextClick(){
    if(this.cableLite){
      this.router.navigate(['/tv-plan/lite-select-device'])
    }
    else if(this.selectedService == environment.SERVICE_TV){
      // CHECK IF INTERNET SERVICES ARE AVAILABLE
      if(this.services.some(x=>x.id === environment.SERVICE_INTERNET)){
        this.router.navigate(['/internet-plan/select-plan'])
      }else{
        this.selectedCategory$.subscribe(x =>{
          if(x){
            if(x === "FTTH"){
              this.router.navigate(['/internet-plan/plan-summary'])
            }else{
              this.router.navigate(['/tv-plan/select-device'])
            }
          }
        })
      }  
    }else if(this.selectedService == environment.SERVICE_BUNDLE){
      let sub = this.selectedCategory$.subscribe(x => {
        if(x){
          if(x === "FTTH"){
            this.router.navigate(['/bundle-plan/plan-summary'])
          }else{
            //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
            if(this.selectedPlans.filter(x => x.service_id === environment.SERVICE_BUNDLE || environment.SERVICE_INTERNET).some(x => x.is_upgradeable === 1)){
              let planId = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_BUNDLE).map(x => x.id)[0]
                let cablePlanId = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_TV).map(x => x.id)[0]
                this.store.dispatch(new UpdateHTTPRequestFlag(true))
                this.gelatoService.getDevices(planId, planId, cablePlanId, undefined).subscribe(devices => {
                  this.store.dispatch(new UpdateDevices(devices))
                  this.store.dispatch(new UpdateHTTPRequestFlag(false))
                devices.data.modem_devices.length == 1 ? this.router.navigate(['/internet-plan/select-addon']).then(x => sub.unsubscribe()) : this.router.navigate(['/internet-plan/select-device']).then(x => sub.unsubscribe())
              })
            }else{
              this.router.navigate(['/internet-plan/select-addon']).then(x => sub.unsubscribe())
            }
          }
        }
      })
    }else{
      let sub = this.selectedCategory$.subscribe(x => {
        if(x){
          if(x === "FTTH"){
            this.router.navigate(['/internet-plan/plan-summary']).then(x => sub.unsubscribe())
          }else{
            //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
            if(this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET).some(x => x.is_upgradeable === 1)){
              //UPDATE DEVICES, BECAUSE UPDATING OF DEVICES IS IN SELECT DEVICE.
              this.store.dispatch(new UpdateHTTPRequestFlag(true))
              let planId = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET).map(x => x.id)[0]
              let cablePlanId = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_TV).map(x => x.id)[0]
              this.gelatoService.getDevices(planId,planId,cablePlanId,undefined).subscribe(x => {
                this.store.dispatch(new UpdateDevices(x))
                this.store.dispatch(new UpdateHTTPRequestFlag(false))
                x.data.modem_devices.length > 1 ? this.router.navigate(['/internet-plan/select-device']).then(x => sub.unsubscribe()) : this.router.navigate(['/internet-plan/select-addon']).then(x => sub.unsubscribe())
              })
            }else{
              this.router.navigate(['/internet-plan/select-addon']).then(x => sub.unsubscribe())
            }
          }
        }
      })
    }
  }

  nextFlag(){
    if(this.selectedPacks || this.selectedChannels){
      if(this.selectedPacks && this.selectedPacks.length > 0){
        return true
      }
      if(this.selectedChannels && this.selectedChannels.length > 0){
        return true
      }
      else{
        return false
      }
    }
    else{
      return false
    }
  }

 

}
