import { TwoDeviceModalComponent } from './../two-device-modal/two-device-modal.component';
import { DeviceDetail } from './../../models/device-detail.model';
import { DeviceCollection } from './../../models/device-collection.model';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { environment } from 'src/environments/environment';
import { RemoveSelectedDevice, AddSelectedDevice, UpdateBillingSummaryPayload, UpdateBillingSummary } from '../../actions/gelato.actions';
import { AvailableLockinPeriod } from '../../models/available-lockin-period.model';
import { Device } from '../../models/device.model';
import { Devices } from '../../models/devices.model';
import { ModemDevice } from '../../models/mode-device.model';
import { Plan } from '../../models/plan.model';
import { ResponseObject } from '../../models/response-object.model';
import { GelatoState } from '../../state/gelato.state';
import * as _ from 'lodash';

@Component({
  selector: 'app-lite-device-box',
  templateUrl: './lite-device-box.component.html',
  styleUrls: ['./lite-device-box.component.css']
})
export class LiteDeviceBoxComponent implements OnInit {

  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>
  @Input() devices: DeviceCollection;
  selectedDevice:[Device]
  panelOpen: boolean = false;
  modemLength: boolean = false;
  cableLength: boolean = false;

  
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>
  @Select(GelatoState.getSelectedPlans) selectedPlan$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;

  selectedPlan:Plan[] = []
  selectedDevices:Device[] =  []
  selectedCableLockin:[AvailableLockinPeriod]
  selectedMeshLockin:[AvailableLockinPeriod]
  selectedCableDevice:any[] = []
  selectedMeshDevice:any[] = []
  errorMessage = ""
  selectedLockin:AvailableLockinPeriod
  selectedMesh:[Device]
  createYourOwnFlag:boolean = false
  selectedService: number
  billingSummaryPayload:any
  selectedPlans:Plan[] = []

  modemDevice: DeviceDetail[]
  cableDevice: DeviceDetail[]

  constructor(public dialog: MatDialog,private store: Store,private gelatoService:GelatoService,private router: Router) { }

  ngOnInit() {
    this.selectedDevices$.subscribe(x => {
      if(x){
        this.selectedDevice = x
        this.selectedDevices = x
      }
    })
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })

    this.selectedPlan$.subscribe(x => {
      if(x) this.selectedPlan = x
     
    })
   
    this.selectedPlans$.subscribe(x => {
      if(x) this.selectedPlans = x
    })

    this.modemDevice = this.devices.devices.filter(x => x.device_type_id == environment.DEVICE_MODEM)
    this.cableDevice = this.devices.devices.filter(x => x.device_type_id == environment.DEVICE_CABLE)
    this.modemDevice.length >= 1 ? this.modemLength = true : this.modemLength = false
    this.cableDevice.length >= 1 ? this.cableLength = true : this.cableLength = false
  }

  getThis(modem, cable){
    let modemData
    let cableData

    if(modem && cable){
      this.gelatoService.getDeviceDetail(`${this.modemDevice[0].id}`).subscribe(x => {
        modemData = x.data['device']
        this.gelatoService.getDeviceDetail(`${this.cableDevice[0].id}`).subscribe(x => {
          cableData = x.data['device']
          this.getBox(modemData, cableData)
        })
      })
    }
    else{
      this.gelatoService.getDeviceDetail(`${this.modemDevice[0].id}`).subscribe(x => {
        modemData = x.data['device']
          this.getBox(modemData)
      })
    }
  }

  getBox(modemDevice,cableDevice?){
    modemDevice = {...modemDevice, device_service_type: environment.SERVICE_INTERNET}
    cableDevice ? cableDevice = {...cableDevice, device_service_type: environment.SERVICE_TV} : null
    if(this.selectedDevice){
      // Empty selected devices except from mesh
      this.selectedDevice.filter(x => x.device_type_id != environment.DEVICE_MESH).map(x => {
        this.store.dispatch(new RemoveSelectedDevice(x.id))
      })

      // Add new modem and cable device
      this.store.dispatch(new AddSelectedDevice(modemDevice))
      this.cableLength ? this.store.dispatch(new AddSelectedDevice(cableDevice)) : null

      const x = this.selectedPlan.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_TV).map(plan => {
        return{
          plan_id:plan.id,
          //IF INTERNET GET SELECTED MODEM AND MESH DEVICES
          //IF TV GET BOX DEVICES
          device_id:plan.service_id === environment.SERVICE_INTERNET? this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id) : this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id) 
          
        }
      })


      let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",x)
      //UPDATE BILLING SUMMARY PAYLOAD
      this.store.dispatch(new UpdateBillingSummaryPayload(payload))
      //UPDATE BILLING SUMMARY
      this.gelatoService.billingSummary(payload).subscribe(x => {
        if(x.status_code === 200){
          this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        }else{ 
        }
      })
    }
  }

  getStatus(cable,modem){
    if(cable == true && modem == true){
      return(this.selectedDevices.some(x => x.id === this.cableDevice[0].id))
    }
    else{
      return(this.selectedDevices.some(x => x.id === this.modemDevice[0].id))
    }
  }

  filterUpgradable(cable,modem){
    if(this.selectedPlans.some(x => x.is_upgradeable == 0)){
      if(modem == true && cable == false){
        return false
      }
      else{
        return true
      }
    }
    else{
      return true
    }
  }

  openModal(id){
    const dialogRef = this.dialog.open(TwoDeviceModalComponent,{
      height: '95%',
      width: '660px',
      data: {
        deviceId: id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    
    });
  }

}
