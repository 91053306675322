import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateHeaderText, UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { LandingOptions } from 'src/app/shared/models/landing-options.model';
import { Service } from 'src/app/shared/models/service.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent implements OnInit {

  landingOptions: LandingOptions
  landingOptionsCard: Service[] = [];

  constructor(private gelatoService: GelatoService, private router: Router, private store: Store) {
    this.gelatoService.getLandingOptions().subscribe(options => {
      this.landingOptions = options.data
      let obj: Service = {
        id: 98,
        thumbnail: this.landingOptions.not_yet_subscribe.image,
        service_name: this.landingOptions.not_yet_subscribe.text,
        service_category: '',
        description: '',
        icon: '',
        coverage_area: ''
      }
      let obj2: Service = {
        id: 99,
        thumbnail: this.landingOptions.upgrade_plan.image,
        service_name: this.landingOptions.upgrade_plan.text,
        service_category: '',
        description: '',
        icon: '',
        coverage_area: ''
      }
      this.landingOptionsCard.push(obj, obj2)
    })
  }

  ngOnInit() {

  }

  goto(id: number) {
    if (environment.NOT_SUBSCRIBER == id) {
      this.router.navigate(['onboarding/set-location'])
    } else if (environment.UPGRADE_PLAN == id) {
      this.router.navigate(['upsell/form'])
    }
  }

}
