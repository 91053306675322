import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QrCodeRoutingModule } from './qr-code-routing.module';
import { GenerateComponent } from './pages/generate/generate.component';
import { FormComponent } from './pages/form/form.component';
import { QrCodeContainerComponent } from './pages/qr-code-container/qr-code-container.component';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [GenerateComponent, FormComponent, QrCodeContainerComponent],
  imports: [
    CommonModule,
    QrCodeRoutingModule,
    QRCodeModule,
    SharedModule,
    ReactiveFormsModule,
  ]
})
export class QrCodeModule { }
