import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GelatoState } from 'src/app/shared/state/gelato.state';

@Component({
  selector: 'app-qr-code-container',
  templateUrl: './qr-code-container.component.html',
  styleUrls: ['./qr-code-container.component.css']
})
export class QrCodeContainerComponent implements OnInit {

  @Select(GelatoState.getHTTPRequestFlag) httpReqeuestFlag$: Observable<boolean>;
  flag: boolean = true
  constructor() {
    this.httpReqeuestFlag$.subscribe(x =>{
      this.flag = x
    })
  }

  ngOnInit() {
  }

}
