import { environment } from 'src/environments/environment';
import { Address } from 'src/app/shared/models/address.model';
import { Plan } from 'src/app/shared/models/plan.model';
import { ServicePlan } from './../../../../shared/models/servicePlan.model';
import { UpdateServicePlan, UpdateServices, UpdateVisitedPages } from './../../../../shared/actions/gelato.actions';
import { ProgressComponent } from './../../../../shared/components/progress/progress.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Select } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable, Subscription } from 'rxjs';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Service } from 'src/app/shared/models/service.model';
import { Device } from 'src/app/shared/models/device.model';
import { Pack } from 'src/app/shared/models/pack.model';
import { Channel } from 'src/app/shared/models/channel.model';

@Component({
  selector: 'app-select-internet',
  templateUrl: './select-internet.component.html',
  styleUrls: ['./select-internet.component.css']
})
export class SelectInternetComponent implements OnInit {
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getHTTPRequestFlag) httpReqeuestFlag$: Observable<boolean>;
  @Select(GelatoState.getEditMode) editMode$: Observable<boolean>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getSelectedDevices) selectedDevice$: Observable<[Device]>;
  @Select(GelatoState.getVisitedPages) visitedPages$: Observable<[any]>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  @Select(GelatoState.getGoogleCoordinates) googleCoordinates$: Observable<number[]>
  visitedPages: [any]
  flag: any
  editMode: boolean
  selectedService: number
  servicePlan: ServicePlan[] = []
  selectedPlans: Plan[] = []
  selectedCategory: string;
  subscription: Subscription;
  device: Device[] = []
  address: Address
  province_code: string = ""
  createYourOwnFlag: boolean = false
  hasTvPlan: boolean = false
  hasMoreThanOnePlan: boolean = false
  googleCoordinates: any
  requestGoogleServices: boolean = false
  googleAddress: string = localStorage.getItem("google_address")
  constructor(private router: Router, private store: Store, private gelatoService: GelatoService) {
    this.visitedPages$.subscribe(x => {
      if (x) {
        if (!x.includes('select-plan')) {
          this.store.dispatch(new UpdateVisitedPages(['available', 'select-plan']))
        }
      }
    })
    this.googleCoordinates$.subscribe(x => {
      if(x){
        this.googleCoordinates = x
      }
      else{
        this.googleCoordinates = parseInt(localStorage.getItem("google_coordinates"))
      }
    })
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.selectedPlans$.subscribe(x => {
      if (x) {
        this.selectedPlans = x
        if (this.selectedPlans.some(x => x.service_id === environment.SERVICE_TV)) {
          this.hasTvPlan = true
        }
      }

    })

    this.selectedPacks$.subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.hasTvPlan = true
        }
      }
    })
    this.selectedChannels$.subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.hasTvPlan = true
        }
      }
    })

  }

  ngOnInit() {
    this.createYourOwnFlag$.subscribe(x => {
      if (x) this.createYourOwnFlag = x
    })
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google || this.googleAddress) {
          if(x){
            x.data['province'] ? this.province_code = x.data["province"]["code"] : null
          }
          else{
            this.requestGoogleServices = true
            this.gelatoService.getServicesByLATLONG(this.googleCoordinates[0], this.googleCoordinates[1]).subscribe(x => {
              if(x.data){
                this.store.dispatch(new UpdateServices(x))
                this.province_code = x.data['province']['code']
                this.selectedCategory$.subscribe(x => {
                  if (x) {
                    this.gelatoService.getServicesByPlanID("1", this.province_code, x).subscribe(x => {
                      this.store.dispatch(new UpdateHTTPRequestFlag(true))
                      this.servicePlan = x.data["service_plans"]
                      //IF PLANS IS MORE THAN ONE, SHOW HELP ME CHOOSE
                      if (this.servicePlan.reduce((x, y) => x + y.plans.length, 0) > 1) this.hasMoreThanOnePlan = true
                      this.store.dispatch(new UpdateServicePlan(x.data["service_plans"]))
                      this.store.dispatch(new UpdateHTTPRequestFlag(false))
                      this.requestGoogleServices = false
                    })
                  }
                })
              }
              else{
                this.store.dispatch(new UpdateHTTPRequestFlag(false))
                console.warn(x.message)
                this.requestGoogleServices = false
              }
            })
          }
        }
      })
    })

    const addressObj = localStorage.getItem("address")
    if (addressObj) {
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
    this.selectedDevice$.subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.device = x.filter(x => x.device_type_id === environment.DEVICE_CABLE)
        }
        if (this.device.map(x => x.id).length >= 2) {

        }
      }
    })
    this.selectedCategory$.subscribe(x => {
      this.selectedCategory = x
    })
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
    this.httpReqeuestFlag$.subscribe(x => {
      this.flag = x
    })
    this.selectedCategory$.subscribe(x => {
      if (x && this.requestGoogleServices == false) {
        this.gelatoService.getServicesByPlanID("1", this.province_code, x).subscribe(x => {
          this.store.dispatch(new UpdateHTTPRequestFlag(true))
          this.servicePlan = x.data["service_plans"]
          //IF PLANS IS MORE THAN ONE, SHOW HELP ME CHOOSE
          if (this.servicePlan.reduce((x, y) => x + y.plans.length, 0) > 1) this.hasMoreThanOnePlan = true
          this.store.dispatch(new UpdateServicePlan(x.data["service_plans"]))
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        })
      }
    })


    this.editMode$.subscribe(x => {
      this.editMode = x
    })




  }

  nextFlag() {
    if (this.selectedPlans) {
      return this.selectedPlans.some(x => x.service_id == environment.SERVICE_INTERNET)
    }
    else {
      return false
    }
  }
  maybeNext() {
    if (this.selectedService == environment.SERVICE_TV) {
      if (this.device) {
        let sub = this.selectedCategory$.subscribe(x => {
          if (x) {
            if (x === "FTTH") {
              this.router.navigate(['/internet-plan/plan-summary']).then(x => sub.unsubscribe())
            } else {
              //IF TV PLAN HAS 2 BOXES
              if (this.device.map(x => x.id).length >= 2) {
                this.router.navigate(['/tv-plan/select-addon']).then(x => sub.unsubscribe())
              } else {
                //CHECK IF TV PLAN DEVICE CAN BE UPGRADED
                if (this.selectedPlans.filter(x => x.service_id === environment.SERVICE_TV).some(x => x.is_upgradeable === 1)) {
                  this.router.navigate(['/tv-plan/select-device']).then(x => sub.unsubscribe())
                } else {
                  if (this.createYourOwnFlag) {
                    this.router.navigate(['/tv-plan/select-device']).then(x => sub.unsubscribe())
                  } else {
                    this.router.navigate(['/tv-plan/select-addon']).then(x => sub.unsubscribe())
                  }
                }
              }
            }
          }
        })

      } else {
        //CHECK IF TV PLAN DEVICE CAN BE UPGRADED
        if (this.selectedPlans.filter(x => x.service_id === environment.SERVICE_TV).some(x => x.is_upgradeable === 1)) {
          this.router.navigate(['/tv-plan/select-device'])
        } else {
          if (this.createYourOwnFlag) {
            this.router.navigate(['/tv-plan/select-device'])
          } else {
            this.router.navigate(['/tv-plan/select-addon'])
          }
        }
      }
    } else if (this.selectedService === environment.SERVICE_BUNDLE) {
      this.router.navigate(['/tv-plan/select-addon'])
    } else {
      this.router.navigate(['/tv-plan/choose-plan'])
    }

  }
  nextClick() {

    /*
    let sub1 = this.selectedCategory$.subscribe(x => {
      // force to plan sumary due to removal plans
      this.router.navigate(['/internet-plan/plan-summary']).then(x => {
        sub1.unsubscribe()
      })
    })
    */


    if (this.selectedService == environment.SERVICE_INTERNET) {
      // this.router.navigate(['/tv-plan/choose-plan'])
      this.router.navigate(['/internet-plan/plan-summary'])
    } else {

      this.selectedCategory$.subscribe(x => {
        if (x) {
          if (x === "FTTH") {
            this.router.navigate(['/internet-plan/plan-summary'])
          } else {
            //CHECK IF TV PLAN DEVICE CAN BE UPGRADED
            if (this.selectedPlans.filter(x => x.service_id === environment.SERVICE_TV).some(x => x.is_upgradeable === 1)) {
              this.router.navigate(['/tv-plan/select-device'])
            } else {
              if (this.createYourOwnFlag) {
                this.router.navigate(['/tv-plan/select-device'])
              } else {
                this.router.navigate(['/tv-plan/select-addon'])
              }
            }
          }
        }
      })
    }

  }

  maybeFilter() {
    if (this.selectedPlans) {
      return !this.selectedPlans.some(x => x.service_id == environment.SERVICE_INTERNET)
    }
    else {
      return true
    }
  }
}
