import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UpgradeRoutingModule } from './upgrade-routing.module';
import { FormComponent } from './pages/form/form.component';
import { UpgradeContainerComponent } from './pages/upgrade-container/upgrade-container.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AccountNumberDialogComponent } from './components/account-number-dialog/account-number-dialog.component';

@NgModule({
  declarations: [FormComponent, UpgradeContainerComponent, AccountNumberDialogComponent],
  imports: [
    CommonModule,
    UpgradeRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    NgxCaptchaModule
  ],
  entryComponents: [
    AccountNumberDialogComponent
  ]
})
export class UpgradeModule { }
