import { environment } from 'src/environments/environment';
import { Channel } from './../../../../shared/models/channel.model';
import { Pack } from './../../../../shared/models/pack.model';
import { UpdateCreateYourOwnFlag, UpdateDevices, UpdateVisitedPages, UpdateCableLite } from './../../../../shared/actions/gelato.actions';
import { Component, OnInit } from '@angular/core';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { HeaderText } from './../../../../shared/models/header-text.model';
import { ServicePlan } from './../../../../shared/models/servicePlan.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { Router } from '@angular/router';
import { Plan } from 'src/app/shared/models/plan.model';

@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.css']
})


export class ChoosePlanComponent implements OnInit {
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  selectedService: number
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getVisitedPages) visitedPages$: Observable<[any]>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  public message11: any;
  public message2: any;
  public standard: any;
  public cableLite: any;
  public icon1: any;
  public icon2: any;
  public icon3: any;
  subtext1: String;
  subtext2: String;
  subtext3: String;
  public createOwn:any;
  selectedPlans:Plan[] = []
  selectedPacks:Pack[] = []
  selectedChannels:Channel[] = []
  hasTVPlan:boolean = false
  createYourOwnPathFlag:boolean = false
  selected: string = ''
  nextButton: boolean = false
  internetFirst:string
  hasInternetPlan:boolean = false
  selectedCategory:string = ""
  isCableLite:boolean = false
  hasCableLite:boolean = false
  cableLiteFlag:boolean = false
  constructor(
    private srv: GelatoState,private router: Router, private store:Store,private gelatoService:GelatoService
  ) {
    this.visitedPages$.subscribe(x => {
       if(!x.includes('select-plan')){
        this.store.dispatch(new UpdateVisitedPages(['available','select-plan']))
      }
    })


  }



  ngOnInit() {
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
    this.headerText$.subscribe(x => {

      this.internetFirst = x.cabletv_plan_screen.internet_first_heading_text
      this.message11 = x.cabletv_plan_screen.cable_tv_plan_heading_text_1
      this.message2 = x.cabletv_plan_screen.cable_tv_plan_heading_subtext_1
      this.standard = x.cabletv_plan_screen.plan_selections.standard.description
      this.icon1 = x.cabletv_plan_screen.plan_selections.standard.icon
      this.subtext1 = x.cabletv_plan_screen.plan_selections.standard.sub_text
      this.createOwn = x.cabletv_plan_screen.plan_selections.custom.description
      this.icon2 = x.cabletv_plan_screen.plan_selections.custom.icon
      this.subtext2 = x.cabletv_plan_screen.plan_selections.custom.sub_text
      this.cableLite = x.cabletv_plan_screen.plan_selections.cable_lite.description
      this.icon3 = x.cabletv_plan_screen.plan_selections.cable_lite.icon
      this.subtext3 = x.cabletv_plan_screen.plan_selections.cable_lite.sub_text

    })

    this.selectedPlans$.subscribe(x => {
      if(x) {
        this.selectedPlans = x
        this.hasCableLite = this.selectedPlans.some(x => x.service_id === environment.SERVICE_TV && x.id === 56)
        this.hasTVPlan = this.selectedPlans.some(x => x.service_id === environment.SERVICE_TV && x.id !== 56)
        this.hasTVPlan ? this.selected = 'standard' : this.selected = ''
        this.hasInternetPlan = this.selectedPlans.some(x => x.service_id === environment.SERVICE_INTERNET)
        if(this.selectedPlans.some(x => x.service_id === environment.SERVICE_INTERNET) && this.selectedPlans.length === 1 || this.selectedPlans.some(x=>x.service_id === environment.SERVICE_TV)){
          this.cableLiteFlag = true
        }
        if(this.selectedPlans.filter(plans => plans.service_id == environment.SERVICE_INTERNET).some(plan => plan.id == 1)){
          this.cableLiteFlag = false
        }

        // Hide cable lite plan
        this.cableLiteFlag = false; // this.selectedPlans[0].is_lite_enable;
        this.nextButton = this.hasTVPlan
      }
    })

    this.selectedPacks$.subscribe(x => {
      if(x) {
        this.selectedPacks = x
        // Check if there're selected Packs & Channels
        if(this.selectedChannels.length>0 || this.selectedPacks.length>0){
          this.createYourOwnPathFlag = true
          this.selected = 'create'
          this.nextButton = true
        }else{
          this.createYourOwnPathFlag = false
        }
        this.hasTVPlan = this.selectedPlans.some(x => x.service_id === environment.SERVICE_TV)
      }
    })

    this.selectedChannels$.subscribe(x => {
      if(x) {
        this.selectedChannels = x
        // Check if there're selected Packs & Channels
        if(this.selectedChannels.length>0 || this.selectedPacks.length>0){
          this.createYourOwnPathFlag = true
          this.selected = 'create'
          this.nextButton = true
        }else{
          this.createYourOwnPathFlag = false
        }
        this.hasTVPlan = this.selectedPlans.some(x => x.service_id === environment.SERVICE_TV)
      }
    })

    this.selectedCategory$.subscribe(x =>{
      if(x) this.selectedCategory = x
    })

  }

  next(){

    if(this.selectedService && this.selectedPlans){
      if(this.selectedService === environment.SERVICE_TV){

        this.router.navigate(['/internet-plan/select-plan'])
      }else if(this.selectedService == environment.SERVICE_BUNDLE){
        let sub = this.selectedCategory$.subscribe(x => {

          if(x){
            if(x === "FTTH"){
              this.router.navigate(['/bundle-plan/plan-summary']).then(x => sub.unsubscribe())
            }else{
              this.router.navigate(['/bundle-plan/select-device']).then(x => sub.unsubscribe())
            }
          }
        })
      }else{
        let sub = this.selectedCategory$.subscribe(x => {
          if(x){
            if(x === "FTTH"){
              this.router.navigate(['/internet-plan/plan-summary'])
            }else{
              //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
              if(this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_BUNDLE).some(x => x.is_upgradeable === 1)){
                //UPDATE DEVICES, BECAUSE UPDATING OF DEVICES IS IN SELECT DEVICE.
                this.store.dispatch(new UpdateHTTPRequestFlag(true))
                let planId = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET).map(x => x.id)[0]
                this.gelatoService.getDevices(planId, planId, undefined, undefined).subscribe(x => {
                  this.store.dispatch(new UpdateDevices(x))
                  this.store.dispatch(new UpdateHTTPRequestFlag(false))
                  x.data.modem_devices.length > 1 ? this.router.navigate(['/internet-plan/select-device']).then(x => sub.unsubscribe()) : this.router.navigate(['/internet-plan/select-addon']).then(x => sub.unsubscribe())
                })
              }else{
                //UPDATE DEVICES, BECAUSE UPDATING OF DEVICES IS IN SELECT DEVICE.
                this.store.dispatch(new UpdateHTTPRequestFlag(true))
                this.gelatoService.getDevices().subscribe(x => {
                  this.store.dispatch(new UpdateDevices(x))
                  this.store.dispatch(new UpdateHTTPRequestFlag(false))
                })
                this.router.navigate(['/internet-plan/select-addon']).then(x => sub.unsubscribe())
              }
            }
          }
        })
      }
    }
  }

  createYourOwn(x?:any){
    if(x == false){
      this.store.dispatch(new UpdateCreateYourOwnFlag(false))
      this.store.dispatch(new UpdateCableLite(false))
      this.selected = 'standard'
      this.nextButton = true
      this.router.navigate(['/tv-plan/standard-plan'])
    }
    else{
      this.store.dispatch(new UpdateCreateYourOwnFlag(true))
      // this.cableLite ? this.store.dispatch(new UpdateCableLite(true)) : this.store.dispatch(new UpdateCableLite(false))
      this.selected = 'create'
      this.nextButton = true
      this.router.navigate(['/tv-plan/create-plan/tv-packs'])
    }
  }

  isLite(x?:any){
    if(x == true){
      this.store.dispatch(new UpdateCreateYourOwnFlag(false))
      // this.store.dispatch(new UpdateCableLite(true))
      this.selected = 'free'
      this.nextButton = true
      this.router.navigate(['/tv-plan/free-plan'])
    }
  }

  nextClick(){
    if(this.hasTVPlan && this.hasCableLite == false){
      this.router.navigate(['/tv-plan/standard-plan'])
    }
    else if(this.selected == 'create' && this.createYourOwnPathFlag && !this.hasTVPlan){
      this.router.navigate(['/tv-plan/create-plan/tv-packs'])
    }else if(this.hasCableLite){
      this.router.navigate(['/tv-plan/free-plan'])
    }
  }
}
