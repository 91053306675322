import { RemoveSelectedMeshDevice, RemoveSelectedMeshLockin } from './../../../../shared/actions/gelato.actions';
import { Address } from './../../../../shared/models/address.model';
import { InternetViewDetailsModalComponent } from './../internet-view-details-modal/internet-view-details-modal.component';
import { Component, OnInit, Input } from '@angular/core';
import { Plan } from 'src/app/shared/models/plan.model';
import { MatDialog } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { AddSelectedDevice, AddSelectedPlan, RemoveSelectedDevice, RemoveSelectedPlan, UpdateBillingSummary, UpdateBillingSummaryPayload, UpdatePlanDetails } from 'src/app/shared/actions/gelato.actions';
import { ModalPlanDetailComponent } from 'src/app/shared/components/modal-plan-detail/modal-plan-detail.component';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable, Subscription } from 'rxjs';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Service } from 'src/app/shared/models/service.model';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash'
import { Device } from 'src/app/shared/models/device.model';
import { AvailableLockinPeriod } from 'src/app/shared/models/available-lockin-period.model';

@Component({
  selector: 'app-plan-box',
  templateUrl: './plan-box.component.html',
  styleUrls: ['./plan-box.component.css']
})
export class PlanBoxComponent implements OnInit {
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>
  @Select(GelatoState.getSelectedMeshLockin) selectedMeshLockin$: Observable<[AvailableLockinPeriod]>
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;

  selectedPlans:[Plan]
  @Input() plan: Plan;
  panelOpen: boolean = false;
  selectedDevices:Device[] =  []
  selectedCategory: string;
  subscription: Subscription;
  address:Address
  province_code:string = ""
  errorMessage = ""
  billingSummaryPayload:any
  createYourOwnFlag:boolean = false
  selectedMesh:Device[] =[]
  selectedMeshLockin:AvailableLockinPeriod[]=[]
  selectedCable:Device[]= []
  googleAddress: string = localStorage.getItem("google_address")
  constructor(public dialog: MatDialog,private store: Store,private gelatoService:GelatoService) {}

  ngOnInit() {
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google || this.googleAddress) {
          this.province_code = x.data["province"]["code"]
        }
      })
    })
    this.services$.subscribe(x => {
      if(x) {
        this.province_code = x.data["province"]?.code
      }
    })

    this.createYourOwnFlag$.subscribe(x =>{
      if(x) this.createYourOwnFlag = x
    })
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    this.selectedCategory$.subscribe(x => {
      this.selectedCategory = x
    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x

    })

    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })

    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMesh =x
    })
    this.selectedMeshLockin$.subscribe(x => {
      if(x) this.selectedMeshLockin = x
    })
    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCable = x
    })
  }

  openPlanDetail(id) {

    //UPDATE PLAN_DETAIL
    this.subscription = this.selectedCategory$.subscribe(x => {
      if(x){
        this.gelatoService.getPlanDetailsByID(id,this.province_code,x).subscribe(x => {
          this.store.dispatch(new UpdatePlanDetails(x))
          const dialogRef = this.dialog.open(InternetViewDetailsModalComponent,{
            autoFocus: false,
            height: '95%',
            width: '660px',
          });
          dialogRef.afterClosed().subscribe(result => {
            this.subscription.unsubscribe()
          });
        })
      }
    })
  }

  getFlag(plan:Plan){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.id === plan.id)
    }else{
      return false
    }

  }

  getThis(plan:Plan) {
      this.panelOpen = true
    if(this.createYourOwnFlag){
      if(this.selectedPlans){
        if(this.selectedPlans){
          this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET).map(y => {
            this.store.dispatch(new RemoveSelectedPlan(y.id))
          })
          this.selectedDevices.filter(y => y.device_service_type === environment.SERVICE_INTERNET || y.device_type_id === environment.DEVICE_MESH).map(x => {
            this.store.dispatch(new RemoveSelectedDevice(x.id))
          })
        }
        this.store.dispatch(new AddSelectedPlan(plan))
        plan.comes_with.map(x => {
          const copy = {...x, device_service_type: environment.SERVICE_INTERNET}
          this.store.dispatch(new AddSelectedDevice(copy))
        })


          //CONSTRUCT SELECTED PLANS
          const selected = []
          this.selectedPlans.map(plan => {
            selected.push({
              "plan_id":plan.id,
              device_id: this.selectedDevices.filter(x => x.device_service_type == plan.service_id).map(x => x.id)
            })
          })
          // selected.push({
          //   "plan_id":0,
          //   device_id: this.selectedDevices.filter(x => x.device_service_type == plan.service_id).map(x => x.id)
          // })
          let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",selected)

          //UPDATE BILLING SUMMARY PAYLOAD
          this.store.dispatch(new UpdateBillingSummaryPayload(payload))
          //UPDATE BILLING SUMMARY
          this.gelatoService.billingSummary(payload).subscribe(x => {

            if(x.status_code === 200){
              this.errorMessage = ""
              this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
            }else{
              this.errorMessage = x.message
            }
          })
      // CHECK IF NO PLAN SELECTED
      }else{
            this.store.dispatch(new AddSelectedPlan(plan))
          plan.comes_with.map(x => {
            const copy = {...x, device_service_type: environment.SERVICE_INTERNET}
            this.store.dispatch(new AddSelectedDevice(copy))
          })


          //CONSTRUCT SELECTED PLANS
          const selected = []
          this.selectedPlans.map(plan => {
            selected.push({
              "plan_id":plan.id,
              device_id: this.selectedDevices.filter(x => x.device_service_type == plan.service_id).map(x => x.id)
            })
          })
          // selected.push({
          //   "plan_id":0,
          //   device_id: this.selectedDevices.filter(x => x.device_service_type == plan.service_id).map(x => x.id)
          // })
          let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",selected)

          //UPDATE BILLING SUMMARY PAYLOAD
          this.store.dispatch(new UpdateBillingSummaryPayload(payload))
          //UPDATE BILLING SUMMARY
          this.gelatoService.billingSummary(payload).subscribe(x => {

            if(x.status_code === 200){
              this.errorMessage = ""
              this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
            }else{
              this.errorMessage = x.message
            }
          })
      }
        // CHECK IF NOT CREATEYOUROWNPLAN
    }else{
      if(this.selectedPlans){
        this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET).map(y => {
          //IF PLAN HAS MESH DEVICE, REMOVE ALL ADDONS MESH
          if(plan.comes_with.some(x => x.device_type_id === environment.DEVICE_MESH)){
            this.selectedMesh.map(x => {
              this.store.dispatch(new RemoveSelectedMeshDevice(x.id))
            })
            this.selectedMeshLockin.map(x => {
              this.store.dispatch(new RemoveSelectedMeshLockin(x.name))
            })
          }
          this.store.dispatch(new RemoveSelectedPlan(y.id))
        })
        this.selectedDevices.filter(y => y.device_service_type === environment.SERVICE_INTERNET || y.device_type_id === environment.DEVICE_MESH).map(x => {
          this.store.dispatch(new RemoveSelectedDevice(x.id, environment.SERVICE_INTERNET))
        })
      }
      this.store.dispatch(new AddSelectedPlan(plan))
      plan.comes_with.map(x => {
        const copy = {...x, device_service_type: environment.SERVICE_INTERNET}
        this.store.dispatch(new AddSelectedDevice(copy))
      })


      //CONSTRUCT SELECTED PLANS
      const selected = this.selectedPlans.map(plan => {
        return {
          "plan_id":plan.id,
          device_id: this.selectedDevices.filter(x => x.device_service_type == plan.service_id).map(x => x.id)
        }
      })
      let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",selected)

      const devices = this.selectedMesh.map(x => {
        return {

          "device_id": x.id,
          "lockin_period_id": x.lockin_period_id || x.lockin_period['id']
        }
      })
      this.selectedCable.map(x => {
        devices.push({
          "device_id": x.id,
          "lockin_period_id": x.lockin_period_id
        })
      })
      payload = _.set(JSON.parse(JSON.stringify(payload)), "addons.devices", devices)




      //UPDATE BILLING SUMMARY PAYLOAD
      this.store.dispatch(new UpdateBillingSummaryPayload(payload))
      //UPDATE BILLING SUMMARY
      this.gelatoService.billingSummary(payload).subscribe(x => {

        if(x.status_code === 200){
          this.errorMessage = ""
          this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        }else{
          this.errorMessage = x.message
        }
      })
    }



  }


}
