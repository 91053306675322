import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  existingAccount!: any;

  constructor(private keycloak: KeycloakService) { }

  ngOnInit(): void {

    this.keycloak.isLoggedIn().then((is) => {
      if (is) {
        this.keycloak.loadUserProfile().then((x) => {
          console.log(x)
          if (x) {
            this.existingAccount = x;
          }
        })
      }
    });
  }

  login() {
    this.keycloak.login();
  }

}
