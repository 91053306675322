import { environment } from 'src/environments/environment';
import { Channel } from 'src/app/shared/models/channel.model';
import { Device } from 'src/app/shared/models/device.model';
import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Devices } from '../../models/devices.model';
import { ResponseObject } from '../../models/response-object.model';
import { GelatoState } from '../../state/gelato.state';
import { Plan } from '../../models/plan.model';
import { Router } from '@angular/router';
import { Pack } from '../../models/pack.model';

@Component({
  selector: 'app-internet-addon',
  templateUrl: './internet-addon.component.html',
  styleUrls: ['./internet-addon.component.css']
})
export class InternetAddonComponent implements OnInit {
  @Select(GelatoState.getDevices) devices$: Observable<ResponseObject<Devices>>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedDevices) selectedDevice$: Observable<[Device]>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>;
  @Select(GelatoState.getCableLite) cableLite$: Observable<boolean>;
  requestFlag:boolean
  selectedService:number
  devices:Device[]
  packs:Pack[] = []
  channels:Channel[] = []
  subscription:Subscription
  changeFlag:boolean = false
  selectedPlans:Plan[] = []
  createYourOwnFlag:boolean = false
  hasSelectedMesh:boolean = false
  cableLite:boolean
  deviceList: Device[]
  availableMesh: boolean

  constructor(private router: Router) {
    this.cableLite$.subscribe(x => {
      this.cableLite = x
    })
  }

  ngOnInit() {
    this.createYourOwnFlag$.subscribe(x => {
      if(x)this.createYourOwnFlag = x
    })
    this.selectedPlans$.subscribe(x => {
      if(x){
        this.selectedPlans = x
      }
    })
    this.selectedService$.subscribe(x => {
      if(x) this.selectedService = x
    })
    this.devices$.subscribe(x => {
      if(x){
        this.deviceList = x.data.modem_devices
      }
    })
    this.selectedDevice$.subscribe(x => {
      if(x){
        if(x.length > 0){
          if(this.cableLite){
            this.devices = x
          }
          else{
            if(this.selectedService == environment.SERVICE_BUNDLE){
              this.devices = x.filter(x => x.device_service_type === environment.SERVICE_BUNDLE && x.device_type_id == environment.DEVICE_MODEM || x.device_type_id == environment.DEVICE_MESH)
            } else if(this.selectedService == environment.SERVICE_TV){
              this.devices = x.filter(x => x.device_type_id == environment.DEVICE_MODEM)
            }
            else{
              this.devices = x.filter(x => x.device_service_type === environment.SERVICE_INTERNET)
            }
          }
          this.hasSelectedMesh = x.some(x => x.device_type_id === environment.DEVICE_MESH)
        }
        //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
        this.changeFlag = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_BUNDLE).some(x => x.is_upgradeable === 1) && this.deviceList.length > 1
        // Filter available mesh device
        x.filter(device => device.device_type_id == environment.DEVICE_MESH).length > 0 ? this.availableMesh = true : this.availableMesh = false
      }
    })
    this.selectedPacks$.subscribe(x => {
      if(x) this.packs = x
    })
    this.selectedChannels$.subscribe(x => {
      if(x) this.channels = x
    })
  }
  nextClick(){
    // REDIRECT TO PLAN SUMMARY FOR LITE PLAN
    if(this.cableLite){
      this.router.navigate(['/internet-plan/plan-summary'])
    }
    //IF NO TV PLAN SELECTED GOTO FINALIZE
    else {
      this.subscription =  this.selectedPlans$.subscribe(x => {
      //ID = 1 //INTERNET PLAN
      //ID = 2 //TV PLAN 
      //ID = 1 //BUNDLE PLAN
      if(x && this.selectedService){
        // HAVE BUNDLE, TV OR PACKS
         if(x.some(x => x.service_id === environment.SERVICE_TV || x.service_id === environment.SERVICE_BUNDLE || this.packs.length > 0 || this.channels.length > 0)){
           if(this.selectedService === environment.SERVICE_INTERNET){
             //IF HAS INTERNET AND TV PLAN
             //CHECK IF TV PLAN DEVICE CAN BE UPGRADED
            if(this.selectedPlans.filter(x => x.service_id === environment.SERVICE_TV).some(x => x.is_upgradeable === 1)){
              this.router.navigate(['/tv-plan/select-device']).then(x => {
                this.subscription.unsubscribe()
              })
            }else{
              if(this.createYourOwnFlag){
                this.router.navigate(['/tv-plan/select-device']).then(x => {
                  this.subscription.unsubscribe()
                })
              }else{
                if(this.selectedService == environment.SERVICE_INTERNET){
                  this.router.navigate(['/internet-plan/plan-summary']).then(x => {
                    this.subscription.unsubscribe()
                  })
                } else {
                  this.router.navigate(['/tv-plan/select-addon']).then(x => {
                    this.subscription.unsubscribe()
                  })
                }
              }
              
            }
           }else if(this.selectedService === environment.SERVICE_BUNDLE){
            this.router.navigate(['/tv-plan/select-addon']).then(x => {
              this.subscription.unsubscribe()
            })
           }
           else{
            this.router.navigate(['/tv-plan/plan-summary']).then(x => {
              this.subscription.unsubscribe()
            })
           }
         }else{
            this.router.navigate(['/internet-plan/plan-summary']).then(x => {
              this.subscription.unsubscribe()
            })
         }
      }
      
    })
    }
  }
  change(){
    this.cableLite ? this.router.navigate(['/tv-plan/lite-select-device']) : this.router.navigate(['/internet-plan/select-device'])
  }

  back(){
    if(this.changeFlag){
      if(this.cableLite){
        return '/tv-plan/lite-select-device'
      }
      else{
        return '/internet-plan/select-device'
      }
    }
    else{
      if(this.cableLite){
        return '/tv-plan/lite-select-device'
      }
      else{
        if(this.selectedPlans.some(x=>x.service_id === environment.SERVICE_BUNDLE)){
        return '/bundle-plan/select-plan'
        }else{
          return '/tv-plan/choose-plan'
        }
      }
    }
  }
}
