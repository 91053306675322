import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormComponent } from './pages/form/form.component';
import { GenerateComponent } from './pages/generate/generate.component';
import { QrCodeContainerComponent } from './pages/qr-code-container/qr-code-container.component';

const routes: Routes = [
  { 
    path:"qr-code", component:QrCodeContainerComponent,
    children:[
      { path:"form",component:FormComponent },
      { path:"generate",component:GenerateComponent },
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QrCodeRoutingModule { }
