import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { append } from '@ngxs/store/operators';
import { UpdatePackDetail, UpdateChannelDetail, AddSelectedPack, AddSelectedChannel, RemoveSelectedPack, RemoveSelectedChannel, UpdateChannelPacks, UpdateHTTPRequestFlag, UpdateBillingSummary, UpdateBillingSummaryPayload, UpdateServices, UpdateDevices } from './../../../../shared/actions/gelato.actions';
import { Channel } from 'src/app/shared/models/channel.model';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable, concat, forkJoin } from 'rxjs';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Genre } from 'src/app/shared/models/genre.model';
import { ChannelPack } from 'src/app/shared/models/channel-pack.model';
import { Router } from '@angular/router';
import { ChannelObject } from 'src/app/shared/models/channel-object.model';
import { Pack } from 'src/app/shared/models/pack.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectorMatcher } from '@angular/compiler';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Plan } from 'src/app/shared/models/plan.model';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { Address } from 'src/app/shared/models/address.model';
import { VoucherCode } from 'src/app/shared/models/voucher-code';
import { Device } from 'src/app/shared/models/device.model';
import { AvailableLockinPeriod } from 'src/app/shared/models/available-lockin-period.model';
import { Service } from 'src/app/shared/models/service.model';
import * as _ from 'lodash'
@Component({
  selector: 'app-tv-packs',
  templateUrl: './tv-packs.component.html',
  styleUrls: ['./tv-packs.component.css']
})
export class TvPacksComponent implements OnInit {
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getEditMode) editMode$: Observable<boolean>;
  @Select(GelatoState.getSelectedPlans) selectedPlan$: Observable<[Plan]>;
  @Select(GelatoState.getVoucherCode) discountCode$: Observable<ResponseObject<VoucherCode>>;
  @Select(GelatoState.getEditMode) edit$: Observable<boolean>;
  @Select(GelatoState.getApplyButtonFlag) applyButtonFlag$: Observable<boolean>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>;
  @Select(GelatoState.getCableLite) cableLite$: Observable<boolean>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  discountCode:string =""
  selectedPlan:Plan[] = []
  createYourOwnFlag:boolean
  errorMessage = ""
  selectedService: number
  selectedPacks:Pack[] = []
  selectedChannels:Channel[] = []
  selectedPlans:[Plan]
  genres:[Genre]
  channelPack:ChannelPack
  packs:[Pack]
  channelList:ChannelObject
  channels:[Channel]
  packForm: FormGroup
  channelForm: FormGroup
  packSearchFlag: boolean = false
  channelSearchFlag: boolean = false
  alacarteMode: boolean = false
  alacartePlanId: any
  type: string = ''
  address:Address
  editMode: boolean
  province_code:string = ""
  billingSummaryPayload:any
  services:Service[] = []
  cableLite: boolean = false;
  
  constructor(private formBuilder: FormBuilder,private router: Router,private store: Store,private gelatoService:GelatoService) {
  
   }

  ngOnInit() {
    

    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google) {
          this.province_code = x.data["province"]["code"]
        }
      })
    })
   
   
    this.selectedPlan$.subscribe(x => {
      if(x) this.selectedPlan = x
     
    })
   
    this.discountCode$.subscribe(x => {
      if(x) this.discountCode = x.data['voucher_code'].code
      else this.discountCode = ""
    })
  
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })

    this.alacarteMode = this.alacarte()

    this.gelatoService.getGenres().subscribe(x => {
      this.genres = x.data["genres"]
    })

    this.gelatoService.getChannelPacks(this.alacartePlanId,this.type,"","","10","",this.province_code).subscribe(x => {
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.channelPack = x.data["channel_packs"]
      this.packs = x.data["channel_packs"]["data"]
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
    })
    this.gelatoService.getChannels(this.alacartePlanId,this.type,"","","10","",this.province_code).subscribe(x => {
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.channelList = x.data["channels"]
      this.channels = x.data["channels"]["data"]
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      
    })

    this.services$.subscribe(x =>{
      if(x) this.services = x.data['services']
    })
    this.packForm = this.formBuilder.group({
      genre: [''],
      search: [''],
    })
    this.channelForm = this.formBuilder.group({
      genre: [''],
      search: [''],
    })

    this.selectedPacks$.subscribe(x => {
      if(x) this.selectedPacks = x
      else this.selectedPacks = []
    })
    this.selectedChannels$.subscribe(x => {
      if(x) this.selectedChannels = x
     else this.selectedChannels = []
    })

    this.editMode$.subscribe(x => {
      this.editMode = x
    })

    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })

    this.createYourOwnFlag$.subscribe(x => {
      this.createYourOwnFlag = x
    })

    this.cableLite$.subscribe(x => {
      if(x) 
      this.cableLite = x
    })
  }

  getPackCheck(pack:Pack) {
    if(this.selectedPacks){
      return this.selectedPacks.some(x => x.id === pack.id)
    }else{
      return false
    }
  }

  getChannelCheck(channel:Channel) {
    if(this.selectedChannels){
      return this.selectedChannels.some(x => x.id === channel.id)
    }else{
      return false
    }
  }
  next(){
    if(this.cableLite){
      this.router.navigate(['/tv-plan/lite-select-device'])
    }

    else if(this.selectedService && this.selectedPlans){
      if(this.selectedService === environment.SERVICE_TV){
        // CHECK IF INTERNET SERVICES ARE AVAILABLE
        if(this.services){
          if(this.services.some(x=>x.id=== environment.SERVICE_INTERNET)){
            this.router.navigate(['/internet-plan/select-plan'])
          }else{
            this.selectedCategory$.subscribe(x =>{
              if(x){
                if(x === "FTTH"){
                  this.router.navigate(['/internet-plan/plan-summary'])
                }else{
                  this.router.navigate(['/tv-plan/select-device'])      
                }
              }
            })
          }
        }
      }else if(this.selectedService == environment.SERVICE_BUNDLE){
        let sub = this.selectedCategory$.subscribe(x => {
          if(x){
            if(x === "FTTH"){
              this.router.navigate(['/bundle-plan/plan-summary'])
            }else{
              //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
              if(this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_BUNDLE).some(x => x.is_upgradeable === 1)){
                let planId = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_BUNDLE).map(x => x.id)[0]
                let cablePlanId = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_TV).map(x => x.id)[0]
                this.store.dispatch(new UpdateHTTPRequestFlag(true))
                this.gelatoService.getDevices(planId, planId, cablePlanId, undefined).subscribe(devices => {
                  this.store.dispatch(new UpdateDevices(devices))
                  this.store.dispatch(new UpdateHTTPRequestFlag(false))
                  devices.data.modem_devices.length == 1 ? this.router.navigate(['/internet-plan/select-addon']).then(x => sub.unsubscribe()) : this.router.navigate(['/internet-plan/select-device']).then(x => sub.unsubscribe())
                })
              }else{
                this.router.navigate(['/internet-plan/select-addon']).then(x => sub.unsubscribe())
              }
            }
          }
        }) 
      }else{
        let sub = this.selectedCategory$.subscribe(x => {
          if(x){
            if(x === "FTTH"){
              this.router.navigate(['/internet-plan/plan-summary'])
            }else{
              //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
              if(this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_BUNDLE).some(x => x.is_upgradeable === 1)){
                let planId = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_INTERNET).map(x => x.id)[0]
                let cablePlanId = this.selectedPlans.filter(x => x.service_id === environment.SERVICE_TV).map(x => x.id)[0]
                this.store.dispatch(new UpdateHTTPRequestFlag(true))
                this.gelatoService.getDevices(planId, planId, cablePlanId, undefined).subscribe(devices => {
                  this.store.dispatch(new UpdateDevices(devices))
                  this.store.dispatch(new UpdateHTTPRequestFlag(false))
                  devices.data.modem_devices.length > 1 ? this.router.navigate(['/internet-plan/select-device']).then(x => sub.unsubscribe()) : this.router.navigate(['/internet-plan/select-addon']).then(x => sub.unsubscribe())
                })
              }else{
                //UPDATE DEVICES, BECAUSE UPDATING OF DEVICES IS IN SELECT DEVICE.
                this.store.dispatch(new UpdateHTTPRequestFlag(true))
                this.gelatoService.getDevices().subscribe(x => {
                  this.store.dispatch(new UpdateDevices(x))
                  this.store.dispatch(new UpdateHTTPRequestFlag(false))
                })
                this.router.navigate(['/internet-plan/select-addon']).then(x => sub.unsubscribe())
              }
            }
          }
        }) 
      }
    }
  }
  
  selectPack(pack:Pack, e: any){
    e == true ? this.store.dispatch(new AddSelectedPack(pack)) : this.store.dispatch(new RemoveSelectedPack(pack.id))
    let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.channel_pack_id",this.selectedPacks.map(x => x.id))
    
    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      
      if(x.status_code === 200){
        this.errorMessage = ""
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }else{
        this.errorMessage = x.message
      }
    })    
  }

  selectChannel(channel:Channel, e: any){
    e == true ? this.store.dispatch(new AddSelectedChannel(channel)) : this.store.dispatch(new RemoveSelectedChannel(channel.id))
    let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.channel_id",this.selectedChannels.map(x => x.id))
    
    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      
      if(x.status_code === 200){
        this.errorMessage = ""
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }else{
        this.errorMessage = x.message
      }
    })    
  }
  viewPackDetails(pack:Pack){
    this.gelatoService.getPackDetail(`${pack.id}`,`${this.province_code}`).subscribe(x => {
      this.store.dispatch(new UpdatePackDetail(x))
      this.router.navigate(['/tv-plan/create-plan/pack-info'])
    })
  }
  
  viewChannelDetails(channel:Channel){
    this.gelatoService.getChannelDetail(`${channel.id}`,`${this.province_code}`).subscribe(x => {
      this.store.dispatch(new UpdateChannelDetail(x))
      this.router.navigate(['/tv-plan/create-plan/channel-details'])
    })
    
  }

  loadPackByGenre(){
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    const genre = this.packForm.controls["genre"].value || ""
    const search = this.packForm.controls["search"].value || ""
    if(genre){
      this.gelatoService.getChannelPacks(this.alacartePlanId,this.type,genre.id,search,"10","",this.province_code).subscribe(x => {
        this.channelPack = x.data["channel_packs"]
        this.packs = x.data["channel_packs"]["data"]
        this.packSearchFlag = true
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })
    }else{
      this.gelatoService.getChannelPacks(this.alacartePlanId,this.type,"",search,"10","",this.province_code).subscribe(x => {
        this.channelPack = x.data["channel_packs"]
        this.packs = x.data["channel_packs"]["data"]
        this.packSearchFlag = false
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })
    }
    
  }
  loadPackBySearch(event){
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    const search = event.target.value as string
    const genre = this.packForm.controls["genre"].value || ""
    if(search.length > 2){
      if(genre){
        this.gelatoService.getChannelPacks(this.alacartePlanId,this.type,genre.id,search,"10","",this.province_code).subscribe(x => {
          this.channelPack = x.data["channel_packs"]
          this.packs = x.data["channel_packs"]["data"]
          this.packSearchFlag = true
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        })
      }else{
        this.gelatoService.getChannelPacks(this.alacartePlanId,this.type,"",search,"10","",this.province_code).subscribe(x => {
          this.channelPack = x.data["channel_packs"]
          this.packs = x.data["channel_packs"]["data"]
          this.packSearchFlag = true
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        })
      }
    }else if(search.length < 1){
      if(genre){
        this.gelatoService.getChannelPacks(this.alacartePlanId,this.type,genre.id,search,"10","",this.province_code).subscribe(x => {
          this.channelPack = x.data["channel_packs"]
          this.packs = x.data["channel_packs"]["data"]
          this.packSearchFlag = false
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        })
      }else{
        this.gelatoService.getChannelPacks(this.alacartePlanId,this.type,"","","10","",this.province_code).subscribe(x => {
          this.channelPack = x.data["channel_packs"]
          this.packs = x.data["channel_packs"]["data"]
          this.packSearchFlag = false
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        })
      }
    }
  }

  loadChannelByGenre(){
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    const genre = this.channelForm.controls["genre"].value || ""
    const search = this.channelForm.controls["search"].value || ""
    if(genre){
      this.gelatoService.getChannels(this.alacartePlanId,this.type,genre.id,search,"10","",this.province_code).subscribe(x => {
        this.channelList = x.data["channels"]
        this.channels = x.data["channels"]["data"]
        this.channelSearchFlag = true
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })
    }else{
      this.gelatoService.getChannels(this.alacartePlanId,this.type,"",search,"10","",this.province_code).subscribe(x => {
        this.channelList = x.data["channels"]
        this.channels = x.data["channels"]["data"]
        this.channelSearchFlag = false
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })
    }
    
  }

  loadChannelBySearch(event){
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    const search = event.target.value as string
    const genre = this.channelForm.controls["genre"].value || ""
    if(search.length > 2){
      if(genre){
        this.gelatoService.getChannels(this.alacartePlanId,this.type,genre.id,search,"10","",this.province_code).subscribe(x => {
          this.channelList = x.data["channels"]
          this.channels = x.data["channels"]["data"]
          this.channelSearchFlag = true
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        })
      }else{
        this.gelatoService.getChannels(this.alacartePlanId,this.type,"",search,"10","",this.province_code).subscribe(x => {
          this.channelList = x.data["channels"]
          this.channels = x.data["channels"]["data"]
          this.channelSearchFlag = true
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        })
      }
    }else if(search.length < 1){
      if(genre){
        this.gelatoService.getChannels(this.alacartePlanId,this.type,genre.id,search,"10","",this.province_code).subscribe(x => {
          this.channelList = x.data["channels"]
          this.channels = x.data["channels"]["data"]
          this.channelSearchFlag = false
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        })
      }else{
        this.gelatoService.getChannels(this.alacartePlanId,this.type,"","","10","",this.province_code).subscribe(x => {
          this.channelList = x.data["channels"]
          this.channels = x.data["channels"]["data"]
          this.channelSearchFlag = false
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        })
      }
    }
  }
  loadMorePacks(channelPack:ChannelPack){
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.gelatoService.getChannelPacks(this.alacartePlanId,this.type,"","","10",`${channelPack.current_page+1}`,this.province_code).subscribe(x => {
        this.channelPack = x.data["channel_packs"]
        this.packs = this.packs.concat(x.data["channel_packs"]["data"]) as [Pack]
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })
  }

  loadMoreChannels(channelList:ChannelObject){
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.gelatoService.getChannels(this.alacartePlanId,this.type,"","","10",`${channelList.current_page+1}`,this.province_code).subscribe(x => {
      this.channelList = x.data["channels"]
      this.channels = this.channels.concat(x.data["channels"]["data"]) as [Channel]
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
    })
  }

  resetPacks(){
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.gelatoService.getChannelPacks(this.alacartePlanId,this.type,"","","10","",this.province_code).subscribe(x => {
      this.channelPack = x.data["channel_packs"]
      this.packs = x.data["channel_packs"]["data"]
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
    })
  }

  resetChannels(){
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.gelatoService.getChannels(this.alacartePlanId,this.type,"","","10","",this.province_code).subscribe(x => {
      this.channelList = x.data["channels"]
      this.channels = x.data["channels"]["data"]
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
    })
  }

  nextFlag(){
    if(this.selectedService == environment.SERVICE_INTERNET){
      return true
    }
    if(this.selectedService == environment.SERVICE_TV){
      if(this.selectedPlans){
        if(this.selectedPlans.length > 0){
          return true
        }
      }
    }
    if(this.selectedService == environment.SERVICE_BUNDLE){
      if(this.selectedPlans){
        if(this.selectedPlans.length > 0){
          return true
        }
      }
    }
  }

  // CHECK AND SET ALACARTE STATUS, PLAN ID AND TYPE
  alacarte(){
    this.type = ''
    this.alacartePlanId = undefined
    if(this.selectedService == environment.SERVICE_BUNDLE){
      this.selectedPlans.forEach(x => {
        if(x.service_id == environment.SERVICE_BUNDLE){
          this.alacartePlanId = x.id;
        }
      })
      this.type = 'alacarte'
      return true
    }

    if(this.selectedService == environment.SERVICE_TV || this.selectedService == environment.SERVICE_INTERNET){
      if(this.selectedPlans){
        if(this.selectedPlans.length > 0){
          if(this.selectedPlans.some(x => x.service_id == environment.SERVICE_TV)){
            this.selectedPlans.forEach(x => {
              if(x.service_id == environment.SERVICE_TV){
                this.alacartePlanId = x.id
              }
            })
            this.type = 'alacarte'
            return true
          }
        }
      }
    }
    else{
      this.type = ''
      this.alacartePlanId = ''
      return false
    }
  }

  maybeFilter() {
    if (this.selectedChannels || this.selectedPacks) {
      if(this.selectedChannels.length > 0){
        return false
      }else if(this.selectedPacks.length > 0){
        return false
      }
      else{
        return true
      }
    }
    else {
      return true
    }
  }
}
