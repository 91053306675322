import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateBarangays, UpdateBuildings, UpdateCities, UpdateHTTPRequestFlag, UpdateProvinces, UpdateStreets } from 'src/app/shared/actions/gelato.actions';
import { Barangay } from 'src/app/shared/models/barangay.model';
import { Building } from 'src/app/shared/models/building.model';
import { City } from 'src/app/shared/models/city.model';
import { Province } from 'src/app/shared/models/province.model';
import { QrCode, QRCodePurposes } from 'src/app/shared/models/qr-code.model';
import { Street } from 'src/app/shared/models/street.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  @Select(GelatoState.getProvinces) provinces$: Observable<[Province]>;
  @Select(GelatoState.getCities) cities$: Observable<[City]>;
  @Select(GelatoState.getBuildings) buildings$: Observable<[Building]>;
  @Select(GelatoState.getBarangays) barangays$: Observable<[Barangay]>;
  @Select(GelatoState.getStreets) streets$: Observable<[Street]>;
  
  qrForm: FormGroup
  isDisabled : boolean = true
  submitted: boolean = false
  purposes:[QRCodePurposes]
  selectedPurpose: string
  qrData: QrCode;
  data:string = `${document.baseURI}onboarding/available-location?qr-code=`
  
  constructor(private formBuilder: FormBuilder, private router: Router,private store: Store,private gelatoService:GelatoService) {
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    gelatoService.getProvinces().subscribe(x => {
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      this.store.dispatch(new UpdateProvinces(x.data["provinces"]))
    })

    gelatoService.getListOfQRCodePurposes().subscribe(purposes => {
      this.purposes = purposes.data;
    })
   }

  ngOnInit() {
    this.qrForm = this.formBuilder.group({
      agent_name: ['',Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9\\-\\s\\ñ\\Ñ']+$")])],
      account_executive_name: ['',Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9\\-\\s\\ñ\\Ñ']+$")])],
      agent_email: ['',Validators.compose([Validators.required, Validators.email,,Validators.pattern('^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\\.[a-z]{2,4}$')])],
      account_executive_email: ['',Validators.compose([Validators.required, Validators.email,,Validators.pattern('^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\\.[a-z]{2,4}$')])],
      street: [''],
      province: [''],
      city: [''],
      building_id: [''],
      barangay: [''],
      voucher_code: [''],
    })
  }

  loadSelectData(event) {
    this.submitted = false
    if(event.target.name === "province"){
      //LOAD CITIES
      const province = this.qrForm.controls["province"].value
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.gelatoService.getCities(province).subscribe(x => {
        this.store.dispatch(new UpdateCities(x.data["cities"]))
        this.store.dispatch(new UpdateBarangays(x.data[""]))
        this.store.dispatch(new UpdateStreets(x.data[""]))
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })
      document.getElementsByClassName('province')[0]['style'].color = 'black'
      this.qrForm.controls["province"].setValidators([Validators.required])
      this.qrForm.controls["city"].setValidators([Validators.required])
      this.qrForm.controls["barangay"].setValidators([Validators.required])
      this.qrForm.controls["street"].setValidators([Validators.required])

      // Reset other select
      document.getElementsByClassName('city')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('barangay')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('building')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      this.qrForm.controls['city'].patchValue('')
      this.qrForm.controls['barangay'].patchValue('')
      this.qrForm.controls['street'].patchValue('')
    }else if(event.target.name === "city"){
      //LOAD BARANGAYS
      const province = this.qrForm.controls["province"].value
      const city = this.qrForm.controls["city"].value
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.gelatoService.getBarangays(province,city).subscribe(x => {
        this.store.dispatch(new UpdateBarangays(x.data["barangays"]))
        this.store.dispatch(new UpdateStreets(x.data[""]))
      })
      this.gelatoService.getBuildings(province,city).subscribe(x => {
        this.store.dispatch(new UpdateBuildings(x.data["buildings"]))
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })

      // Reset other select
      document.getElementsByClassName('city')[0]['style'].color = 'black'
      document.getElementsByClassName('barangay')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('building')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      this.qrForm.controls['barangay'].patchValue('')
      this.qrForm.controls['street'].patchValue('')
    }else if(event.target.name === "barangay"){
      //LOAD STREETS
      const province = this.qrForm.controls["province"].value
      const city = this.qrForm.controls["city"].value
      const barangay = this.qrForm.controls["barangay"].value
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.gelatoService.getStreets(province,city,barangay).subscribe(x => {
        this.store.dispatch(new UpdateStreets(x.data["streets"]))
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })

      // Reset other select
      document.getElementsByClassName('barangay')[0]['style'].color = 'black'
      document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      this.qrForm.controls['street'].patchValue('')
    }else if(event.target.name === "building"){
      // Reset other select
      document.getElementsByClassName('building')[0]['style'].color = 'black'
      document.getElementsByClassName('barangay')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      
    }
    else document.getElementsByClassName('street')[0]['style'].color = 'black'
    
  }

  clickPurpose(name:QRCodePurposes){
    const checkboxes = document.getElementsByClassName('custom-checkbox')
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i]['checked'] = false
    }
    document.getElementById(name.id.toString())['checked'] = true
    this.selectedPurpose = name.name
  }

  generateCode(){
    this.submitted = true
    let qrCodeId:number
    if (this.qrForm.dirty && this.qrForm.valid && this.selectedPurpose) {
      this.qrData = {...this.qrForm.value, purpose: this.selectedPurpose}
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.gelatoService.generateQRCode(this.qrData).subscribe(x => {
        if(x.status_code == 200){
          this.data += x.data.qr_code_id
          qrCodeId = x.data.qr_code_id
          setTimeout(() => {
            this.updateQRCode(qrCodeId)
          }, 1500);
        }
      })
    }
  }

  updateQRCode(qrCodeId:number){
    let image = document.getElementsByClassName('qrcode')[0].children[0]['src']
      const reqData = {
        _method: "PUT",
        qr_image: image
      }
      this.gelatoService.updateQrCode(reqData, qrCodeId).subscribe(x => {
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
        this.router.navigate(['qr-code/generate'], {state: {qr_code_id : qrCodeId}})
      })
  }

  clearAddress(){
    this.qrForm.controls["province"].setValidators([]);
    this.qrForm.controls["city"].setValidators([]);
    this.qrForm.controls["barangay"].setValidators([]);
    this.qrForm.controls["street"].setValidators([]);
    this.qrForm.controls["province"].setValue("")
    this.qrForm.controls["city"].setValue("")
    this.qrForm.controls["barangay"].setValue("")
    this.qrForm.controls["street"].setValue("")
    this.qrForm.controls["building_id"].setValue("")
    document.getElementsByClassName('province')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
    document.getElementsByClassName('city')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
    document.getElementsByClassName('barangay')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
    document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
    document.getElementsByClassName('building')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
  }
}
