import { HeaderText } from 'src/app/shared/models/header-text.model';
import { environment } from 'src/environments/environment';
import { AvailableLockinPeriod } from './../../models/available-lockin-period.model';
import { VoucherCode } from './../../models/voucher-code';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Channel } from 'src/app/shared/models/channel.model';
import { RemoveSelectedPlan, RemoveSelectedPack, RemoveSelectedChannel, ResetHelpMeChoose, UpdateVoucherCode, UpdateEditMode, UpdateEditOriginLink, UpdateApplyButtonFlag, RemoveSelectedCableDevice, RemoveSelectedCableLockin, RemoveSelectedDevice, RemoveSelectedMeshLockin, RemoveSelectedMeshDevice, ResetAll, UpdateCreateYourOwnFlag, UpdateVisitedPages, ResetCableIndexId, RemoveSelectedCableDeviceId, UpdateBillingSummaryPayload, UpdateBillingSummary, ResetCableAddons, UpdateNumberOfBoxes, UpdateCableLite } from './../../actions/gelato.actions';
import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Plan } from '../../models/plan.model';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs';
import { Pack } from '../../models/pack.model';
import { Router } from '@angular/router';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Device } from '../../models/device.model';
import { CableBoxDevice } from '../../models/cable-box-device.model';
import { MeshDevice } from '../../models/mesh-device.model';
import { BillingSummary } from '../../models/billing-summary';
import * as _ from 'lodash';

@Component({
  selector: 'app-service-fee',
  templateUrl: './service-fee.component.html',
  styleUrls: ['./service-fee.component.css']
})
export class ServiceFeeComponent implements OnInit {
  @Select(GelatoState.getSelectedPlans) selectedPlan$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getVoucherCode) discountCode$: Observable<ResponseObject<VoucherCode>>;
  @Select(GelatoState.getEditMode) edit$: Observable<boolean>;
  @Select(GelatoState.getApplyButtonFlag) applyButtonFlag$: Observable<boolean>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>;
  @Select(GelatoState.getSelectedMeshLockin) selectedMeshLockin$: Observable<[AvailableLockinPeriod]>;
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>;
  @Select(GelatoState.getSelectedCableLockin) selectedCableLockin$: Observable<[AvailableLockinPeriod]>;
  @Select(GelatoState.getSelectedCableDeviceId) CableId$: Observable<[any]>;
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>;
  @Select(GelatoState.getSelectedDeviceIndex) cableIndex$: Observable<any>
  @Select(GelatoState.getMonthlyPrice) monthlyPrice$: Observable<[number]>
  @Select(GelatoState.getInstallFee) installPrice$: Observable<[number]>
  @Select(GelatoState.getBillingSummary) billingSummary$: Observable<[BillingSummary]>
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getCableLite) cableLite$: Observable<boolean>;

  selectedPlan:Plan[] = []
  selectedPacks:Pack[] = []
  selectedDevices:Device[] =  []
  selectedChannels:Channel[] = []
  selectedCableLockin:[AvailableLockinPeriod]
  selectedCableDevice:CableBoxDevice[] = []
  selectedMeshLockin:[AvailableLockinPeriod]
  selectedMeshDevice:MeshDevice[] = []
  discountAmount:number = 0
  total:number = 0
  packTotal = 0
  deviceTotal = 0
  channelTotal = 0
  planTotal = 0
  meshTotal = 0
  validPromo:boolean = false
  promoAmount:number = 0
  voucherCode = ""
  errorMessage = ""
  editMode:boolean = false
  edit: boolean = false
  hasMeshDevice:boolean = false
  hasCableDevice:boolean = false
  cableId:any
  selectedService:number
  createYourOwnFlag:boolean = false
  cableIndex:number[]
  monthlyPrice:number[]
  installPrice:number[]

  BillingSummary:BillingSummary[]
  billingSummaryPayload:any
  priceCheck:boolean = false
  services:any[]
  headerText:HeaderText
  cableDeviceFlag:boolean = false

  cableLite:boolean
  rate:string
  
  env = environment

  constructor(private store: Store,private router: Router, private gelatoService:GelatoService) {
    
    this.edit$.subscribe(x => {
      this.edit = x
      this.editMode = x
    })

    this.cableLite$.subscribe(x => {
      this.cableLite = x
    })
    
    
   }

  ngOnInit() {
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
     
    })
    this.billingSummary$.subscribe(x => {
      if(x){
      this.BillingSummary = x
      this.BillingSummary.map(x=>{
        const device_type_id = x.addons.devices.some(x=>x.device_type_id === environment.DEVICE_CABLE)
       if(device_type_id){
        this.cableDeviceFlag = true
       }
      })
      
      if(this.BillingSummary){
        this.priceCheck = true

      }
     
        else{
        
          this.priceCheck = false
        
        }
   }
       
      })
    this.monthlyPrice$.subscribe(x => {
      this.monthlyPrice = x
    })
    
    this.installPrice$.subscribe(x => {
      this.installPrice = x
    })

    this.cableIndex$.subscribe(x => {
      this.cableIndex = x
    })
    this.createYourOwnFlag$.subscribe(x => {
      if(x) this.createYourOwnFlag = x
      
    })
    this.selectedService$.subscribe(x => {
      if(x) this.selectedService = x
    })
    this.discountCode$.subscribe(x => {
      if(x){
        if(x.status_code === 200){
          this.discountAmount = +x.data["voucher_code"]["discount_amount"]
          this.voucherCode = x.data["voucher_code"]["code"]
          this.validPromo = true
          this.promoAmount = x.data["voucher_code"]["discount_amount"]
          this.rate = x.data["voucher_code"]["rate"]
          this.total -= this.promoAmount
        }else{
          this.discountAmount = 0 
        }
      }else{
        this.discountAmount = 0 
      }
      
    })
    this.selectedPlan$.subscribe(x => {
     if(x) this.selectedPlan = x
    })

    this.selectedDevices$.subscribe(x => {
      if(x)
        this.selectedDevices = x
    })
    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMeshDevice = x
    })
    this.selectedMeshLockin$.subscribe(x => {
      if(x)
        this.selectedMeshLockin = x
    })
    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCableDevice = x
    })
    this.selectedCableLockin$.subscribe(x => {
      if(x) this.selectedCableLockin = x
    })
    this.CableId$.subscribe(x => {
      if(x) this.cableId = x
    })

    this.selectedChannels$.subscribe(x => {
      if(x) this.selectedChannels = x
    })    
    this.selectedPacks$.subscribe(x => {
      if(x) this.selectedPacks = x
    })
    
    this.headerText$.subscribe(x => {
      if(x) this.headerText = x
    })
  }
  hasBoxSelected(){
    if(this.selectedDevices.some(x=>x.device_type_id===2) ){
      return true
    }else{
      return false
    }
  }
  removeDevice(device:Device){
    this.store.dispatch(new RemoveSelectedDevice(device.id))
    this.store.dispatch(new UpdateCreateYourOwnFlag(false))
  }
  removePlan(plan:Plan){
    // Remove discount on delete plan
    this.removeDiscount();
    this.store.dispatch(new RemoveSelectedPlan(plan.id))
    if(plan.service_id === environment.SERVICE_INTERNET) {
      //IF SELECTED PLAN HAS CABLE LITE, REMOVE CABLE LITE PLAN AND ADDONS
      //56 = CABLE LITE PLAN
      this.selectedMeshDevice.map(x=>{
        this.removeMeshDevice(x,true)
      })
      if(this.selectedPlan.some(x => x.id === 56)){
        this.store.dispatch(new RemoveSelectedPlan(56))
        this.store.dispatch(new UpdateCableLite(false))
        this.clearAll()
      }
     
      this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(y => {
        this.store.dispatch(new RemoveSelectedDevice(y.id))
      })

      // REMOVE FROM BILLING SUMMARY, GO TO SELECT PLAN IF PLAN LIST IS NOT EMPTY
      this.removeBillingSummaryPlan(plan)
      this.selectedPlan.length >= 1 ? this.router.navigate(['internet-plan/select-plan']) : null
    }else if(plan.service_id === environment.SERVICE_TV){
      if(plan.id === 56){
        this.store.dispatch(new RemoveSelectedPlan(56))
        this.store.dispatch(new UpdateCableLite(false))
        let changeFlag = this.selectedPlan.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_BUNDLE).some(x => x.is_upgradeable === 1)
        changeFlag ? this.router.navigate(['/internet-plan/select-device']) : this.router.navigate(['/internet-plan/select-addon'])
      }
      this.removeChannelsPacksCableBoxes()

      // REMOVE FROM BILLING SUMMARY, GO TO SELECT PLAN IF PLAN LIST IS NOT EMPTY
      this.removeBillingSummaryPlan(plan)
      this.selectedPlan.length >= 1 ? this.router.navigate(['tv-plan/standard-plan']) : null
    }else if(plan.service_id === environment.SERVICE_BUNDLE){
      //IF BUNDLE REMOVE ALL DEVICES
      this.selectedMeshDevice.map(x=>{
        this.removeMeshDevice(x,true)
      })
      // this.removeBillingSummaryPlan(plan)
      this.clearAll()
    }
    
    // GO TO AVAILABLE LOCATION IF ALL PLANS ARE DELETED
    if(this.selectedPlan.length < 1 && this.selectedPacks.length < 1 && this.selectedChannels.length < 1 ) {
      const path = this.router.url.split("/")
      this.router.navigate(['/onboarding/available-location'])
    } 
 
  }
  removeChannelsPacksCableBoxes(){
    //IF PLAN IS CABLE TV REMOVE SELECTED CHANNELS AND PACKS
    this.selectedPacks.map(x => {
      this.store.dispatch(new RemoveSelectedPack(x.id))
    })
    this.selectedChannels.map(x => {
      this.store.dispatch(new RemoveSelectedChannel(x.id))
    })
    //REMOVE CREATE YOUR OWN FLAG, CABLE INDEX ETC.
    this.store.dispatch(new ResetCableAddons())
    //REMOVE ALL CABLE TV DEVICES
    this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(y => {
      this.store.dispatch(new RemoveSelectedDevice(y.id))
    })
  }
  
  removePack(pack:Pack){
    this.store.dispatch(new RemoveSelectedPack(pack.id))
    this.removeBillingSummaryPack()
    if(this.createYourOwnFlag && this.selectedPacks.length < 1 && this.selectedChannels.length < 1){
      this.selectedCableDevice.map(x => {
        this.store.dispatch(new RemoveSelectedCableDevice(x.id))
        this.store.dispatch(new RemoveSelectedCableDeviceId(x.id))
        this.removeBillingSummaryCableAddons()
        //this.router.navigate(['/tv-plan/choose-plan'])
      })
    }
    if(this.selectedPlan.length < 1 && this.selectedMeshDevice.length < 1 && this.selectedPacks.length < 1 && this.selectedChannels.length < 1 ){

      this.router.navigate(['/onboarding/available-location'])
    }
  }
  removeChannel(channel:Channel){
    this.store.dispatch(new RemoveSelectedChannel(channel.id))
    this.removeBillingSummaryChannel()
    if(this.createYourOwnFlag && this.selectedPacks.length < 1 && this.selectedChannels.length < 1){
      this.selectedCableDevice.map(x => {
        this.store.dispatch(new RemoveSelectedCableDevice(x.id))
        this.store.dispatch(new RemoveSelectedCableDeviceId(x.id))
        this.removeBillingSummaryCableAddons()
        //this.router.navigate(['/tv-plan/choose-plan'])
      })
    }
    if(this.selectedPlan.length < 1 && this.selectedMeshDevice.length < 1 && this.selectedPacks.length < 1 && this.selectedChannels.length < 1  ){
      this.selectedCableDevice.map(x => {
        this.store.dispatch(new RemoveSelectedCableDevice(x.id))
        this.store.dispatch(new RemoveSelectedCableDeviceId(x.id))
        this.removeBillingSummaryCableAddons()
      })
      this.router.navigate(['/onboarding/available-location'])
    }
  }

 

 
  removeMesh(mesh:AvailableLockinPeriod){
    this.store.dispatch(new RemoveSelectedMeshLockin(mesh.name))
  }

  removeMeshDevice(mesh: MeshDevice, navigate?:boolean) {
    this.store.dispatch(new RemoveSelectedMeshDevice(mesh.id))
    this.store.dispatch(new RemoveSelectedMeshLockin(mesh.name))
    this.removeBillingSummaryMeshAddons(!navigate)
    if (this.selectedPlan.length < 1 && this.selectedMeshDevice.length < 1 && this.selectedPacks.length < 1 && this.selectedChannels.length < 1 && this.selectedCableDevice.length < 1) {
      const path = this.router.url.split("/")
      if(!navigate) this.router.navigate(['/onboarding/available-location'])
    }
  }

  removeCable(cable:AvailableLockinPeriod){
    this.store.dispatch(new RemoveSelectedCableLockin(cable.name))
  }

  removeCableDevice(cable:CableBoxDevice){
    if(this.selectedCableDevice.length === 1){
    this.store.dispatch(new RemoveSelectedCableDevice(cable.id))
    this.store.dispatch(new RemoveSelectedCableDeviceId(cable.id))
    this.store.dispatch( new RemoveSelectedCableLockin(cable.name))
    this.store.dispatch(new UpdateNumberOfBoxes(null))
    this.store.dispatch( new ResetCableIndexId())
    this.removeBillingSummaryCableAddons()
    if(this.cableId < 1 &&this.selectedPlan.length < 1 && this.selectedMeshDevice.length < 1 && this.selectedPacks.length < 1 && this.selectedChannels.length < 1 && this.selectedCableDevice.length < 1 ){
      const path = this.router.url.split("/")
      this.router.navigate(['/onboarding/available-location'])
    }
  } else if(this.selectedCableDevice.length === 2){
    this.store.dispatch(new RemoveSelectedCableDeviceId(cable.id))
    this.store.dispatch(new RemoveSelectedCableDevice(cable.id))
    this.store.dispatch( new RemoveSelectedCableLockin(cable.name))
    this.removeBillingSummaryCableAddons()
    if(this.cableId < 1 &&this.selectedPlan.length < 1 && this.selectedMeshDevice.length < 1 && this.selectedPacks.length < 1 && this.selectedChannels.length < 1 && this.selectedCableDevice.length < 1 ){
      const path = this.router.url.split("/")
      this.router.navigate(['/onboarding/available-location'])
    }
  }else if(this.selectedCableDevice.length === 3){
    this.store.dispatch(new RemoveSelectedCableDeviceId(cable.id))
    this.store.dispatch(new RemoveSelectedCableDevice(cable.id))
    this.store.dispatch( new RemoveSelectedCableLockin(cable.name))
    
    this.removeBillingSummaryCableAddons()
    if(this.cableId < 1 &&this.selectedPlan.length < 1 && this.selectedMeshDevice.length < 1 && this.selectedPacks.length < 1 && this.selectedChannels.length < 1 && this.selectedCableDevice.length < 1 ){
      const path = this.router.url.split("/")
      this.router.navigate(['/onboarding/available-location'])
    }
  }else{
    this.store.dispatch(new RemoveSelectedCableDeviceId(cable.id))
    this.store.dispatch(new RemoveSelectedCableDevice(cable.id))
    this.store.dispatch( new RemoveSelectedCableLockin(cable.name))
    this.removeBillingSummaryCableAddons()
    if(this.cableId < 1 &&this.selectedPlan.length < 1 && this.selectedMeshDevice.length < 1 && this.selectedPacks.length < 1 && this.selectedChannels.length < 1 && this.selectedCableDevice.length < 1 ){
      const path = this.router.url.split("/")
      this.router.navigate(['/onboarding/available-location'])
    }
  }

  }

  displayInstallationFee(plan:Plan){
    
    if(plan.service_category === 'FTTH'){
      return plan.installation_fee
    }else{
      const x = this.selectedDevices.filter(x => x.device_type_id === plan.service_id)
      if(x.length > 0){
        return x[0].installation_fee    
      }else{
        return plan.installation_fee
      }
    }
    
  }
  

  clear(){
    if(this.selectedPlan){
      if(this.selectedPlan.length > 0){
        return true
      }
    }
    if(this.selectedChannels){
      if(this.selectedChannels.length > 0){
        return true
      }
    }
    if(this.selectedPacks){
      if(this.selectedPacks.length > 0){
        return true
      }
    }
    if(this.selectedCableLockin){
      if(this.selectedCableLockin.length > 0){
        return true
      }
    }
    if(this.selectedCableDevice){
      if(this.selectedCableDevice.length > 0){
        return true
      }
    }
    if(this.selectedMeshLockin){
      if(this.selectedMeshLockin.length > 0){
        return true
      }
    }
    if(this.selectedMeshDevice){
      if(this.selectedMeshDevice.length > 0){
        return true
      }
    }
    if(this.validPromo){
      return true
    }
    else{
      return false
    }
  }
  
  clearAll(){
    
    this.store.dispatch(new UpdateVisitedPages(undefined))
    this.store.dispatch(new ResetAll())
    this.removeDiscount()
     
    //START RESET BILLING SUMMARY AND PAYLOAD
    const reset = {
      "selected_plans":[],
      "voucher_code":"",
      "addons":{
          "devices":[],
          "channel_pack_id":[],
          "channel_id":[]
      }
    }
    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(reset))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(reset).subscribe(x => {
      
      if(x.status_code === 200){
        this.errorMessage = ""
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        //END RESET BILLING SUMMARY AND PAYLOAD
        this.router.navigate(['/onboarding/available-location'])
      }else{
        this.errorMessage = x.message
      }
    })
  
  }

  editServices(){
    this.editMode = !this.editMode
  }

  editPlan(x){
    this.store.dispatch(new UpdateEditMode(true))
    this.store.dispatch(new UpdateEditOriginLink(this.router.url))
    if(x == environment.SERVICE_BUNDLE){
      this.router.navigate(['/bundle-plan/select-plan'])
    }
    else if(x == environment.SERVICE_INTERNET){
      this.router.navigate(['/internet-plan/select-plan'])
    }
    else if(x == environment.SERVICE_TV){
      this.cableLite ? this.router.navigate(['/tv-plan/free-plan']) : this.router.navigate(['/tv-plan/standard-plan'])
    }
    else if(this.selectedPacks.some(x=>x.name === x.name)||this.selectedChannels.some(x=>x.name === x.name)){
      this.router.navigate(['/tv-plan/create-plan/tv-packs'])
    }

  }
  
  validateVoucherCode(){
    if (this.voucherCode) {
      this.gelatoService.validateVoucherCode(this.voucherCode).subscribe(x => {
        if(x.status_code === 200){
          this.store.dispatch(new UpdateApplyButtonFlag(true))
          this.store.dispatch(new UpdateVoucherCode(x))
         
          this.promoAmount = x.data["voucher_code"]["discount_amount"]
          this.rate = x.data["voucher_code"]["rate"]
          this.validPromo = true
          this.errorMessage = ""
        }else{
          this.errorMessage = x.message
          this.validPromo = false
        
        }
      })
    }
  }

  clickServiceFee(){
 
    if(window.innerWidth < 1240){
    document.getElementById('service-fee-holder').classList.toggle("clicked");
      document.getElementsByClassName('service-fee-holder')[0].classList.contains("clicked") ?
      document.getElementsByClassName('extra')[0]['style'].bottom = '485px' :
      document.getElementsByClassName('extra')[0]['style'].bottom = '57px' 

    } 
    document.getElementsByClassName('service-fee-holder')[0].classList.contains('clicked') ? document.getElementsByClassName('icon-arrow-up')[0].className = 'icon-arrow-down text-white' : document.getElementsByClassName('icon-arrow-down')[0].className = 'icon-arrow-up text-white';

  }

  removeDiscount(){
    this.validPromo = false
    this.total += this.discountAmount 
    this.voucherCode = ""
    this.store.dispatch(new UpdateApplyButtonFlag(false))
    this.store.dispatch(new UpdateVoucherCode(null))
  }

  toggleAddon(x, type){
    document.getElementById(type+"-addon-price-"+x).classList.toggle("d-none")
    document.getElementById(type+"-addon-period-"+x).classList.toggle("d-none")
    document.getElementById(type+"-addon-parent-"+x).classList.toggle("icon-addon")
    document.getElementById(type+"-addon-parent-"+x).classList.toggle("icon-minuson")
  }

  // BILLING SUMMARY 
  
  removeBillingSummaryPlan(x){
    if(x.service_id === environment.SERVICE_TV) 
    {  
        const reset = {
          "selected_plans":[],
          "voucher_code":"",
          "addons":{
              "devices":[],
              "channel_pack_id":[],
              "channel_id":[]
          }
        }
  
        // UPDATE PLAN
        this.selectedPlan.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_TV).map(plan => {
              reset.selected_plans.push({
                plan_id:plan.id,
                device_id:plan.service_id === environment.SERVICE_INTERNET? this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id) : this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id) 
              
              })
            })
           
        this.selectedMeshDevice.map(x => {
          reset.addons.devices.push({
            "device_id":x.id,
            "lockin_period_id":x.lockin_period_id || x.lockin_period['id']
          })
        })
       
        //UPDATE BILLING SUMMARY PAYLOAD
        this.store.dispatch(new UpdateBillingSummaryPayload(reset))
        //UPDATE BILLING SUMMARY
        this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
          
          if(x.status_code === 200){
            this.errorMessage = ""
            this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
          }else{
            this.errorMessage = x.message
          }
        })
       //  CHECK IF CREATEYOUROWNPLAN
     }else if(this.createYourOwnFlag){
           //CONSTRUCT SELECTED PLANS
         const x = []
         this.selectedPlan.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_TV).map(plan => {
           x.push({
             plan_id:plan.id,
             //IF INTERNET GET SELECTED MODEM AND MESH DEVICES
             //IF TV GET BOX DEVICES
             device_id:plan.service_id === environment.SERVICE_INTERNET? this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id) : this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id) 
           })
         })
         x.push({
             "plan_id":0,
             device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id)
         })
         let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",x)
         //UPDATE BILLING SUMMARY PAYLOAD
         this.store.dispatch(new UpdateBillingSummaryPayload(payload))
 
         //UPDATE BILLING SUMMARY
         this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
           if(x.status_code === 200){
             this.errorMessage = ""
             this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
           }else{
             this.errorMessage = x.message
           }
         })
        //CHECK IF INTERNET OR BUNDLE
      }else{
         //CONSTRUCT SELECTED PLANS
      
      const x = this.selectedPlan.filter(x => x.service_id === environment.SERVICE_INTERNET || x.service_id === environment.SERVICE_TV).map(plan => {
        return{
          plan_id:plan.id,
          //IF INTERNET GET SELECTED MODEM AND MESH DEVICES
          //IF TV GET BOX DEVICES
          device_id:plan.service_id === environment.SERVICE_INTERNET? this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id) : this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id) 
        }
      })
      let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",x)
      //UPDATE BILLING SUMMARY PAYLOAD
      this.store.dispatch(new UpdateBillingSummaryPayload(payload))
  
      //UPDATE BILLING SUMMARY
      this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
        if(x.status_code === 200){
          this.errorMessage = ""
          this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        }else{
          this.errorMessage = x.message
        }
     })      
  
      }
   }
 


  removeBillingSummaryCableAddons(){
    //CONSTRUCT SELECTED CABLE DEVICE
  const selected = []
  this.selectedCableDevice.map(x => {
    selected.push({
    
      "device_id":x.id,
      "lockin_period_id":x.lockin_period_id 
    })
  })
  this.selectedMeshDevice.map(x => {
    selected.push({
    
      "device_id":x.id,
      "lockin_period_id":x.lockin_period_id || x.lockin_period['id']
    })
  })
  let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.devices",selected)
  //UPDATE BILLING SUMMARY PAYLOAD
  this.store.dispatch(new UpdateBillingSummaryPayload(payload))
  //UPDATE BILLING SUMMARY
  this.gelatoService.billingSummary(payload).subscribe(x => {
    if(x.status_code === 200){
      this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
    }else{
    }
  })    

  }

  
  removeBillingSummaryMeshAddons(request?:boolean){
    //CONSTRUCT SELECTED CABLE DEVICE
  const selected = this.selectedMeshDevice.map(x => {
  return {
    
      "device_id":x.id,
      "lockin_period_id":x.lockin_period_id || x.lockin_period['id']
  }
  })
  this.selectedCableDevice.map(x => {
    selected.push({
    
      "device_id":x.id,
      "lockin_period_id":x.lockin_period_id 
    })
  })
  let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.devices",selected)
  //UPDATE BILLING SUMMARY PAYLOAD
  this.store.dispatch(new UpdateBillingSummaryPayload(payload))
  //UPDATE BILLING SUMMARY
  if(!request){
    this.gelatoService.billingSummary(payload).subscribe(x => {
      if(x.status_code === 200){
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }else{
      }
    }) 
  }   

  }

  removeBillingSummaryChannel(){
    let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.channel_id",this.selectedChannels.map(x => x.id))
    
    if(this.createYourOwnFlag && this.selectedPacks.length < 1 && this.selectedChannels.length < 1) {
      this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => {
        this.store.dispatch(new RemoveSelectedDevice(x.id))
      })
      const selected = this.selectedPlan.map(x => {
        return {
          "plan_id":x.id,
          device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id)
        }
      })
      payload     = _.set(JSON.parse(JSON.stringify(payload)),"selected_plans",selected)
      //this.router.navigate(['/tv-plan/choose-plan'])      
    }

    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      
      if(x.status_code === 200){
        this.errorMessage = ""
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }else{
        this.errorMessage = x.message
      }
    })    

 }

  removeBillingSummaryPack(){
    
    
    let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.channel_pack_id",this.selectedPacks.map(x=>x.id))
    
    
    if(this.createYourOwnFlag && this.selectedPacks.length < 1 && this.selectedChannels.length < 1) {
      this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => {
        this.store.dispatch(new RemoveSelectedDevice(x.id))
      })
      const selected = this.selectedPlan.map(x => {
        return {
          "plan_id":x.id,
          device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id)
        }
      })
      payload     = _.set(JSON.parse(JSON.stringify(payload)),"selected_plans",selected)      
      //this.router.navigate(['/tv-plan/choose-plan'])
    }
    
    
    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      
      if(x.status_code === 200){
        this.errorMessage = ""
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }else{
        this.errorMessage = x.message
      }
    })    

  }

  totalCableBox(){
    let price = 0 
    this.BillingSummary[0].addons.devices.forEach(x =>{
      if(x.device_type_id == 2){
        price = price + x.monthly_price
      }
    })
    
    return price
  }
  totalCableInstallationFee(){
    let price = 0 
    this.BillingSummary[0].addons.devices.forEach(x =>{
      if(x.device_type_id == 2){
        price = price + x.installation_fee
      }
    })
    
    return price
  }

  getCableDevices(devices){
    return devices.filter(x => {
      return x.device_type_id == environment.DEVICE_CABLE
    })
  }

  createYourOwn(){
    if(this.createYourOwnFlag){
      return true
    }else{
      return false
    }
  }

  getInstallFee(service:number){
    let plan = this.BillingSummary[0].plans.filter(x => x.service_id == service);
    return plan[0].installation_fee
  }
}
