import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GelatoService } from 'src/app/core/services/gelato.service';

@Component({
  selector: 'app-generate',
  templateUrl: './generate.component.html',
  styleUrls: ['./generate.component.css']
})
export class GenerateComponent implements OnInit {
  data:string = `${document.baseURI}onboarding/available-location?qr-code=`
  qrCodeId: number
  constructor(private router: Router,private gelatoService:GelatoService) { 
    // Add QR ID to qr code data
    if(history.state){
      this.data += history.state.qr_code_id
      this.qrCodeId = history.state.qr_code_id
    } else{
      this.router.navigate(['/qr-code/form'])
    }
  }

  ngOnInit() {
  }

  saveImage(){
    let link = document.getElementsByClassName('qrcode')[0].children[0]['src']
    link = link.toString().replace('png','jpeg')
    var a  = document.createElement('a');
    a.href = link;
    // Generate Image name here
    a.download = `sky-qr-code-${this.getFileDate()}`;

    a.click()
  }

  pad2(n: number): string {
    return (n < 10 ? '0' : '') + n.toString();
  }

  getFileDate(): string {
    const date = new Date();
    return `${date.getFullYear()}${this.pad2(date.getMonth() + 1)}${this.pad2(date.getDate())}${this.pad2(date.getHours())}${this.pad2(
      date.getMinutes()
    )}${this.pad2(date.getSeconds())}`;
  }

}
