import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as crypto from 'crypto-js';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ContactInfo } from 'src/app/shared/models/upgrade-request.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private _http: HttpClient) { }

  /**
   * Validate if account number is correct.
   * Returns the masked contact information of the user.
   * 
   * @param accountNumber Account number of the user
   * @returns @see ContactInfo
   */
  getContactInfo(accountNumber: string): Observable<ContactInfo> {
    try {
      let path = "/contactinfo"
      const body = {
        "AccountNumber": `${accountNumber}`
      }

      const timestamp = this.getTime()
      const hmackey = crypto.enc.Base64.parse(environment.ACCOUNT_SECRET_KEY)
      const headers = new HttpHeaders({
        'X-Sky-AuthorizationTime': timestamp,
        'X-Sky-Header-Authorization': this.generateHeaderAuth(timestamp, hmackey),
        'X-Sky-Payload-Authorization': this.generatePayloadAuth(body, hmackey),
        'X-Sky-Header-InterfaceId': environment.INTERFACE_ID,
        'X-Sky-ClientIP': '',
      })

      return this._http.post<ContactInfo>(environment.ACCOUNT_BASE_URL + path, body, { headers })
    } catch (error) {
      console.warn(error)
      throw (error)
    }
  }

  /**
   * Generate the X-Sky-Header-Authorization needed.
   * 
   * @param timestamp Current timestamp generated @see getTime()
   * @param key Parsed secret key from @see environment
   * @returns Correct string with the credential and signature
   */
  generateHeaderAuth(timestamp: string, key: any) {
    const header = `x-sky-authorizationtime=${timestamp},x-sky-header-interfaceid=${environment.ACCOUNT_INTERFACE_ID}`
    const hash_header = crypto.HmacSHA256(header, key)
    const hash_header_base64 = crypto.enc.Base64.stringify(hash_header)

    return `skyv1 Credential=${environment.ACCOUNT_CREDENTIAL},Signature=${hash_header_base64}`
  }

  /**
   * Generate the X-Sky-Payload-Authorization needed.
   * 
   * @param payload Payload passed via the HTTP request
   * @param key Parsed secret key from @see environment
   * @returns Correct string with the credential and signature
   */
  generatePayloadAuth(payload: any, key: any) {
    const no_space_payload = JSON.stringify(payload).replace(/\s+/g, '')
    const hash_payload = crypto.HmacSHA256(no_space_payload, key)
    const hash_payload_base64 = crypto.enc.Base64.stringify(hash_payload)

    return `skyv1 Credential=${environment.ACCOUNT_CREDENTIAL},Signature=${hash_payload_base64}`
  }

  // Returns a timestamp with YYYYMMDDTHHmmss format.
  getTime() {
    const date = new Date();

    return `${date.getFullYear()}` + `${date.getMonth() + 1}` + `${date.getDate()}` + 'T' + `${date.getHours()}` + `${date.getMinutes()}` + `${date.getSeconds()}`
  }
}
